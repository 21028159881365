import React, {useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import styled from 'styled-components';
import SelectField from '../nzuac-form/SelectField';
import {hover, white} from '../../styles/globalStyleVars';
import reactHtmlParser from 'react-html-parser';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {ApiParam} from '../../api/network/apiParams';
import {fetchPostsChild} from '../../api/redux/university';

/**
 * Overview Type 6 component section
 * Pass the props with your data to the Overview Type 6 component.
 * Check the page folder to see the components used.
 **/
const VersityList = ({data, id, offset}) => {



  const dispath = useDispatch();
  const [selectedValue, setSelectedValue] = useState('');
  const [disableCourse, setDisableCourse] = useState(true);
  let getPost = useSelector(store => store.university);
  const filter = getPost?.child?.filter;



  let categoryArray = getPost?.child?.filter?.category;
  const courseArray = getPost?.child?.filter?.course;
  let categoryOptions = [];
  let courseOptions = [];




  // Check if universityArray is defined and not empty
  if (categoryArray && categoryArray.length > 0) {
    categoryOptions = categoryArray.map(university => ({
      value: university?.id,
      label: university?.title
    }));
  }

  // Check if universityArray is defined and not empty
  if (courseArray && courseArray.length > 0) {
    courseOptions = courseArray.map(university => ({
      value: university?.id,
      label: university?.title
    }));
  }


  const handleSelectCategory = (value) => {
    setSelectedValue(value?.value);

    let api_filter = apiEndPoints.FILTERUNIVERSITY;
    let param = {
      [ApiParam.CATEGORY]: value?.value ? value?.value : '',
      [ApiParam.COURSE]: '',

    };
    dispath(fetchPostsChild([api_filter, param]));
    setDisableCourse(false);

  };


  const handleSelectCourse = (value) => {

    let api_filter = apiEndPoints.FILTERUNIVERSITY;
    let param = {
      [ApiParam.CATEGORY]:selectedValue ? selectedValue : '',
      [ApiParam.COURSE]: value?.value ? value?.value : '',

    };
    dispath(fetchPostsChild([api_filter, param]));
    setDisableCourse(false);

  };





  return (
    <StyledOverview6 id={id} offset={offset} className={'ministry '}>
      <Container>
        <Row>
          <Col md={12}>
            <h2 className={''}>
              {
                data?.section_data?.subtitle &&
                  reactHtmlParser(data?.section_data?.subtitle)
              }

            </h2>
          </Col>

        </Row>
        <Row className={'filter-wrapper'}>
          <Col md={4} className={'filter'}>
            <SelectField selection={categoryOptions} onChange={handleSelectCategory} background={'transparent'} color={'#888888'} border={'#888888'} placeholder={'Select Field of Study'}/>
          </Col>
          <Col md={4} className={'filter'}>
            <SelectField background={'transparent'} color={'#888888'} border={'#888888'} placeholder={'Select Course'} selection={courseOptions}  disabled={disableCourse ? true : false} onChange={handleSelectCourse} />
          </Col>
          <Col md={4} className={'filter'}>
            <div className="count">
              <p className={''}>Number of Universities</p>
              <p className="digit ">{filter?.university?.length}</p>
            </div>
          </Col>
        </Row>
        <Row className={'versity-list-row'}>

          {
            filter?.university && filter?.university?.length > 0 &&
              filter?.university?.map((item, index) => {

                return (
                  <Col key={index}>
                    <div className="image-wrapper">
                      <Link to={`/university/${item?.product_data?.slug}`}></Link>

                      <img height={58} width={82} src={item?.images?.list?.[0]?.full_path} alt={reactHtmlParser(item?.product_data?.title)}/>
                      <p className={''}>{reactHtmlParser(item?.product_data?.title)}</p>
                    </div>
                  </Col>
                );
              })

          }


        </Row>
      </Container>
    </StyledOverview6>
  );
};

/**
 * Styled component for Overview Type 6 component section
 * Add your custom styles for this component
 **/
const StyledOverview6 = styled.section`
  position: relative;
  padding-top: 210px;
  padding-bottom: 160px;

  h2 {
    margin-bottom: 40px;
    span{
      color: ${hover}
    }
  }
  

  .filter-wrapper {
    padding-bottom: 60px;

    .filter__control {
      border: 1px solid #888888;
      max-width: 100%;
    }

    .filter__placeholder {
      color: #888888;
    }

    .count {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(4, 5, 4, 0.5);
      margin-bottom: 0;
      margin-top: 0 !important;

      .digit {
        color: ${hover} !important;
        font-weight: 500;
        margin-top: 0 !important;

      }
    }


    @media (max-width: 992px) {
      .col-md-4.filter {
        margin-bottom: 30px;
        flex: 0 0 100%;
        max-width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .versity-list-row {
    display: flex;
    flex-wrap: wrap;
    margin: -15px; /* Adjust for negative margins */

    .col {
      flex: 0 0 calc(16.666% - 30px); /* 16.666% to accommodate the gap */
      max-width: calc(16.666% - 30px); /* 16.666% to accommodate the gap */
      margin: 15px; /* Adjust for negative margins */
      padding-left: 0;
      padding-right: 0;

      .image-wrapper {
        background: ${white};
        padding: 45px 15px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          position: absolute;
          inset: 0;
          z-index: 1;
        }

        img {
          transition: 0.7s all ease;
          position: relative;
        }

        p {
          position: absolute;
          transform: translateY(50px);
          transition: 0.7s all ease;
          opacity: 0;
          visibility: hidden;
          text-align: center;
          padding:  0 15px;

        }

        &:hover {
          p {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;

          }

          img {
            transform: translateY(50px);
            opacity: 0;
            visibility: hidden;

          }
        }
      }
      @media (max-width: 992px) and (min-width: 768px) {
        flex: 0 0 calc(33.333333333% - 30px); /* 16.666% to accommodate the gap */
        max-width: calc(33.333333333% - 30px); /* 16.666% to accommodate the gap */


      }
      @media (max-width: 767px) {
        flex: 0 0 calc(50% - 30px); /* 16.666% to accommodate the gap */
        max-width: calc(50% - 30px); /* 16.666% to accommodate the gap */


      }
    }
  }

`;

export default React.memo(VersityList);
