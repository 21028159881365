import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import Subtitle from '../nziuac-title/Subtitle';
import {hover, text, white} from '../../styles/globalStyleVars';
import {BsPlus} from 'react-icons/bs';
import {Link} from 'react-router-dom';
import {ImgLazyLoad} from '../nzuac-image/ImgLazyLoad';
import ButtonIcon from '../nzuac-buttons/ButtonIcon';
import reactHtmlParser from 'react-html-parser';

const OverviewTwo = ({data, hoverBox, bg}) => {

  /**
   * variables
   * Change / add your custom variable
   **/
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 767) {
        const containerOffsetLeft = document.querySelector('.container').offsetLeft;
        // const lubeTestingImgBox = document.querySelector('.ministry__container');
        const customs_list = document.querySelector('.right-wrapper-col');
        const marginLeft = containerOffsetLeft + 30;
        customs_list.style.setProperty('padding-right', `${marginLeft}px`);
      }
    };

    handleResize(); // Initial positioning
  });

  return (
    <StyledOverviewTwo className={'about-section  '}>
      <div className={`first_section_wrapper pt-160 pb-160 ${hoverBox && data?.posts?.list?.length <= 0 ? 'pb-160' : ''}`}>

        <Container>
          <Row className='about-section__title'>
            <Col md={12}>
              <Subtitle text={data?.section_data?.subtitle ? reactHtmlParser(data?.section_data?.subtitle) : 'Subtitle Here'} color={'rgba(4,5,4,0.3)'} fontSize={16} fontWeight={400} lineHeight={20} margin={'0 0 60px'}/>
              <h2 className={'split-up'}>{data?.section_data?.short_desc ? reactHtmlParser(data?.section_data?.short_desc) : 'Short Description'}</h2>
            </Col>
          </Row>
        </Container>
        <Container fluid className={'p-0'}>


          <Row className='about-section__content right-wrapper-col'>
            <Col sm={4}>
              <div className="image-wrapper reveal">
                <ImgLazyLoad src={data?.images?.list?.[0]?.full_path ? data?.images?.list?.[0]?.full_path : '/images/dynamic/home/logo.png'} alt="About Logo" />

              </div>
            </Col>

            <Col sm={{span: 7}} className={'pl-4'}>
              {/*<TextSlideChildrenAnimation duration="1.17s">*/}

              {
                data?.section_data?.extra_desc ?
                  reactHtmlParser(data?.section_data?.extra_desc)
                  : ''
              }
              <ul>
                <li>
                  {
                    data?.posts?.list?.[0]?.images && data?.posts?.list?.[0]?.images?.length > 0 &&
                      data?.posts?.list?.[0]?.images?.map((item, index) => {
                        return(
                          <div key={index} className="single-item">
                            <div className="img">
                              <img height={50} width={50} src={item?.full_path} alt={item?.short_title}/>
                            </div>
                            <div className="content">
                              <h6>{item?.short_title}</h6>
                              <p>{item?.short_desc}</p>
                            </div>
                          </div>
                        );
                      })
                  }
                </li>
              </ul>
              {
                data?.section_data?.description ?
                  reactHtmlParser(data?.section_data?.description)
                  : 'Description Here'
              }


              {
                data?.section_data?.button_url &&
                                <ButtonIcon src={data?.section_data?.button_url ? data?.section_data?.button_url : ''} height={50} margin={'60px 0 0'} width={150} text={'Learn More'} hoverTextcolor={white} borderColor={white} background={hover} hoverBackground={text}/>

              }


            </Col>
          </Row>

        </Container>
      </div>


      {hoverBox && data?.posts?.list?.length > 0 &&
                <>
                  <Container fluid className='about-section__bottom'>
                    <Row>


                      {
                        data?.posts?.list?.length > 0 &&
                                data?.posts?.list?.map((item, index) => {
                                  const thumb = item?.images?.find(f => f?.thumb === 'on');
                                  const banner = item?.images?.find(f => f?.banner === 'on');

                                  return (
                                    <Col sm={3} key={index} className='about-section__bottom__single p-0'>

                                      <div className="about-section__bottom__single__inner">
                                        <Link to={item?.data?.subtitle}></Link>
                                        <div className="about-section__bottom__single__inner__img">
                                          <ImgLazyLoad alt={reactHtmlParser(item?.data?.title)} src={banner?.full_path ? banner?.full_path : '/images/dynamic/home/box-1.jpg'}/>

                                        </div>

                                        <img className='img-top' height={70} width={70} src={thumb?.full_path ? thumb?.full_path : './images/dynamic/home/icon-1.png'} alt={item?.data?.title ? reactHtmlParser(item?.data?.title) : ''}/>

                                        <div className="circle">
                                          <BsPlus/>
                                        </div>
                                        <p>{item?.data?.title ? reactHtmlParser(item?.data?.short_desc) : ''}</p>
                                        <h4>{item?.data?.title ? reactHtmlParser(item?.data?.title) : ''}</h4>
                                      </div>

                                    </Col>

                                  );
                                })
                      }

                    </Row>
                  </Container>
                </>
      }


    </StyledOverviewTwo>
  );
};

const StyledOverviewTwo = styled.section`
  position: relative;
  z-index: 4;
  background: #F1F0EE;
  overflow: hidden;

  .first_section_wrapper {
    position: relative;
    overflow: hidden;

    .leaf-bg-fixed {
      position: absolute;
      right: -150px;
      bottom: -50px;
      top: 0;
      width: auto;
      height: auto;
      z-index: 2;

      &.mobile {
        display: none;
        @media (max-width: 767px) {
          display: block;
          top: unset;
          bottom: -235px;
          right: -70px;
        }
      }

      &.desktop {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
    
    @media(max-width: 767px){
      padding-top: 120px;
      padding-bottom: 120px;
    }
  }

  .about-section__title {
    margin-bottom: 60px;

    h2 {

    }
  }

  .about-section__content {
    .pl-4 {
      padding-left: 70px !important;
    }

    .image-wrapper {
      position: relative;
      padding-top: calc(616 / 428 * 100%);
    }

    ul {
      margin: 60px 0;

      li {

        .single-item {
          display: flex;
          margin-bottom: 60px;

          &:last-child {
            margin-bottom: 0;
          }

          .img {
            margin-right: 30px;
            min-width: 50px;

            img {
              object-fit: contain;
              height: 50px;
              width: 50px;
            }
          }

          .content {
            h6 {
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
              color: ${hover};
              margin-bottom: 10px;
            }

            p {
              color: ${text};
              margin: 0;
            }

          }
        }
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 16px;
      color: ${text};

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }

    &__count-down {
      margin-top: 80px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &__single {
        width: calc(50% - 50px);
        border-top: 1px solid #221F1F;
        margin-bottom: 60px;

        h2 {
          font-size: 60px;
          line-height: 60px;
          margin-bottom: 25px;
          margin-top: 32px;
          font-weight: 300;

          span {
            font-weight: 300;
          }
        }

        p {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    @media (max-width: 992px) {

      .pl-4 {
        padding-left: 15px !important;
      }

      .col-sm-4 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 30px;
      }

      .col-sm-7 {
        max-width: 100%;

        flex: 0 0 100%;
      }
    }
  }

  .about-section__bottom {
    margin-top: 160px;

    &__single {
      &__inner {
        position: relative;
        padding-top: calc(350 / 341 * 100%);
        overflow: hidden;
        border-right: 1px solid #FFF;

        &:after {
          content: '';
          background-color: ${hover};
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0;
          width: 100%;
          transition: .4s ease;
          //mix-blend-mode: multiply;
        }

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0px;
          z-index: 5;
        }

        &__img {
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(4, 5, 4, 0.4);
            mix-blend-mode: multiply;
            opacity: 1;
            transition: .2s ease;
            transition-delay: .3s;
          }
        }

        p {
          position: absolute;
          left: 30px;
          right: 30px;
          top: 30px;
          color: #ffffff;
          font-size: 16px;
          line-height: 22px;
          transform: translateY(100%);
          transition: all .5s ease;
          opacity: 0;
          z-index: 1;
        }

        .img-top {
          position: absolute;
          top: 40px;
          left: 30px;
          z-index: 2;
          opacity: 1;
          transition: .3s ease;
          height: 60px;
        }

        .circle {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 50px;
          width: 50px;
          border: 1px solid #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          opacity: 1;
          transition: .3s ease;

          svg {
            color: #ffffff;
            font-size: 25px;
            transition: .3s ease;
          }
        }

        h4 {
          position: absolute;
          bottom: 30px;
          left: 30px;
          right: 30px;
          font-size: 24px;
          line-height: 28px;
          font-weight: 500;
          color: #ffffff;
          z-index: 2;
        }

      }

      &:hover {
        .about-section__bottom__single__inner {
          &:after {
            height: 100%;
          }

          p {
            transform: none;
            opacity: 1;
          }

          &__img:after {
            opacity: 1;
            transition-delay: 0s;
          }

          .img-top {
            transform: translateY(-20px);
            opacity: 0;
          }

          .circle {
            transform: scale(1.9);
            opacity: 0;

            svg {
              transform: rotate(50deg);
            }
          }

        }

      }
    }
  }

  .parallax-outer {
    height: 100%;
    width: 100%;
    position: relative;
  }


  //

  .texts-section {
    background-color: #221F1F;
    position: relative;
    padding-bottom: 100px;

    h2 {
      font-size: 32px;
      line-height: 36px;
      color: #ffffff;
      margin-bottom: 90px;

      span {
        color: ${hover};
      }
    }

    &__repeat-text {
      .col-sm-3 {
        margin-bottom: 60px;
      }

      h4 {
        font-size: 26px;
        font-weight: bold;
        color: #ffffff;
        line-height: 32px;
        margin-bottom: 26px;
      }

      p {
        font-size: 16px;
        color: #ffffff;
      }
    }

  }


  @media (min-width: 951px) and (max-width: 1200px) {
    .about-section__bottom__single__inner {
      h4 {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }

  @media (max-width: 992px) {
    .about-section__bottom__single {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .texts-section__repeat-text {
      .col-sm-3 {
        min-width: 33.3333%;
      }
    }

    .col-md-4 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .col-md-7 {
      max-width: 100%;

      flex: 0 0 100%;
    }
  }

  @media (max-width: 992px) {
    
    .right-wrapper-col{
      padding-right: 0 !important;
      padding-left: 0 !important;
      
    }
    .about-section__content .col-sm-7{
      padding: 0 30px !important;
    }
    .global-image img {
      height: 100% !important;
      bottom: 0 !important;
    }

    .mission-vision {
      padding-top: 160px;
      padding-bottom: 160px;
      height: auto !important;

      .row {
        position: relative !important;
        margin-top: -300px !important;
        margin-right: -15px;
        margin-left: -15px;
      }

      .col-sm-4 {
        min-width: 100%;
        margin-bottom: 30px;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 768px) {
    overflow: visible;
    .mission-vision {
      margin-top: 250px !important;
      padding-top: 60px;
      padding-bottom: 70px;

      .container {
        overflow: visible;

        .row {
          margin-top: -200px !important;
        }
      }
    }

  }

  @media (max-width: 767px) {
    .about-section {
      &__title {
        margin-bottom: 60px;
      }

      &__content {
        .col-sm-3, .col-sm-8 {
          min-width: 100%;
          margin: 0;
        }

        img {
          margin-bottom: 60px;
        }

        &__count-down {
          margin-top: 60px;
        }
      }
    }

    .texts-section__repeat-text {
      .col-sm-3 {
        min-width: 50%;
        margin-bottom: 50px;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }

      h4 {
        margin-bottom: 16px;
      }
    }

    .texts-section {
      h2 {
        margin-bottom: 60px;
      }
    }

  }

  @media (max-width: 650px) {
    .about-section__bottom {
      margin-top: 40px;

      &__single {
        flex: 0 0 100%;
        max-width: 100%;

        &__inner {
          border: none;

          h4 {
            font-size: 20px;
            line-height: 26px;
          }
        }
      }
    }
  }


  @media (max-width: 550px) {
    .about-section__content__count-down__single {
      min-width: calc(100% - 50px);
    }
  }


`;
export default React.memo(OverviewTwo);