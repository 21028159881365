import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {get} from '../../network/axiosServices';


const initialState = {
  loading: false,
  posts: [],
  child: [],
  error: '',
  detail: [],
  filter: [],
  detailLoading: false,
  detailError: ''
};

export const fetchPosts = createAsyncThunk('fetchDataNews', (params) => {
  return get(params);
});
export const fetchPostsChild = createAsyncThunk('fetchDataNewsChild', (params) => {
  return get(params);
});

export const fetchPostsChildFilter = createAsyncThunk('fetchDataNewsChildfilter', (params) => {
  return get(params);
});


export const fetchPostDetail = createAsyncThunk('fetchDataDetailNews', (params) => {
  return get(params);
});


const postSlice = createSlice({
  name: 'posts',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchPosts.pending, (state) => {
      state.loading = true;
      state.posts = [];
      state.error = '';
    });
    builder.addCase(fetchPosts.fulfilled, (state, action) => {
      state.loading = false;
      state.posts = action.payload;
      state.error = '';
    });
    builder.addCase(fetchPosts.rejected, (state, action) => {
      state.loading = false;
      state.posts = [];
      state.error = action.error;
    });
    builder.addCase(fetchPostsChild.pending, (state) => {
      state.loading = true;
      state.child = [];
      state.error = '';
    });
    builder.addCase(fetchPostsChild.fulfilled, (state, action) => {
      state.loading = false;
      state.child = action.payload;
      state.error = '';
    });
    builder.addCase(fetchPostsChild.rejected, (state, action) => {
      state.loading = false;
      state.child = [];
      state.error = action.error;
    });
    builder.addCase(fetchPostDetail.pending, (state, action) => {
      state.detailLoading = true;
      state.child = [];
      state.detailError = '';
    });

    builder.addCase(fetchPostsChildFilter.fulfilled, (state, action) => {
      state.loading = false;
      state.filter = action.payload;
      state.error = '';
    });
    builder.addCase(fetchPostsChildFilter.rejected, (state, action) => {
      state.loading = false;
      state.filter = [];
      state.error = action.error;
    });
    builder.addCase(fetchPostsChildFilter.pending, (state, action) => {
      state.detailLoading = true;
      state.filter = [];
      state.detailError = '';
    });

    builder.addCase(fetchPostDetail.fulfilled, (state, action) => {
      state.detailLoading = false;
      state.detail = action.payload;
      state.detailError = '';
    });
    builder.addCase(fetchPostDetail.rejected, (state, action) => {
      state.detailLoading = false;
      state.detail = [];
      state.detailError = action.error;
    });


  }
});


export default postSlice.reducer;
