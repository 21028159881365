import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {white, hover} from '../../styles/globalStyleVars';

/**
 * Icon Left component
 * Pass the props with your data to the Icon Left component.
 **/
const FooterMenuItem = () => {
  return (
    <StyledFooterMenuItem>
      <ul className="footer-menu">
        <li><Link to={'/university'}>Universities</Link></li>
        <li><Link to={'/student-life'}>Student Life</Link></li>
        <li><Link to={'/scholarship'}>Scholarships</Link></li>
        <li><Link to={'/our-partners'}>Our Partners</Link></li>
        <li><Link to={'/partner'}>Become a Partner Agent</Link></li>
        <li><Link to={'/english-proficiency-test'}>English Proficiency Test</Link></li>
      </ul>
    </StyledFooterMenuItem>
  );
};

/**
 * Styled component for Icon Left Single
 * Add your styles for  Icon Left component here.
 **/
const StyledFooterMenuItem = styled.div`
  .footer-menu {
    display: flex;

    li {
      margin-right: 40px;

      a {
        color: ${white};
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;

        &:after {
          display: none;
        }

        &:before {
          display: none;
        }

        &:hover {
          color: ${hover}
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

`;

export default React.memo(FooterMenuItem);
