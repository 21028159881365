import React from 'react';
import styled from 'styled-components';

/**
 * Hamburger Icon component
 * Pass the props with your data to the Hamburger Icon.
 **/
const ApplyNow = () => {
  return (
    <StyledApplyNow>
      <div className="applyNowSvg">
        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
          <g id="Group_24283" data-name="Group 24283" transform="translate(-1297 -668)">
            <circle id="Base" cx="22.5" cy="22.5" r="22.5" transform="translate(1297 668)" fill="#a08750"/>
            <g id="Icon" transform="translate(1308.864 678.682)">
              <path id="Path_9557" data-name="Path 9557" d="M116.962,12.273a6.17,6.17,0,0,0,6.17-6.137,6.17,6.17,0,0,0-12.34,0A6.17,6.17,0,0,0,116.962,12.273Z" transform="translate(-106.327)" fill="#fff"/>
              <path id="Path_9558" data-name="Path 9558" d="M45.391,252.306a5.709,5.709,0,0,0-.62-1.125,7.674,7.674,0,0,0-5.308-3.323,1.14,1.14,0,0,0-.781.188,6.606,6.606,0,0,1-7.76,0,1.015,1.015,0,0,0-.781-.188,7.618,7.618,0,0,0-5.308,3.323,6.583,6.583,0,0,0-.62,1.125.567.567,0,0,0,.027.509,10.693,10.693,0,0,0,.727,1.072,10.2,10.2,0,0,0,1.239,1.393,16.126,16.126,0,0,0,1.239,1.072,12.341,12.341,0,0,0,14.657,0,11.829,11.829,0,0,0,1.239-1.072,12.4,12.4,0,0,0,1.239-1.393,9.4,9.4,0,0,0,.727-1.072A.455.455,0,0,0,45.391,252.306Z" transform="translate(-24.165 -235.128)" fill="#fff"/>
            </g>
          </g>
        </svg>

        <svg id="Hover" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
          <circle id="Base" cx="22.5" cy="22.5" r="22.5" fill="#fff"/>
          <g id="Icon" transform="translate(11.864 10.682)">
            <path id="Path_9559" data-name="Path 9559" d="M116.962,12.273a6.17,6.17,0,0,0,6.17-6.137,6.17,6.17,0,0,0-12.34,0A6.17,6.17,0,0,0,116.962,12.273Z" transform="translate(-106.327)" fill="#a08750"/>
            <path id="Path_9560" data-name="Path 9560" d="M45.391,252.306a5.709,5.709,0,0,0-.62-1.125,7.674,7.674,0,0,0-5.308-3.323,1.14,1.14,0,0,0-.781.188,6.606,6.606,0,0,1-7.76,0,1.015,1.015,0,0,0-.781-.188,7.618,7.618,0,0,0-5.308,3.323,6.583,6.583,0,0,0-.62,1.125.567.567,0,0,0,.027.509,10.693,10.693,0,0,0,.727,1.072,10.2,10.2,0,0,0,1.239,1.393,16.126,16.126,0,0,0,1.239,1.072,12.341,12.341,0,0,0,14.657,0,11.829,11.829,0,0,0,1.239-1.072,12.4,12.4,0,0,0,1.239-1.393,9.4,9.4,0,0,0,.727-1.072A.455.455,0,0,0,45.391,252.306Z" transform="translate(-24.165 -235.128)" fill="#a08750"/>
          </g>
        </svg>
      </div>


    </StyledApplyNow>
  );
};
/**
 * Styled component for Hamburger Icon
 * Add your styles for Hamburger Icon component here.
 **/
const StyledApplyNow = styled.div`
    .applyNowSvg{
      position: relative;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      overflow: hidden;
      svg{
        position: absolute;
        &:first-child{
          z-index: 1;
        }
        &:last-child{
          transform: translateY(50px);
          z-index: 2;
          transition: 0.7s all ease;
        }
        
      }
      &:hover{
        svg{
          &:last-child{
            transform: translateY(0);
          }
        }
      }
    }
`;

export default React.memo(ApplyNow);
