import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {fetchPosts, fetchPostsChild} from '../../api/redux/home';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {ApiParam} from '../../api/network/apiParams';
import gsap from 'gsap';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import BannerCarousel from '../../components/nzuac-section/BannerCarousel';
import {text} from '../../styles/globalStyleVars';
import NZUACForm from '../../components/nzuac-section/NZUACForm';
import Overview from '../../components/nzuac-section/Overview';
import TestimonialOne from '../../components/nzuac-section/TestimonialOne';
import BlogListTwo from '../../components/nzuac-section/BlogListTwo';
import TouchSection from '../../components/nzuac-section/Touch';
import {useLocation} from 'react-router-dom';
import {ScrollSmoother} from 'gsap/dist/ScrollSmoother';
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
// const BannerCarousel = lazy(() => import('../../components/nzuac-section/BannerCarousel'));
// const NZUACForm = lazy(() => import('../../components/nzuac-section/NZUACForm'));
// const Overview = lazy(() => import('../../components/nzuac-section/Overview'));
// const TestimonialOne = lazy(() => import('../../components/nzuac-section/TestimonialOne'));
// const TouchSection = lazy(() => import('../../components/nzuac-section/Touch'));
// const BlogListTwo = lazy(() => import('../../components/nzuac-section/BlogListTwo'));

const Home = ({filter}) => {







  const dispath = useDispatch();
  const location = useLocation();


  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;
    let api_url_child = apiEndPoints.BLOGLIST;

    let param = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'home',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };

    dispath(fetchPosts([api_url, param]));
    dispath(fetchPostsChild([api_url_child]));

  }, [dispath,location]);


  let getPost = useSelector(state => state.home);
  let page_data = getPost?.posts?.data?.page_data;
  let sections_list = getPost?.posts?.data?.sections;
  let child_data = getPost?.child?.data;




  useEffect(() => {
    // Use GSAP to animate opacity from 0 to 1
    gsap.to('.hide', {alpha: 1, visibility: 'visible', display: 'block', duration: 0.5, delay: 1});
    gsap.to('.pagetransition-loader', {alpha: 0, display: 'none', height: '0', duration: 0.5, delay: 1});
    gsap.to('.desktop-menu__bottom__parent-ul > li ul', {alpha: 0, duration: 0.5, delay: 1});

    // if()

    const body = document.querySelector('body');
    const html = document.querySelector('html');
    const applyNow = document.querySelector('.applyNow');

    if (body.classList.contains('scroll-down')) {
      body.classList.remove('scroll-down');
    }

    if (body.classList.contains('scroll-up')) {
      body.classList.remove('scroll-up');
    }

    if (body.classList.contains('menu-is-open')) {
      body.classList.remove('menu-is-open');
    }

    if (html.classList.contains('menu-is-open')) {
      html.classList.remove('menu-is-open');
    }

    if (body.classList.contains('mobile-menu-open')) {
      body.classList.remove('mobile-menu-open');
    }

    if ((body.classList.contains('stop-scroll')) && window.innerWidth < 992) {
      body.classList.remove('stop-scroll');
    }

    if ((applyNow) && window.innerWidth < 992) {
      applyNow.classList.remove('mobile-menu-open');
    }


    if (html.classList.contains('mobile-menu-open')) {
      html.classList.remove('mobile-menu-open');
    }

    var menuOpen = document.querySelectorAll('.Mobile-menu-wrap');
    var hamburger = document.querySelectorAll('.hamburger');
    var menu = document.querySelectorAll('.main_child');
    var menuSub = document.querySelectorAll('.main-child-sub ');
    var menuSubSub = document.querySelectorAll('.main-child-sub-sub');
    hamburger.forEach((i) => {
      i.classList.remove('menu-open');
      gsap.to(document.querySelector('.mobile-menu__items'), {
        opacity: 0,
        duration: '.2'
      });
      gsap.to(document.querySelector('.mobile-menu__items'), {
        display: 'none'
      });
    });
    menuOpen.forEach((i) => {
      i.classList.remove('menu-open');
    });

    menu.forEach((i) => {
      i.classList.remove('submenu-open-mb');
    });
    menuSub.forEach((i) => {
      i.classList.remove('submenu-open-next');
    });
    menuSubSub.forEach((i) => {
      i.classList.remove('submenu-open-next-next');
      i.classList.remove('submenu-open-next');
      i.classList.remove('submenu-open-mb');
    });

    const contact = document.querySelector('.submenu ');



    if((contact)  && window.innerWidth < 992 ){
      if(contact.classList.contains('sub-menu-open')){
        contact.classList.remove('sub-menu-open');

      }
    }
    // You can customize the animation duration and other properties as needed
  }, [location.pathname]); // Run this effect only once when the component mounts







  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`${page_data?.title ? page_data?.title : 'New Zealand University Application Centre'}`}</title>
        {
          page_data?.meta_title &&
              <meta name="title" content={page_data?.meta_title ? page_data?.meta_title : 'New Zealand University Application Centre'}/>

        }

        {
          page_data?.meta_description &&
              <meta name="description" content={page_data?.meta_description}/>

        }
        {
          page_data?.og_title &&
              <meta property="og:title" content={page_data?.og_title}/>

        }
        {
          page_data?.og_description &&
              <meta property="og:description" content={page_data?.og_description}/>

        }

      </Helmet>



      <StyledSection id={'home_page'}>


        {sections_list?.map((item, index) => {


          if (item?.section_data?.template === 'slider_template') {
            return (
              <React.Fragment key={index}>
                <BannerCarousel filter={filter} data={item}/>
              </React.Fragment>
            );
          } else if (item?.section_data?.template === 'nzuac_form') {
            return (
              <React.Fragment key={index}>
                <NZUACForm pt={'100'} data={item}/>
              </React.Fragment>
            );
          }
          else if (item?.section_data?.template === 'overview_box') {
            return (
              <React.Fragment key={index}>
                <Overview data={item} hoverBox winWidth/>
              </React.Fragment>
            );
          }
          else if (item?.section_data?.template === 'testimonial_section') {
            return (
              <React.Fragment key={index}>
                <TestimonialOne background={text} data={item}/>
              </React.Fragment>
            );
          }
          else if (item?.section_data?.template === 'touch_template') {
            return (
              <React.Fragment key={index}>
                <TouchSection data={item}/>
              </React.Fragment>
            );
          }
          else if (item?.section_data?.template === 'blog_slider') {
            return (
              <React.Fragment key={index}>
                <BlogListTwo navigation={text} data={child_data}/>
              </React.Fragment>
            );
          }
          else {
            return null; // If the template doesn't match any case, render nothing
          }
        })}



      </StyledSection>
    </HelmetProvider>
  );
};

const StyledSection = styled.section`
  .bg-padding {
    background: ${text};
    padding-top: 400px;
    @media (max-width: 992px) {
      padding-top: 0;
    }
    }

  .list_with_form  {
    margin-top: -1px;
    @media(max-width: 767px){
    margin-top: 0;}
  }
  
`;

export default Home;
