import React,{useState} from 'react';
import {Form} from 'react-bootstrap';
import styled from 'styled-components';
import {text, white} from '../../styles/globalStyleVars';


const SearchButtonNews = ({
  onSubmit,
  text,
  src,
  img,
  hoverImg,
  fontSize,
  fontWeight,
  color,
  letterSpacing,
  lineHeight,
  margin,
  background,
  borderRadius,
  border,
  width,
  height,
  hoverBackground,
  target,
  borderColor, link, hoverTextcolor, icon
}) => {



  const [searchInput, setSearchInput] = useState('');

  const searchSubmit = (e) => {

    e.preventDefault();
    if (searchInput === '') {
      return;
    } else {
      window.location.href = `/news-results?keyword=${searchInput}`;
      document.querySelector('.search-desktop').classList.remove('search-open');

    }
    // setSearchInput('')


  };


  const handleInput = (e) => {
    setSearchInput(e.target.value);
  };
  return (
    <StyledBtn className={'dc-btn'}
      hoverTextcolor={hoverTextcolor}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      background={background}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      margin={margin}
      border={border}
      img={img}
      borderRadius={borderRadius}
      width={width}
      hoverImg={hoverImg}
      hoverBackground={hoverBackground}
      height={height}
      borderColor={borderColor}
      target={target}
    >
      <Form onSubmit={searchSubmit}>
        <Form.Group className={'button-wrapper'}>
          <Form.Control  onChange={e => handleInput(e)} type="text"
            placeholder="Search...
                                                  "/>
          <div className="icon" onClick={searchSubmit}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <g id="_12" data-name="12" transform="translate(-1.96 -1.96)">
                <path id="Path_2477" data-name="Path 2477" d="M21.665,20.248l-2.824-2.814a9.5,9.5,0,1,0-1.407,1.407l2.814,2.824a1,1,0,1,0,1.417-1.417ZM4,11.478a7.483,7.483,0,1,1,7.483,7.483A7.483,7.483,0,0,1,4,11.478Z" fill="#fff"/>
              </g>
            </svg>
          </div>
        </Form.Group>
      </Form>


    </StyledBtn>
  )
  ;
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin: ${props => props.margin || '0'};
    width: fit-content;
    cursor: pointer;
    min-width: 285px;
    text-align: center;

    .button-wrapper {
      display: flex;
      align-items: center;
      height: 35px;
      color: ${p => p.color || 'rgba(255,255,255,0.5)'};
      font-size: ${props => props.fontSize || '16'}px;
      font-weight: ${props => props.fontWeight || 500};
      margin: 0;
      padding: 0 20px;
      line-height: ${props => props.lineHeight || '20'}px;
      text-transform: capitalize;
      background-color: ${props => props.background || text};
      letter-spacing: ${props => props.letterSpacing || '0'};
      position: relative;
      border-radius: ${props => props.borderRadius || '30px'};
      z-index: 0;
      transition: all .5s cubic-bezier(.54, .32, .05, .96);
      border: 1px solid ${props => props.borderColor || white};
      overflow: hidden;
      justify-content: flex-start;
      cursor: pointer;

      .icon {
        position: absolute;
        left: 20px;
      }

      .icon {
        margin-right: 20px;
      }

      &:hover {
        color: ${props => props.hoverTextcolor ? props.hoverTextcolor : text} !important;

        border: 1px solid ${props => props.hoverBackground ? props.hoverBackground : white};
        color: rgb(255, 255, 255, 1) !important;

        &:after {
          opacity: 1;
          transform: scale(15);
        }
      }


      .form-control {
        background: transparent;
        border: none;
        position: relative;
        padding-left: 40px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        &::placeholder {
          font-weight: 500;
          color: rgba(255, 255, 255, 0.5);
        }

      }


    }
  }



`;


export default React.memo(SearchButtonNews);
