import React from 'react';
import styled from 'styled-components';

/**
 * Icon Left component
 * Pass the props with your data to the Icon Left component.
 **/
const BsLeft = () => {
  return (
    <StyledBsLeft>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Group_16491" data-name="Group 16491" transform="translate(-8568 -15343) rotate(-90)">
          <circle
            id="Ellipse_450"
            data-name="Ellipse 450"
            cx="10"
            cy="10"
            r="10"
            transform="translate(-15343 8568) rotate(90)"
            fill="#fff"
          />
          <g
            id="Group_16490"
            data-name="Group 16490"
            transform="translate(-30769.5 17156) rotate(180)"
          >
            <line
              id="Line_3973"
              data-name="Line 3973"
              x2="3"
              y2="3"
              transform="translate(-15419.5 8576.5)"
              fill="none"
              stroke="#323232"
              strokeLinecap="round"
              strokeWidth="1"
            />
            <line
              id="Line_3974"
              data-name="Line 3974"
              x1="3"
              y2="3"
              transform="translate(-15416.5 8576.5)"
              fill="none"
              stroke="#323232"
              strokeLinecap="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </svg>
    </StyledBsLeft>
  );
};

/**
 * Styled component for Icon Left Single
 * Add your styles for  Icon Left component here.
 **/
const StyledBsLeft = styled.div``;

export default React.memo(BsLeft);
