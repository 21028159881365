import React from 'react';
import styled from 'styled-components';
import reactHtmlParser from 'react-html-parser';

const Text = ({
  text,
  classname
}) => {




  return (

    <StyledText className={`Text fade-up ${classname}`}>
      <p className={'h1 hide'}>{reactHtmlParser(text)}</p>
    </StyledText>

  );
};


const StyledText = styled.div`

   
`;


export default React.memo(Text);














