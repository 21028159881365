import React from 'react';
import styled from 'styled-components';
import {ImgLazyLoad} from '../nzuac-image/ImgLazyLoad';
import ButtonIcon from '../nzuac-buttons/ButtonIcon';
import {coffe, text, white} from '../../styles/globalStyleVars';


const CTASmall = () => {


  return (

    <StyledCTASmall className={'small-box-cta'}>

      <div className={'small-box-cta-single'}>
        <ImgLazyLoad src={'/images/dynamic/small-box-cta/small_cta.jpg'}/>
        <div className="content">
          <h4 className="mid">
                        Ready to take the next big step ?
          </h4>
          <ButtonIcon src={'#'} margin={'15px 0 0'} text={'Apply Now'} color={text} hoverTextcolor={white} hoverBackground={coffe} borderColor={white} background={white}/>
            
        </div>
      </div>

    </StyledCTASmall>

  );

};

const StyledCTASmall = styled.section`
    .small-box-cta-single {
        position: relative;
        padding-top: calc(305 / 230 * 100%);
        .content{
            position: absolute;
            inset: 0;
            padding: 30px 15px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-end;
            h4{
                font-weight: 600;
                color: ${white};
            }
        }
    }
`;


export default React.memo(CTASmall);