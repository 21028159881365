import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import {hover, white} from '../../styles/globalStyleVars';


const CounterSingle = ({value, text, img}) => {


  return (

    <StyledCounterSingle className={'slider-counter-single'}>
      <div className="icon">
        <img alt={text ? text : 'Acclaimed Institutes'} height={60} width={60} src={img ? img : './images/static/acclaimed.svg'}/>

      </div>
      <div className="text">
        {
          value ?
            window?.innerWidth > 767 ?

              <h3><CountUp start={0} end={value ? value : 332}>
                {({countUpRef, start}) => (
                  <VisibilitySensor onChange={start}
                    partialVisibility={{top: 0, bottom: 20}}
                    delayedCall={false}>
                    <span ref={countUpRef}/>
                  </VisibilitySensor>
                )}
              </CountUp>+</h3>
              :

              <h3>
                <span>{value ? value : 332}</span>+
              </h3>
            : ''

        }

        {
          text ?
            <p>{text ? text : 'Acclaimed Institutes'}</p>
            : ''
        }

      </div>
    </StyledCounterSingle>

  );

};

const StyledCounterSingle = styled.section`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .icon {
        height: 60px;
        width: 60px;
        margin-right: 12px;
        @media(max-width: 767px){
            width: 32%;
        }
        svg, img {
            height: 60px;
            width: 60px;
        }
    }

    .text {
        
        @media(max-width: 767px){
            width: 68%;
        }
        h3 {
            color: ${hover};
            margin: 0;
        }

        p {
            color: ${white};
        }
    }
`;


export default React.memo(CounterSingle);