import React from 'react';
import styled from 'styled-components';

const SignUpIcon = ({color}) => {
  return (
    <StyledSignUpIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
        <g id="Icon" transform="translate(16737.622 5871.435)">
          <path id="Path_9205" data-name="Path 9205" d="M116.013,10.385a5.221,5.221,0,0,0,5.221-5.193,5.221,5.221,0,0,0-10.442,0A5.221,5.221,0,0,0,116.013,10.385Z" transform="translate(-16844.637 -5871.435)" fill={`${color ? color: '#fff'}`}/>
          <path id="Path_9206" data-name="Path 9206" d="M42.126,251.619a4.833,4.833,0,0,0-.524-.952,6.494,6.494,0,0,0-4.491-2.812.965.965,0,0,0-.661.159,5.59,5.59,0,0,1-6.566,0,.859.859,0,0,0-.661-.159,6.447,6.447,0,0,0-4.491,2.812,5.572,5.572,0,0,0-.524.952.48.48,0,0,0,.023.431,9.046,9.046,0,0,0,.616.907,8.634,8.634,0,0,0,1.049,1.179,13.65,13.65,0,0,0,1.049.907,10.443,10.443,0,0,0,12.4,0,10.011,10.011,0,0,0,1.049-.907,10.491,10.491,0,0,0,1.049-1.179,7.949,7.949,0,0,0,.616-.907A.385.385,0,0,0,42.126,251.619Z" transform="translate(-16761.787 -6108.518)" fill={`${color ? color: '#fff'}`}/>
        </g>
      </svg>


    </StyledSignUpIcon>
  );
};

const StyledSignUpIcon = styled.div`

`;

export default React.memo(SignUpIcon);
