import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
// eslint-disable-next-line no-unused-vars
import {Col, Container, Row} from 'react-bootstrap';
import {coffe, hover, text, white} from '../styles/globalStyleVars';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {CSSPlugin, gsap} from 'gsap';
import SearchButton from '../components/nzuac-buttons/SearchButton';
import ButtonIcon from '../components/nzuac-buttons/ButtonIcon';
import LandingFilterForm from '../components/nzuac-form/LandingFilterForm';
// import LandingFilterFormSearch from '../components/nzuac-form/LandingFilterFormSearch';
import LogoSVG from '../components/nzuac-svg/LogoSVG';
import CloseIconSearch from '../components/nzuac-svg/CloseIconSearch';
import DesktopMenuItem from './parts/DesktopMenuItem';
import LazyLoad from 'react-lazyload';
import LeafDeskTopMenu from '../components/nzuac-svg/LeafDeskTopMenu';
import {useDispatch, useSelector} from 'react-redux';
import {apiEndPoints} from '../api/network/apiEndPoints';
import {fetchPostsChildFilter} from '../api/redux/home';
// const DesktopMenuItem = lazy(() => import('./parts/DesktopMenuItem'));
// const CloseIconSearch = lazy(() => import('../components/nzuac-svg/CloseIconSearch'));
// const LandingFilterFormSearch = lazy(() => import('../components/nzuac-form/LandingFilterFormSearch'));
// const ButtonIcon = lazy(() => import('../components/nzuac-buttons/ButtonIcon'));
// const SearchButton = lazy(() => import('../components/nzuac-buttons/SearchButton'));
// const LogoSVG = lazy(() => import('../components/nzuac-svg/LogoSVG'));
const MainMenu = ({filter}) => {
  gsap.registerPlugin(CSSPlugin);

  const searchClickRef = useRef();
  const searchItemRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    // Ensure searchClickRef is valid
    if (!searchClickRef || !searchClickRef.current) return;

    // Function to handle click event for showing/hiding search
    const handleClick = (e) => {
      const searchItem = searchItemRef.current;
      // Toggle overflow of smooth content
      // Toggle search open/close classes and animations
      if (!searchItem.classList.contains('search-open')) {
        searchItem.classList.add('search-open');
        document.querySelector('body').classList.add('search-open');
        gsap.to(searchItem, {
          display: 'flex',
          duration: 0.5,
          height: '100vh',
          opacity: 1,
          overflow: 'visible'
        });
        gsap.to(searchItem.querySelector('.container'), {
          opacity: 1,
          delay: 0.2,
        });
      } else {
        searchItem.classList.remove('search-open');
        gsap.to(searchItem, {
          duration: 0.4,
          height: 0,
          opacity: 0,
          overflow: 'hidden'
        });
        gsap.to(searchItem.querySelector('.container'), {
          opacity: 0,
        }, '-=0.4');
        gsap.to(searchItem, {
          display: 'none'
        });
      }
    };

    // Add click event listener to show/hide search
    searchClickRef.current.addEventListener('click', handleClick);

    // Clean up function to remove event listener
    return () => {
      if (searchClickRef.current) {
        searchClickRef.current.removeEventListener('click', handleClick);
      }
    };
  }, [searchClickRef]); // Dependency array with searchClickRef

  // Add event listeners to close search when clicking on close button or link
  useEffect(() => {
    const handleCloseClick = () => {
      const searchItem = searchItemRef.current;
      searchItem.classList.remove('search-open');
      document.querySelector('body').classList.remove('search-open');

      gsap.to(searchItem, {
        duration: 0.4,
        height: 0,
        overflow: 'hidden'
      });
      gsap.to(searchItem.querySelector('.container'), {
        opacity: 0,
      }, '-=0.4');
      gsap.to(searchItem, {
        display: 'none'
      });
    };
    // Add event listeners to close search
    const closeButton = document.querySelector('.search-desktop svg');
    if (closeButton) {
      closeButton.addEventListener('click', handleCloseClick);
    }
    const closeLink = document.querySelector('.search-desktop a');
    if (closeLink) {
      closeLink.addEventListener('click', handleCloseClick);
    }

    // Clean up function to remove event listeners
    return () => {
      if (closeButton) {
        closeButton.removeEventListener('click', handleCloseClick);
      }
      if (closeLink) {
        closeLink.removeEventListener('click', handleCloseClick);
      }
    };
  }, [searchItemRef]); // Dependency array with searchItemRef





  // menu fixed on scroll
  useEffect(() => {
    if (document.body.classList.contains('scroll-down')) {
      document.body.classList.remove('scroll-down');
    }
  },[location?.pathname]);


  const [scrollUp, setScrollUp] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);
  useEffect(() => {
    const body = document.body;
    // const scrollUp = 'scroll-up';
    const scrollDown = 'scroll-down';
    let lastScroll = 0;
    let howMuchScroll = 20;
    window.addEventListener('scroll', () => {
      let currentScroll = window.pageYOffset;

      if (currentScroll <= howMuchScroll) {
        // body.classList.remove(scrollUp);
        setScrollUp(false);
        setScrollDown(false);

        return;
      }
      if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        // body.classList.remove(scrollUp);
        // body.classList.add(scrollDown);
        setScrollDown(true);
        setScrollUp(false);

      } else if (
        currentScroll < lastScroll
      // body.classList.contains(scrollDown)
      ) {
        // up
        // body.classList.remove(scrollDown);
        // body.classList.add(scrollUp);
        setScrollUp(true);
        setScrollDown(false);

      }
      lastScroll = currentScroll;
    });
  }, []);





  useEffect(() => {
    let api_filter = apiEndPoints.FILTER;
    dispatch(fetchPostsChildFilter([api_filter]));
  }, [dispatch, location]);

  let getPost = useSelector(state => state.home);
  let filter2 = getPost?.filter?.filter;




  // menu show hide on router change
  useEffect(() => {
    gsap.timeline().to('.desktop-menu', {
      opacity: 0,
      duration: .03,
      visibility: 'hidden'
    }).to('.desktop-menu', {
      opacity: 1,
      visibility: 'visible',
      delay: .8,
      duration: .5
    });


  }, [location.pathname]);


  const navigate = useNavigate();

  const handleClickHome = () => {
    navigate('/'); // Navigate to the root route ('/')
  };




  return (

    <>
      <StyledDesktopMenu className={`desktop-menu ${scrollDown ? 'scroll-down' : ''} ${scrollUp ? 'scroll-up' : ''}`}>
        <LeafDeskTopMenu/>
        <Container>
          <Row className='desktop-menu__top'>
            <Col className='desktop-menu__top__logo'>
              <Link to={'/'} onClick={handleClickHome} >
                <LogoSVG/>

              </Link>
            </Col>
            <Col>
              <ul className='d-flex justify-content-end hide'>
                <li className='search ' ref={searchClickRef}>
                  <SearchButton borderColor={white} background={'transparent'} icon={true} text={'Search any course'}/>
                </li>
                <li className={'apply-now-pulse-animation hide'}>
                  <ButtonIcon src={'/'} iconSVG text={'Apply Now'} pulse hoverTextcolor={text} hoverBackground={white} borderColor={coffe} background={coffe}/>
                </li>

                <li className={'hide contact-button-menu'}><ButtonIcon width={'100'} color={white} src={'/contacts'} text={'Contacts'} hoverTextcolor={white} borderColor={white} background={'transparent'} hoverBackground={hover}/>
                </li>
              </ul>
            </Col>
          </Row>

          {/*menu item desktop*/}
          <DesktopMenuItem/>

        </Container>

        <div className="search-desktop" ref={searchItemRef}>
          <LazyLoad>
            <img src="./images/static/leaf.svg" className={'fixed-bg-img mobile-none'} alt=""/>
          </LazyLoad>
          <LazyLoad>
            <img src="./images/static/leaf-mobile.svg" className={'fixed-bg-img mobile'} alt=""/>
          </LazyLoad>
          <a className={'search-close-icon'}>
            <CloseIconSearch/>
          </a>
          <Container>

            <Row>
              <Col md={{span: 10, offset: 1}}>
                <p>Select any course from hundreds of education institutions</p>
                <LandingFilterForm data={filter2}/>

              </Col>
            </Row>
          </Container>
        </div>


      </StyledDesktopMenu>




    </>


  );
};

const StyledDesktopMenu = styled.section`

  position: fixed;
  z-index: 9;
  width: 100%;
  background: rgba(4, 5, 4, 85%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.20);
  top: 0;
  left: 0;
  right: 0;
  transition: 0.7s all ease;
  opacity: 0;
  visibility: hidden;

  
  .contact-button-menu .dc-btn{
    a{
      border: 1px solid white;
      &:hover{
        border-color: ${hover};
      }
    }
  }
  
 @media(min-width: 1024px){
   .col-md-10{
     display: flex;
     align-items: center;
     flex-direction: column;
   }
 }
  @media(max-width: 1024px){
    .col-md-10{
      margin: 0;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .search-filer-home{
      width: auto;
      .select-option-single{
        width: auto;
        .selector-custom-nzuac{
          width: auto;
          .form-group{
            width: auto;
            .filter__control{
              width: auto;
              max-width: unset;
            }
          }
        }
      }
    }
  }
  
  .fixed-img {
    position: absolute;
    height: auto;
    width: 50%;
    right: 0;
    bottom: 0;
  }
    .apply-now-pulse {
      &:hover{
        &:before{
          box-shadow: rgb(255, 255, 255) 0 0 0 1px;
        }
        &:after{
          box-shadow: rgb(255, 255, 255) 0 0 0 1px;
        }
      }
      a:hover{
        background: white;
        &:after{
          box-shadow: transparent;
        }
        &:before{
          box-shadow: transparent;
        }
      }
    }
  .desktop-menu__top {
    height: 100px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 50;

    &__logo {
      img {
        height: 40px;
      }

    }

    ul {
      li {
        margin-right: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        &.search {
          margin-right: 30px;
        }
        
        
      }
    }
  }

  //bottom menu 

  .desktop-menu__bottom {
    height: 55px;

    &__parent-ul {
      display: flex;
      width: 100%;
      height: 100%;
      position: relative;
      justify-content: space-between;

      > li {
        display: flex;
        margin-right: 30px;
        position: relative;
        align-items: center;
        width: 100%;
        &.active{
          &:before{
            width: 100%;
          }
        }
        &:after {
          content: '';
          position: absolute;
          height: 2px;
          width: 100%;
          left: 0;
          top: -1.5px;
          background-color: rgba(255, 255, 255, 0.4);
          transition: width .6s cubic-bezier(0.87, 0, 0.13, 1);
        }

        &:before {
          content: '';
          position: absolute;
          height: 2px;
          width: 0;
          left: 0;
          top: -1.5px;
          z-index: 1;
          background-color: ${hover};
          transition: width 1s cubic-bezier(0.87, 0, 0.13, 1);
        }

        a {
          font-size: 16px;
          font-weight: 400;
          display: flex;
          width: 100%;
          line-height: 20px;
          height: 100%;
          align-items: center;
          color: ${white};
          justify-content: space-between;

          &:focus {
            color: ${white};
          }
        }


        ul {
          position: absolute;
          flex-wrap: wrap;
          background-color: ${text};
          width: 100%;
          padding: 20px 0 20px 0;
          opacity: 0;
          visibility: hidden;
          left: 0;
          top: 74px;
          transition: all .1s ease;
          box-shadow: 0 0 25px 5px rgb(0 0 0 / 7%);

          li {
            display: block;
            min-width: 100%;
            border: none;
            padding: 0 20px 0 20px;
            // opacity: 0;

            a {
              display: flex;
              padding: 0 0 10px 0;
              border-bottom: 1px solid rgba(34, 31, 31, 0.10);
              color: #FFFFFF;
              width: 100%;
              margin-bottom: 13px;
              position: relative;
              text-transform: none;
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;


              &:before {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                width: 0;
                height: 1px;
                background: ${hover};
                content: '';
                transition: all .7s ease;

              }


              &:after {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background: rgba(255, 255, 255, 0.2);
                content: '';
              }

              svg {
                position: absolute;
                right: 30px;
                color: ${hover};
                opacity: 0;
                transition: all .3s ease;
                top: 3px;
              }

              &:hover {
                color: ${hover} !important;

                &:before {
                  width: 100%;
                }
              }

            }

            &:after {
              display: none !important;
            }
            
         

            a {
              //font-size: 12px;
              //font-weight: 600;
            }

            &:nth-last-child(1) {
              a {
                margin-bottom: 0;
                border: none;
                padding-bottom: 0;

                &:after {
                  display: none;
                }

                &:before {
                  display: none;
                }
              }
            }

            &:hover {
              svg {
                right: 0;
                opacity: 1;
              }
            }

          }
        }

        &:hover {
          &:before {
            width: 100%;
          }

          ul {
            opacity: 1 !important;
            visibility: visible !important;
            transition: all .6s ease !important;
            top: 54px !important;

          }
        }

      }

    }
  }


  //search

  .search-desktop {
    //height: 195px;
    background-color: ${text};
    //display: flex;
    align-content: center;
    flex-wrap: wrap;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    height: 0;
    display: none;
    overflow: hidden;
    z-index: 999999;
    box-shadow: 0 1px 6px rgb(0 0 0 / 10%);

    p{
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color:white;
      text-align: center;
      margin-bottom: 30px;
    }
    .container {
      position: relative;
      opacity: 0;
      z-index: 999;
    }

    .fixed-bg-img {
      position: absolute;
      z-index: 1;
      right: 0px;
      bottom: 0px;
      width: auto;
      height: auto;
      display: inline-block;

      &.mobile {
        display: none;
        @media (max-width: 767px) {
          display: block;
        }
      }

      &.mobile-none {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
    }

    .search-close-icon {
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: 10;
    }

    form {
      min-width: 100%;

      .search-input {
        position: relative;

        button, p, a {
          box-shadow: none;
          border: none;
          background-color: transparent;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: fit-content;
          font-size: 12px;
          line-height: 18px;
          color: #221f1f;
          transition: color .3s ease;
          cursor: pointer;

          &:hover {
            color: ${hover};
          }
        }

        span {
          position: absolute !important;
          bottom: 18px;
        }

        .form-control {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #221F1F;
          padding-left: 48px;
          padding-bottom: 9px;
          padding-top: 0;
          height: 50px;
          font-size: 32px;
          line-height: 36px;
          font-weight: 600;
          color: rgba(34, 31, 31, 0.20);
          background-color: #F9F9F9;

          ::placeholder {
            font-size: 32px;
            line-height: 36px;
            font-weight: 600;
            color: rgba(34, 31, 31, 0.20);
          }
        }

      }
    }

    &__menu {
      margin-top: 23px;
      min-width: 100%;

      p {
        font-size: 12px;
        color: ${hover};
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 13px;

      }

      ul {
        display: inline-flex;

        li {
          a {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: #221f1f;
            display: flex;
            margin-right: 30px;
          }

          &:nth-last-child(1) {
            a {
              margin-right: 0;
            }
          }
        }
      }

    }

  }

  @media (max-width: 1199px) {

    .desktop-menu__top ul li {
      margin-right: 20px;

    }

    .desktop-menu__bottom ul li {
      margin-right: 15px;

      a {
        font-size: 10px;
      }
    }
  }


  @media (max-width: 992px) {
    display: none;
  }

`;


export default React.memo(MainMenu);
