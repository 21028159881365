import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {hover, text, white} from '../styles/globalStyleVars';
import LeafDeskTopFooterMobile from '../components/nzuac-svg/LeafDeskTopFooterMobile';
import Social from '../components/nzuac-social-icons/Social';
import FooterMenuItem from './parts/FooterMenuItem';
import FooterAddress from './parts/FooterAddress';
import FooterCopyright from './parts/FooterCopyright';
import FooterAddressText from './parts/FooterAddressText';
import LeafDeskTopFooter from '../components/nzuac-svg/LeafDeskTopFooter';


const Footer = ({data}) => {


  // api data refactor
  // let rowData = data && data?.data;
  return (<StyledFooter className='footer pt-160'>
    {
      window?.innerWidth > 767 &&
        <LeafDeskTopFooter/>
    }
    {
      window?.innerWidth < 767 &&
        <LeafDeskTopFooterMobile/>

    }
    <Container className='footer__top'>
      <Row>
        <Col className='footer-col' sm={10}>
          <div className="first-content ">
            <FooterAddress/>
          </div>
        </Col>

        <Col className='footer-col social-list' sm={2}>
          <Social bg={white} iconColor={text}/>
        </Col>


        <Col className='footer-col footer-menu-col hide' sm={12}>
          <FooterMenuItem/>
        </Col>


        <Col className='footer-col footer-logo-bottom hide' sm={2}>
          <img height={70} width={130} src="/images/static/footer-logo.svg" alt="Footer Logo"/>
        </Col>

        <Col className='footer-col' sm={4}>
          <div className="address hide">
            <FooterAddressText title={'Bangladesh'} link={'https://map.google.com'} address={'3rd Floor, BSL Office Complex (Building-1),<br/>\n' +
                '                            Hotel Intercontinental,<br/>\n' +
                '                            1 Minto Road, Shahbag 1000 Dhaka,<br/>\n' +
                '                            Dhaka Division, Bangladesh'}/>
          </div>
        </Col>
        <Col className='footer-col' sm={3}>
          <div className="address hide">
            <FooterAddressText title={'New Zealand'} address={'3 Jammen Drive,<br/>\n' +
                '                        Massey, Auckland,<br/>\n' +
                '                        New Zealand - 0614'} link={'https://map.google.com'}/>
          </div>
        </Col>
        <Col className='footer-col hide' sm={3}>
          <div className="copyright">
            <FooterCopyright/>
          </div>
        </Col>
      </Row>
    </Container>


  </StyledFooter>);
};
const StyledFooter = styled.section`
  background-color: ${text};
  padding: 120px 0 40px;
  position: relative;
    margin-top: -1px;
  margin-bottom: -1px;
  .fixed-bg-img {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    //display: inline-block;


    &.mobile {
      display: none;
      @media (max-width: 767px) {
        display: block;
      }
    }

    &.mobile-none {
      display: block;
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .footer-col {
    position: relative;
    z-index: 3;

    .first-content {
      margin-bottom: 40px;

      .h1 {
        margin-bottom: 40px;
        color: ${white};
        opacity: 0;
        visibility: hidden;
      }

      ul {
        li {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          p {
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            color: ${white};

            span {
              font-size: 20px;
              font-weight: 400;
              line-height: 24px;
              color: ${white};
              margin-right: 15px;
            }

            a {
              font-size: 20px;
              font-weight: 400;
              line-height: 24px;
              color: ${white};

              &:hover {
                color: ${hover}
              }
            }
          }
        }
      }
    }

    &.footer-menu-col {

      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 40px;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: calc(100% - 30px);
        margin: 0 auto;
        content: '';
        height: 1px;
        background: rgba(255, 255, 255, 0.4);
      }

      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: calc(100% - 30px);
        margin: 0 auto;
        content: '';
        height: 1px;
        background: rgba(255, 255, 255, 0.4);
      }

      .footer-menu {
        display: flex;

        li {
          margin-right: 40px;

          a {
            color: ${white};
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;

            &:after {
              display: none;
            }

            &:before {
              display: none;
            }

            &:hover {
              color: ${hover}
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .address {
      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 10px;
      }

      a {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(255, 255, 255, 1);

        &:hover {
          color: ${hover}
        }
      }
    }

    .copyright {
      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.5);
        margin: 0;

        a {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: rgba(255, 255, 255, 0.5);

          &:hover {
            color: ${hover};
          }
        }
      }
    }

    &.social-list {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 767px) {
        justify-content: flex-start;

        .social {
          flex-direction: row !important;
          margin-bottom: 30px;
        }
      }

      .social {
        flex-direction: column;
      }
    }
  }


  @media (max-width: 767px) {
    .footer-logo-bottom {
      margin-bottom: 30px;
    }

    .footer-col {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .footer-menu {
      flex-direction: column;

      li {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

`;


export default Footer;