import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import Subtitle from '../nziuac-title/Subtitle';
import {hover, text, white} from '../../styles/globalStyleVars';
import reactHtmlParser from 'react-html-parser';
import ButtonIconRight from '../nzuac-buttons/ButtonIconRight';

const Oppurtunities = ({data,list}) => {

  return (
    <StyledScholarshipOppurtunities className={'opportunities-bigtex pb-160'}>
      <Container>
        <Row>
          <Col md={12}>
            <Subtitle
              fontSize={16}
              margin={'0 0 40px'}
              fontWeight={'400'}
              text={data?.section_data?.subtitle}
              color={'rgba(4,5,4,0.3)'}
              borderColor={text}
              textTransform={'Normal'}
            />

            <h2 className={''}>{data?.section_data?.short_desc ? reactHtmlParser(data?.section_data?.short_desc) : ''}</h2>
          </Col>
        </Row>



        {
          list && list?.length > 0 &&
            list?.map((item, index) => {
              return(
                <Row key={index} className={'single-item'}>
                  <Col md={2}>
                    {
                      item?.images?.list?.[0]?.full_path &&
                        <div className="single-image">
                          <img src={item?.images?.list?.[0]?.full_path} alt={reactHtmlParser(item?.data?.title)}/>
                        </div>
                    }

                  </Col>
                  <Col md={3}>
                    {
                      item?.data?.title &&
                        <>
                          <h6 className={''}>Scholarship</h6>
                          <p className={''}>{reactHtmlParser(item?.data?.title)}</p>
                        </>
                    }

                  </Col>
                  <Col md={3}>
                    {
                      item?.data?.name &&
                        <>
                          <h6 className={''}>Scholarship Type</h6>
                          <p className={''}>{reactHtmlParser(item?.data?.name)} </p>
                        </>
                    }

                  </Col>
                  <Col md={2}>
                    {
                      item?.data?.position &&
                        <>
                          <h6 className={''}>Scholarship Amount</h6>
                          <p className={''}>{reactHtmlParser(item?.data?.position)}</p>
                        </>
                    }

                  </Col>
                  <Col md={2}>
                    <ButtonIconRight height={48} iconSVG width={'170'} src={`/scholarship/${item?.data?.slug}`} text={'View Details'} hoverTextcolor={white} borderColor={hover} background={hover} hoverBackground={text}/>

                  </Col>
                </Row>
              );
            })
        }
      </Container>
    </StyledScholarshipOppurtunities>
  );
};

const StyledScholarshipOppurtunities = styled.section`
  background: #F1F0EE;

  h2 {
    margin-bottom: 60px;
  }

  h6 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: rgba(4, 5, 4, 0.5);
    margin: 0 0 10px;
  }

  p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    color: ${text};
  }

  .single-item {
    padding-bottom: 30px;
    margin-bottom: 30px;
    position: relative;
    @media(max-width: 992px){
      >div{
        margin-bottom: 30px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    &:last-child{
      margin-bottom: 0;
      padding-bottom: 0;
      &:after{
        display: none;
      }
    }

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      width: calc(100% - 30px);
      margin: 0 auto;
      height: 1px;
      background: rgba(4, 5, 4, 0.2);
      bottom:0;
      content: '';
    }

    .single-image {
      background: #fff;
      padding-top: calc(120 / 170* 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        position: absolute;
        margin: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      

    }
  }
  @media(max-width: 992px){
    .col-md-3{
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-md-2{
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;
export default React.memo(Oppurtunities);