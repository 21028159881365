import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/pagination';
import reactHtmlParser from 'react-html-parser';
import {ImgLazyLoad} from '../nzuac-image/ImgLazyLoad';
import {hover, text} from '../../styles/globalStyleVars';

/**
 * Testimonial Type one component section
 * Pass the props with your data to the Testimonial Type one component.
 * Check the page folder to see the components used.
 **/
const PersonMessage = ({background, data, padding}) => {
  /**
     * variables
     * Change / add your custom variable
     **/



  let [offset, setOffset] = useState(90);

  useEffect(() => {
    if (window.innerWidth > 767) {
      setOffset(document.querySelector('.container').offsetLeft + 0);
    }
  }, [data]);



  return (
    <StyledPersonMessage
      offsetStyle={offset}
      background={background}
      className={`person_message ${padding ? padding : 'pt-160'}  as-image-text-slider`}
    >
      <Container>
        <div className="wrapper-man">


          {
            data?.section_data?.reverse === 'key1' ?
              <Row className={'yes-revers'}>

                <Col md={{span: 5}}>
                  <div className="image_wrapper ">
                    <ImgLazyLoad alt={reactHtmlParser(data?.section_data?.title)} src={data?.images?.list?.[0]?.full_path}/>
                  </div>
                </Col>
                <Col md={{span:6, offset: 1}}>
                  <div className="content">
                    {
                      data?.section_data?.description &&
                        reactHtmlParser(data?.section_data?.description)
                    }
                    <div className="designation">
                      {
                        data?.section_data?.title &&
                          <h5 className={'split-up'}>{reactHtmlParser(data?.section_data?.title)}</h5>
                      }
                      {
                        data?.section_data?.subtitle &&
                          <p className={'split-up'}>{reactHtmlParser(data?.section_data?.subtitle)}</p>
                      }
                    </div>
                  </div>
                </Col>

              </Row>
              :
              <Row className={'no-revers'}>
                <Col md={6}>
                  <div className="content">
                    {
                      data?.section_data?.description &&
                                      reactHtmlParser(data?.section_data?.description)
                    }
                    <div className="designation">
                      {
                        data?.section_data?.title &&
                                        <h5 className={'split-up'}>{reactHtmlParser(data?.section_data?.title)}</h5>
                      }
                      {
                        data?.section_data?.subtitle &&
                                        <p className={'split-up'}>{reactHtmlParser(data?.section_data?.subtitle)}</p>
                      }
                    </div>
                  </div>
                </Col>
                <Col md={{span: 5, offset: 1}}>
                  <div className="image_wrapper ">
                    <ImgLazyLoad src={data?.images?.list?.[0]?.full_path} alt={reactHtmlParser(data?.section_data?.title)}/>
                  </div>
                </Col>


              </Row>
          }
        </div>
      </Container>
    </StyledPersonMessage>
  );
};

/**
 * Styled component for Testimonial Type one component section
 * Add your styles for Testimonial Type one component here.
 **/
const StyledPersonMessage = styled.section`

  .wrapper-man {
        padding: 70px;
        border: 1px solid ${hover};

        .content {
            p {
                color: ${text};
            }

            .designation {
                margin-top: 60px;

                h5 {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 24px;
                    color: ${text};

                }

                p {
                    font-weight: 300;
                }
            }
        }

        .image_wrapper {
            padding-top: calc(520 / 400 * 100%);
            position: relative;
        }
    }


    @media (max-width: 992px) {
        .wrapper-man {
            .col-md-6, .col-md-5 {
                flex: 0 0 100%;
                max-width: 100%;
                margin: 0;
                padding: 0;
            }

            .yes-revers {

                .col-md-5 {
                    margin-bottom: 30px;
                }
            }

            .no-revers {
                flex-direction: column-reverse;

                .col-md-5 {
                    margin-bottom: 30px;
                }
            }
        }

    }

    @media (max-width: 767px) {
        .wrapper-man {
            padding: 15px;

            .col-md-6, .col-md-5 {

                padding: 0 15px;
            }
        }
    }
`;

export default React.memo(PersonMessage);
