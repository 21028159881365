import React from 'react';
import styled from 'styled-components';

const NavigationIcon = ({next_id, prev_id, color, banner, background}) => {


  return (

    <StyledNavigationIcon className={'navigation_button'}>
      <ul>
        <li className="hover_left hoverable" id={prev_id ? prev_id : 'service-prev'}>




          <svg id="Button_-_Nav" data-name="Button - Nav" xmlns="http://www.w3.org/2000/svg"  width="48" height="48" viewBox="0 0 48 48">
            <defs>
              <clipPath id="clip-path">
                <rect id="Mask" width="48" height="48" rx="24" transform="translate(1220 2912)" fill={background ? background : '#fff'}/>
              </clipPath>
            </defs>
            <g id="Mask_Group_271" data-name="Mask Group 271" transform="translate(-1220 -2912)" clipPath="url(#clip-path)">
              <rect id="Base" width="48" height="48" rx="24" transform="translate(1220 2912)" fill={background ? background : '#fff'}/>
              <g id="Arrow"  className={'white-arrow'} transform="translate(1239 2931)">
                <g id="Group_4823" data-name="Group 4823">
                  <line id="Line_9" data-name="Line 9" x1="5" y2="5" fill="none" stroke={color ? color: '#040504'} strokeLinecap="round" strokeWidth="1"/>
                  <line id="Line_10" data-name="Line 10" x2="5" y2="5" transform="translate(0 5)" fill="none" stroke={color ? color: '#040504'} strokeLinecap="round" strokeWidth="1"/>
                </g>
                <line id="Line_11" data-name="Line 11" x1="10" transform="translate(0 5)" fill="none" stroke={color ? color: '#040504'} strokeLinecap="round" strokeWidth="1"/>
              </g>
              <g id="Hover" transform="translate(0 52)">
                <rect id="Base-2" data-name="Base" width="48" height="48" rx="24" transform="translate(1220 2912)" fill="#a08750"/>
                <g id="Arrow-2" data-name="Arrow" transform="translate(1239 2931)">
                  <g id="Group_4823-2" data-name="Group 4823">
                    <line id="Line_9-2" data-name="Line 9" x1="5" y2="5" fill="none" stroke={background ? background : '#fff'} strokeLinecap="round" strokeWidth="1"/>
                    <line id="Line_10-2" data-name="Line 10" x2="5" y2="5" transform="translate(0 5)" fill="none" stroke={background ? background : '#fff'} strokeLinecap="round" strokeWidth="1"/>
                  </g>
                  <line id="Line_11-2" data-name="Line 11" x1="10" transform="translate(0 5)" fill="none" stroke={background ? background : '#fff'} strokeLinecap="round" strokeWidth="1"/>
                </g>
              </g>
            </g>
          </svg>


        </li>
        <li className="hover_right hoverable" id={next_id ? next_id : 'service-next'}>

          <svg id="Button_-_Nav" data-name="Button - Nav" xmlns="http://www.w3.org/2000/svg"  width="48" height="48" viewBox="0 0 48 48">
            <defs>
              <clipPath id="clip-path">
                <rect id="Mask" width="48" height="48" rx="24" transform="translate(1220 2912)" fill={background ? background : '#fff'}/>
              </clipPath>
            </defs>
            <g id="Mask_Group_271" data-name="Mask Group 271" transform="translate(-1220 -2912)" clipPath="url(#clip-path)">
              <rect id="Base" width="48" height="48" rx="24" transform="translate(1220 2912)" fill={background ? background : '#fff'}/>
              <g id="Arrow" className={'white-arrow-RIGHT'} transform="translate(590.5 1334.5)">
                <g id="Group_4823" data-name="Group 4823" transform="translate(653.5 1596.5)">
                  <line id="Line_9" data-name="Line 9" x2="5" y2="5" fill="none" stroke={color ? color: '#040504'} strokeLinecap="round" strokeWidth="1"/>
                  <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(0 5)" fill="none" stroke={color ? color: '#040504'} strokeLinecap="round" strokeWidth="1"/>
                </g>
                <line id="Line_11" data-name="Line 11" x2="10" transform="translate(648.5 1601.5)" fill="none" stroke={color ? color: '#040504'} strokeLinecap="round" strokeWidth="1"/>
              </g>
              <g id="Hover" transform="translate(0 52)">
                <rect id="Base-2" data-name="Base" width="48" height="48" rx="24" transform="translate(1220 2912)" fill="#a08750"/>
                <g id="Arrow-2" data-name="Arrow" transform="translate(590.5 1334.5)">
                  <g id="Group_4823-2" data-name="Group 4823" transform="translate(653.5 1596.5)">
                    <line id="Line_9-2" data-name="Line 9" x2="5" y2="5" fill="none" stroke={background ? background : '#fff'} strokeLinecap="round" strokeWidth="1"/>
                    <line id="Line_10-2" data-name="Line 10" x1="5" y2="5" transform="translate(0 5)" fill="none" stroke={background ? background : '#fff'} strokeLinecap="round" strokeWidth="1"/>
                  </g>
                  <line id="Line_11-2" data-name="Line 11" x2="10" transform="translate(648.5 1601.5)" fill="none" stroke={background ? background : '#fff'} strokeLinecap="round" strokeWidth="1"/>
                </g>
              </g>
            </g>
          </svg>

        </li>
      </ul>
    </StyledNavigationIcon>

  );
};


const StyledNavigationIcon = styled.div`
  position: relative;
  z-index: 10;

  ul {
    display: flex;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      overflow: hidden;
      padding: 0 !important;
      border: none !important;
      background: transparent !important;
      height: 48px;
      width: 48px;
      border-radius: 50%;
      overflow: hidden;

      &:nth-of-type(1) {
        margin-right: 20px;
      }

      .white-arrow, #Hover, .white-arrow-RIGHT {
        transition: all cubic-bezier(.74, 0, .24, .99) 0.5s;

      }


      &:hover {
        #Hover {
          transform: none;
        }

        .white-arrow-RIGHT {
          transform: translate(590.5px, 1304.5px);

        }

        .white-arrow {
          transform: translate(1239px, 2901px);
        }

        #Group_18503 {
        }
      }

    }
  }


  @media (max-width: 767px) {
    #Rectangle_5479 rect {
      rx: 25px !important;
      transform: translate(0, 50px) rotate(-90deg) !important;
    }

    #Group_18503 {
    }
  }

`;

export default React.memo(NavigationIcon);














