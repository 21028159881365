import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {useForm} from 'react-hook-form';
import {postForm} from '../../api/redux/contact';
import {useDispatch, useSelector} from 'react-redux';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {hover, text, white} from '../../styles/globalStyleVars';
import ImageSocialBox from '../nzuac-single-item/ImageSocialBox';
import Select, {components} from 'react-select';
import ButtonSubmit from '../nzuac-buttons/ButtonSubmit';

const NZUACForm = ({padding, data, id, pt}) => {

  const [selectType, setSelectType] = useState(null); // Initialize with null or the default selected value
  const [selectField, setSelectField] = useState(null); // Initialize with null or the default selected value

  const dispatch = useDispatch();
  const responseData = useSelector(state => state.contact);
  let [offset, setOffset] = useState(90);

  useEffect(() => {
    if (window.innerWidth > 767) {
      setOffset(document.querySelector('.container').offsetLeft + 0);
    }
  }, [data]);


  const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});


  // --- form submit
  const success = (msg) => toast.success(msg, {
    position: 'top-right', autoClose: 4000, closeOnClick: true, progress: undefined,

  });

  const error = (msg) => toast.error(msg, {
    position: 'top-right', autoClose: 4000, closeOnClick: true, progress: undefined,

  });


  const onSubmit = async (e) => {

    let api_services = apiEndPoints.FORM_SUBMIT;

    var formData = new FormData();
    formData.append('name', e?.name);
    formData.append('email', e?.email);
    formData.append('gender', e?.gender);
    formData.append('age', e?.age);
    formData.append('phone', e?.phone);
    formData.append('education', selectType);
    formData.append('study', selectField);
    formData.append('message', e?.message);
    formData.append('form_id', 'get-in-touch');

    if (e.email !== '' && e.name !== '' && e.phone !== '') {
      await dispatch(postForm([api_services, formData]));
      // success('Form Submit Successfully');
      reset();
      setSelectType(null);
      setSelectField(null);
    }


  };
  let count = 0;

  const onError = (errors) => {
    Object.values(errors).forEach((error) => {
      count++;
    });
    if (count > 0) {
      toast.error('please fill out the correct inputs');
    }
    count = 0;
  };
  useEffect(() => {
    if (responseData && responseData?.error !== '') {
      error(responseData?.error);
    }
    if (responseData && responseData?.success !== '') {
      success(responseData?.success);
    }
  }, [responseData]);


  const selectLocationInputRef = useRef();

  const types = [
    {value: 'Undergraduate education', label: 'Undergraduate education'},
    {value: 'Diploma', label: 'Diploma'},
    {value: 'Doctorate', label: 'Doctorate'},
  ];


  const feild_of_study = [
    {value: 'Science', label: 'Science'},
    {value: 'Arts', label: 'Arts'},
    {value: 'Engineering', label: 'Engineering'},
  ];

  const customStyles = {
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: 'all .2s cubic-bezier(.74,0,.24,.99)',
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      color: state.isSelected ? `${white}` : text,
      backgroundColor: state.isSelected ? hover : white,
      margin: 0,
      fontSize: 16,
      cursor: 'pointer',
      lineHeight: '20px',
      paddingLeft: 10,
      paddingRight: 10,
      fontWeight: state.isSelected ? 400 : 400,
      borderBottom: state.options.indexOf(state.data) === state.options.length - 1 ? 'none' : '1px solid #888888', // Check if it's the last item

      '&:hover': {
        backgroundColor: `${hover}`,
        color: `${white}`,
        cursor: 'pointer'
      },

    }), menu: (provided, state) => ({
      ...provided,
      color: '#888888',
      backgroundColor: state.isSelected ? `${white}` : white,
      margin: '15px 0 0 0',
      border: `1px solid ${hover}`,
      padding: 0,
      borderRadius: 10,
      fontSize: 12,
      zIndex: 10,
      borderBottom: '1px solid #888888'

      // width: 200,
    }), menuList: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? `${white}` : white,
      borderRadius: 10,
      fontWeight: '400',
      color: '#FFF',
      fontSize: 12,
    }),

  };


  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon/>
      </components.DropdownIndicator>
    );
  };
  const CaretDownIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="12.828" height="7.828" viewBox="0 0 12.828 7.828">
        <g id="Arrow" transform="translate(17238.414 7077.914)">
          <line id="Line_3886" data-name="Line 3886" x2="5" y2="5" transform="translate(-17237 -7076.5)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="2"/>
          <line id="Line_3887" data-name="Line 3887" x1="5" y2="5" transform="translate(-17232 -7076.5)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="2"/>
        </g>
      </svg>

    );
  };


  const handleLebel = (e) => {
    setSelectType(e?.label);
  };

  const handleStudy = (e) => {

    setSelectField(e?.label);

  };


  return (<StyledListWithForm pt={pt} id={`${id ? id : 'ListWithForm'}`}

    className={`list_with_form ${padding ? padding : ''} `}>
    <Container fluid style={{paddingLeft: offset + 'px'}}>
      <Row>

        <Col md={6} className={'pr-0'}>
          <ImageSocialBox description={data?.section_data?.description} title={data?.section_data?.subtitle} subtitle={data?.section_data?.short_desc} img={data?.images?.list?.[0]?.full_path}/>
        </Col>
        <Col className={'form-column'} md={6}>
          <div className="">

          </div>
          <div className="form_wrapper" style={{paddingRight: offset + 'px'}}>
            <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)}>

              <input name={'spam_protector'} type="hidden"/>
              <input name={'form_id'} value={'contact-form'} type="hidden"/>
              <div className="form-group">
                <Form.Group controlId="formBasicEmail">

                  <Form.Control
                    className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                    {...register('name', {
                      required: 'Name is required',

                    })}
                    type="text"
                    placeholder="Name*"
                  />
                </Form.Group>
              </div>
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <Form.Group controlId="formBasicEmail">

                      <Form.Control
                        className={formState?.errors?.gender?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                        {...register('gender', {
                          required: 'Gender is required',

                        })}
                        type="text"
                        placeholder="Gender*"
                      />
                    </Form.Group>
                  </div>


                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <Form.Group controlId="formBasicEmail">

                      <Form.Control
                        className={formState?.errors?.age?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                        {...register('age', {
                          required: 'Age is required',

                        })}
                        type="number"
                        placeholder="Age*"
                      />
                    </Form.Group>
                  </div>

                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <Form.Group controlId="formBasicEmail">

                      <Form.Control
                        className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                        {...register('email', {
                          required: {
                            value: true, message: 'please enter your email'
                          }, pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'please enter a valid email address'
                          }
                        })}
                        type="email"
                        placeholder="Email*"/>
                    </Form.Group>
                  </div>

                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <Form.Group controlId="formBasicPhone">

                      <Form.Control
                        className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                        {...register('phone', {
                          required: {
                            value: true, message: 'please enter your phone first'
                          }, pattern: {
                            value: /^01[0-9]{9}$/,
                            message: 'please enter a valid 11 digit phone number'
                          }
                        })}
                        type="number"
                        placeholder="Phone Number*"/>
                    </Form.Group>
                  </div>


                </Col>

              </Row>

              <div className="form-group">
                <Form.Group controlId="formBasicPhone">
                  <Select
                    {...register('education', {
                      required: {
                        // value:true,
                        message: 'What is your level of education?',
                      },
                    })}
                    classNamePrefix="filter"
                    ref={selectLocationInputRef}
                    isSearchable={false}
                    isClearable={true}
                    options={types}
                    onChange={e => {
                      handleLebel(e);
                    }}
                    placeholder={'What is your level of education?'} styles={customStyles}
                    components={{DropdownIndicator}}
                  />
                </Form.Group>
              </div>

              <div className="form-group">
                <Form.Group controlId="formBasicPhone">
                  <Select
                    {...register('study', {
                      required: {
                        // value:true,
                        message: 'What is your preferred field of study?',
                      },
                    })}
                    classNamePrefix="filter"
                    ref={selectLocationInputRef}
                    isSearchable={false}
                    isClearable={true}
                    options={feild_of_study}
                    onChange={e => {
                      handleStudy(e);
                    }}
                    placeholder={'What is your preferred field of study?'} styles={customStyles}
                    components={{DropdownIndicator}}
                  />
                </Form.Group>
              </div>
              <div className="form-group">
                <Form.Group controlId="formBasicPhone">
                  <textarea
                    className={formState?.errors?.email?.message ? 'has-error form-control-lg textarea form-control' : 'textarea form-control form-control-lg'}
                    {...register('message', {
                      required: {
                        // value:true,
                        message: '',
                      },
                    })}
                    placeholder="How may we help you?"
                    rows={4} // You can adjust the number of rows as needed
                  />
                </Form.Group>

              </div>


              <div className="note">
                <p>* NZUAC will not share your details with others</p>
              </div>
              <div className={'form-group '}>
                <div onClick={handleSubmit(onSubmit, onError)}>

                  <ButtonSubmit height={50}  width={185} text={'Submit Message'} hoverTextcolor={white} borderColor={white} background={hover} hoverBackground={text}/>

                </div>

              </div>

            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  </StyledListWithForm>);
};

const StyledListWithForm = styled.div`
  box-sizing: border-box;
  position: relative;
  padding-top: ${(props) => (props.pt ? props.pt + 'px' : '0')};


  .social {
    a {
      &:hover {
        path {
          fill: ${text} !important;
        }
      }
    }
  }

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #F1F0EE;
    height: 50%;
    width: 100%;
    content: '';
    z-index: -1;

  }

  &:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: ${text};
    height: 50%;
    width: 100%;
    content: '';
    z-index: -1;
  }

  .note {
    margin-bottom: 30px;

    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
    }
  }

  .form_wrapper {
    padding: 70px 0 70px 70px;
    height: 100%;
    position: relative;
    // overview_bg

    .left-col {
      div {
        height: 100%;
      }
    }


    form {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;
    }

    .textarea {
      min-height: 120px;
    }

    .title {
      font-size: 24px;
      font-weight: 4000;
      line-height: 28px;
      color: #F9F9F9;
      margin: 0 0 40px;
    }

    .form-group {
      margin-bottom: 30px;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        display: block;
      }

      .form-control {
        box-shadow: none;
        outline: 0;
        border-radius: 0;
        background: transparent;
        height: auto;
        border-left: none;
        border-right: none;
        border-top: none;
        border-color: rgba(4, 5, 4, 0.2);
        padding: 0 0 8px;
        color: #040504;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;

        svg {
          display: block;
        }

        &:focus {
          border-color: rgba(4, 5, 4, 1);

        }

        &::placeholder {
          color: rgba(4, 5, 4, 0.4);
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }


  }

  .row {
    .form-group {
      margin-bottom: 40px !important;
    }
  }

  .form-column {
    background: ${white};
  }


  .form_wrapper {
    .row {
      margin: 0;
      width: 100%;
      gap: 15px;
      justify-content: space-between;

      .col-md-6 {
        flex: 0 0 calc(50% - 15px);
        max-width: calc(50% - 15px);
        padding-left: 0;
        padding-right: 0;
      }
    }
  }


  //selector

  .filter__control {
    border-left: none;
    border-right: none;
    border-radius: 0;
    border-top: none;
    padding-left: 0;

    .filter__value-container {
      padding-left: 0;
      padding-right: 0;
    }

    .filter__single-value {
      color: rgba(4, 5, 4, 1);
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }

    .filter__placeholder {
      color: rgba(4, 5, 4, 0.4);
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }

    .filter__indicator-separator {
      display: none;
    }

    .filter__indicator {
      padding-right: 0;
    }
  }

  .filter__control--is-focused {
    outline: none;
    border-bottom: 1px solid rgba(4, 5, 4, 0.2) !important;
    box-shadow: none;
  }


  @media (max-width: 992px) {
    transform: none;
    .social {
      align-items: flex-start !important;
      justify-content: flex-start !important;
    }

    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .container-fluid {
      padding-left: 0 !important;
    }

    .single_content_wrp {
      padding: 40px 20px;
      @media (max-width: 767px) {
        padding: 30px 15px !important;
      }
    }

    .form_wrapper {
      padding: 40px 20px;
      @media (max-width: 767px) {
        padding: 30px 15px !important;
      }
    }
  }
`;

export default React.memo(NZUACForm);
