import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container} from 'react-bootstrap';
import {ImgLazyLoad} from '../nzuac-image/ImgLazyLoad';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import {Autoplay, Navigation, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import reactHtmlParser from 'react-html-parser';
// import NavigationIcon from '../nzuac-form/NavigationIcon';
import {hover, text} from '../../styles/globalStyleVars';
import Subtitle from '../nziuac-title/Subtitle';

const MyComponent = ({data}) => {

  const [offset, setOffset] = useState(0);
  useEffect(() => {
    setOffset(document.querySelector('.container')?.offsetLeft + 15);
    window.addEventListener('resize', () => {
      setOffset(document.querySelector('.container')?.offsetLeft + 15);
    });
  }, []);


  return (
    <StyledComponent offset={offset} className={'awards pt-160 pb-160 no-line'}>
      <Container>

        <div className="awards__top">
          <Subtitle
            fontSize={16}
            margin={'0 0 40px'}
            fontWeight={'400'}
            text={data?.section_data?.subtitle}
            color={'rgba(4,5,4,0.3)'}
            borderColor={text}
            textTransform={'Normal'}
          />


        </div>
        {/*<NavigationIcon background={text}*/}
        {/*  color={white}*/}
        {/*  prev_id={'slider_prev_award'}*/}
        {/*  next_id={'slider_next_award'}*/}
        {/*/>*/}
        <div className="blog-button">
          <div className="slider-nav">
            <ul>
              <li id={'slider_prev_award'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414" viewBox="0 0 11.207 11.414">
                  <g id="Arrow" transform="translate(0.707 0.707)">
                    <g id="Group_23756" data-name="Group 23756">
                      <line id="Line_3910" data-name="Line 3910" x1="5" y2="5" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                      <line id="Line_3911" data-name="Line 3911" x2="5" y2="5" transform="translate(0 5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                    </g>
                    <line id="Line_3912" data-name="Line 3912" x1="10" transform="translate(0 5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                  </g>
                </svg>


              </li>
              <li id={'slider_next_award'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414" viewBox="0 0 11.207 11.414">
                  <g id="Arrow" transform="translate(-648 -1595.793)">
                    <g id="Group_4823" data-name="Group 4823" transform="translate(653.5 1596.5)">
                      <line id="Line_9" data-name="Line 9" x2="5" y2="5" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                      <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(0 5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                    </g>
                    <line id="Line_11" data-name="Line 11" x2="10" transform="translate(648.5 1601.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                  </g>
                </svg>
              </li>
            </ul>
          </div>
        </div>
      </Container>

      <Container className={'for-mobile-gap'}>
        {data?.posts?.list?.length > 0 &&
                <Swiper loop={false}
                  spaceBetween={30}
                  speed={1500}
                  navigation={{
                    prevEl: '#slider_prev_award',
                    nextEl: '#slider_next_award ',
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  breakpoints={{
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                    500: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },

                  }}>
                  {data?.posts?.list?.map((i, index) => (
                    <SwiperSlide key={index}>
                      <div className="awards__slider">
                        <div className="awards__slider__item">
                          <ImgLazyLoad src={i?.images?.[0]?.full_path} alt={reactHtmlParser(i?.data?.subtitle)}/>
                        </div>
                        <div className="content">
                          <p className="year split-up">{reactHtmlParser(i?.data?.subtitle)}</p>
                          <p className={'split-up'}>{reactHtmlParser(i?.data?.title)}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
        }
      </Container>
    </StyledComponent>
  );
};
const StyledComponent = styled.section`
    background-color: #FFFFFF;

    .awards__top {
        display: flex;
        justify-content: space-between;

        ul {
            display: flex;
        }

    }

    .blog-button {
        margin-bottom: 40px;
    }

    .awards__slider {
        width: 100%;

        &__item {
            padding-top: calc(230 / 270 * 100%);
            position: relative;
            background-color: #FFF;
            padding-left: 20px;
            padding-right: 20px;
            border: 1px solid ${hover};

            img {
                object-fit: contain;
                margin: auto;
                max-width: 155px;
                height: 90px;
            }
        }

        .content {
            margin-top: 20px;
        }

        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: ${hover};
            margin-bottom: 0;
            margin-top: 0;

            &.year {
                color: ${text};
                font-weight: 300;
            }
        }
    }


    @media (max-width: 767px) {
      .for-mobile-gap{
        padding-right: 0;
      }
        .swiper {
            padding-right: ${p => p.offset}px;
        }
    }


    @media (max-width: 990px) {
        .awards__top {
            flex-wrap: wrap;

            ul {
                width: 100%;
            }
        }
    }

    @media (max-width: 500px) {
        .swiper {
            padding-right: 80px;
        }
    }
`;
export default MyComponent;