import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import gsap, {CSSPlugin} from 'gsap';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {fetchPosts, fetchPostsChild} from '../../api/redux/university';
import {useDispatch, useSelector} from 'react-redux';
import {SplitText} from 'gsap/SplitText';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {ApiParam} from '../../api/network/apiParams';
import VersityList from '../../components/nzuac-section/VersityList';
import {motion} from 'framer-motion';
import {PageAnimation} from '../../components/nzuic-animations/PageAnimation';
import {useLocation} from 'react-router-dom';



gsap.registerPlugin(ScrollTrigger, CSSPlugin, SplitText);

const UNIVERSITY = () => {


  const dispath = useDispatch();


  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;

    let param = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'university-list',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };
    let api_url_child = apiEndPoints.FILTERUNIVERSITY;
    dispath(fetchPosts([api_url, param]));
    dispath(fetchPostsChild([api_url_child]));


  }, [dispath]);


  let getPost = useSelector(store => store.university);

  let page_data = getPost?.posts?.data?.page_data;
  let sections_list = getPost?.posts?.data?.sections;


  const location = useLocation();
  useEffect(() => {
    // Use GSAP to animate opacity from 0 to 1
    gsap.to('.hide', {alpha: 1, visibility: 'visible', display: 'block', duration: 0.5, delay: 1});
    gsap.to('.pagetransition-loader', {alpha: 0, display: 'none', height: '0', duration: 0.5, delay: 1});
    gsap.to('.desktop-menu__bottom__parent-ul > li ul', {alpha: 0, duration: 0.5, delay: 1});

    // if()

    const body = document.querySelector('body');
    const html = document.querySelector('html');
    const applyNow = document.querySelector('.applyNow');

    if (body.classList.contains('scroll-down')) {
      body.classList.remove('scroll-down');
    }

    if (body.classList.contains('scroll-up')) {
      body.classList.remove('scroll-up');
    }

    if (body.classList.contains('menu-is-open')) {
      body.classList.remove('menu-is-open');
    }

    if (html.classList.contains('menu-is-open')) {
      html.classList.remove('menu-is-open');
    }

    if (body.classList.contains('mobile-menu-open')) {
      body.classList.remove('mobile-menu-open');
    }

    if ((body.classList.contains('stop-scroll')) && window.innerWidth < 992) {
      body.classList.remove('stop-scroll');
    }

    if ((applyNow) && window.innerWidth < 992) {
      applyNow.classList.remove('mobile-menu-open');
    }

    if (html.classList.contains('mobile-menu-open')) {
      html.classList.remove('mobile-menu-open');
    }
    var menuOpen = document.querySelectorAll('.Mobile-menu-wrap');
    var hamburger = document.querySelectorAll('.hamburger');
    var menu = document.querySelectorAll('.main_child');
    var menuSub = document.querySelectorAll('.main-child-sub ');
    var menuSubSub = document.querySelectorAll('.main-child-sub-sub');
    hamburger.forEach((i) => {
      i.classList.remove('menu-open');
      gsap.to(document.querySelector('.mobile-menu__items'), {
        opacity: 0,
        duration: '.2'
      });
      gsap.to(document.querySelector('.mobile-menu__items'), {
        display: 'none'
      });
    });
    menuOpen.forEach((i) => {
      i.classList.remove('menu-open');
    });

    menu.forEach((i) => {
      i.classList.remove('submenu-open-mb');
    });
    menuSub.forEach((i) => {
      i.classList.remove('submenu-open-next');
    });
    menuSubSub.forEach((i) => {
      i.classList.remove('submenu-open-next-next');
      i.classList.remove('submenu-open-next');
      i.classList.remove('submenu-open-mb');
    });

    const contact = document.querySelector('.submenu ');



    if((contact)  && window.innerWidth < 992 ){
      if(contact.classList.contains('sub-menu-open')){
        contact.classList.remove('sub-menu-open');

      }
    }
    // You can customize the animation duration and other properties as needed
  }, [location.pathname]); // Run this effect only once when the component mounts



  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`${page_data?.title ? page_data?.title + ' - New Zealand University Application Centre' : 'New Zealand University Application Centre - Provides institutional brokerage services'}`}</title>
        {
          page_data?.meta_title &&
              <meta name="title" content={page_data?.meta_title ? page_data?.meta_title : 'New Zealand University Application Centre'}/>

        }

        {
          page_data?.meta_description &&
              <meta name="description" content={page_data?.meta_description}/>

        }
        {
          page_data?.og_title &&
              <meta property="og:title" content={page_data?.og_title}/>

        }
        {
          page_data?.og_description &&
              <meta property="og:description" content={page_data?.og_description}/>

        }
      </Helmet>
      <motion.div key={'193'} className="page-loader pagetransition-loader" exit="exit" animate="anim"
        variants={PageAnimation}
        initial="init">
      </motion.div>
      <StyledComponent>

        {
          sections_list?.length > 0 && sections_list.map((item, index) => {
            switch (item?.section_data?.template) {
            case 'versity_list':
              return (
                <React.Fragment key={index}>
                  <VersityList data={item}  key={index}/>
                </React.Fragment>
              );

            default:
              return null; // If the template doesn't match any case, render nothing
            }
          })
        }

      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`
  background: #F1F0EE;
`;

export default UNIVERSITY;
