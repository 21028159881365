import React from 'react';
import styled from 'styled-components';
import {hover} from '../../styles/globalStyleVars';

const SignUpIcon = ({enable,onClick}) => {

  const handleLocation = (e) => {
    // Remove the following line:
    // setSelectLocation(e);

    // Invoke the onChange callback with the selected value
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <StyledSearchButton  className={enable ? 'enable' : ''}   onClick={e => {
      handleLocation(e);
    }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <g id="Group_24259" data-name="Group 24259" transform="translate(-904 -457)">
          <rect id="Navbar_-_Base" data-name="Navbar - Base" width="48" height="48" rx="24" transform="translate(904 457)" fill="#888"/>
          <path id="Path_2480" data-name="Path 2480" d="M17.724,16.591,15.465,14.34a7.6,7.6,0,1,0-1.125,1.125l2.251,2.259a.8.8,0,1,0,1.133-1.133ZM3.588,9.575a5.986,5.986,0,1,1,5.986,5.986A5.986,5.986,0,0,1,3.588,9.575Z" transform="translate(918.039 471.04)" fill="#fff"/>
        </g>
      </svg>


    </StyledSearchButton>
  );
};

const StyledSearchButton = styled.div`
   cursor: pointer;

  @keyframes flicker {
    0% {
      stroke-dasharray: 1;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 0.1;
      stroke-dashoffset: 0.5;
    }
    100% {
      stroke-dasharray: 1;
      stroke-dashoffset: 0;
    }
  }
  &.enable{
    svg{
      rect{
        fill: ${hover};
      }
      path{
        animation: flicker 1s infinite;
      }
    }
  }
`;

export default React.memo(SignUpIcon);
