import React from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import {Container, Row} from 'react-bootstrap';
import 'simplebar-react/dist/simplebar.min.css';
import CloseIconSearch from '../nzuac-svg/CloseIconSearch';
import NZUACModal from './NZUACModal';

const Popup = ({
  show,
  handleClose,
  no_img,
  item,
  title,
  description,
  data,
  subtitle,
  img
}) => {


  return (

    <StyledModal>
      <Modal
        show={show}
        item={item}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="gph_modal popup-version-one form-modal"
      >
        <Modal.Body>
          <Container className={'close-icon-modal'}>
            <Row className={'for-close'} onClick={handleClose}>
              <div className="modal-close ">
                <CloseIconSearch/>
              </div>
            </Row>
          </Container>
          <Container>
            <Row>
              <div className="modal-data d-flex">
                <NZUACModal pt={'100'}/>
              </div>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </StyledModal>

  );
};


const StyledModal = styled.section`
    background-color: #F5F5F5;

    .modal-dialog {
        max-width: 100% !important;
    }

    h4 {
        letter-spacing: 1.5px;
    }


`;


export default Popup;
