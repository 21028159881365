import React from 'react';
import styled from 'styled-components';
import reactHtmlParser from 'react-html-parser';
import {hover} from '../../styles/globalStyleVars';

/**
 * Icon Left component
 * Pass the props with your data to the Icon Left component.
 **/
const FooterAddressText = ({address, link, title}) => {
  return (
    <StyledFooterAddressText>
      <p>{title ? title : ''}</p>
      <a href={link ? link : ''} target={'_blank'} rel="noreferrer">
        {
          address ?
            reactHtmlParser(address) : ''
        }
      </a>
    </StyledFooterAddressText>
  );
};

/**
 * Styled component for Icon Left Single
 * Add your styles for  Icon Left component here.
 **/
const StyledFooterAddressText = styled.div`
    p {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 10px;
    }

    a {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(255, 255, 255, 1);

        &:hover {
            color: ${hover}
        }
    }

`;

export default React.memo(FooterAddressText);
