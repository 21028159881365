import React, {useEffect} from 'react';
import styled from 'styled-components';
import {hover, white} from '../../styles/globalStyleVars';
// import {Img} from '../nzuac-image/Img';
import {Col, Container, Row} from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import {ImgLazyLoad} from '../nzuac-image/ImgLazyLoad';
import {gsap} from 'gsap';
import LandingFilterForm from '../nzuac-form/LandingFilterForm';
import CounterSingle from '../nzuac-single-item/CounterSingle';

const BannerCarousel = ({offset, data, filter}) => {


  // variables

  const sectionData = data?.posts?.list;

  useEffect(() => {
    // Use GSAP to animate opacity from 0 to 1
    gsap.to('.Home-banner', {alpha: 1, visibility: 'visible', duration: 0.5, delay: 0.5});
    gsap.to('.slide-inner', {alpha: 1, visibility: 'visible', duration: 0.5, delay: 0.5});
    gsap.to('.slide-inner h1', {alpha: 1, visibility: 'visible', duration: 1, delay: 1});
    gsap.to('.slide-inner p', {alpha: 1, visibility: 'visible', duration: 0.5, delay: 0.5});
    gsap.to('.single', {alpha: 1, visibility: 'visible', duration: 0.5, delay: 0.5});
    gsap.to('.search-wrapper-home', {alpha: 1, visibility: 'visible', duration: 0.5, delay: 0.5});





    // You can customize the animation duration and other properties as needed
  }, [sectionData]); // Run this effect only once when the component mounts





  return (<StyledBanner offset={offset} className={'Home-banner'}>

    {sectionData && sectionData?.length > 0 && sectionData.map((item, index) => {
      return (
        <div className="slide-inner" key={index}>


          {window.innerWidth > 767 ? // change the image path for desktop devices
            <div className="desktop">
              <ImgLazyLoad alt={item?.data?.title}
                src={item?.images[0]?.full_path ? item?.images[0]?.full_path : ''}/>
            </div>

            : // change the image path for mobile devices
            <div className="mobile">
              <ImgLazyLoad alt={item?.data?.title}
                src={item?.images[1]?.full_path ? item?.images[1]?.full_path : item?.images[0]?.full_path}/>

            </div>

          }


          <Container>
            <Row>
              <Col md={8}>
                <div className="slide-inner__info">
                  {
                    index === 0 ?
                      <h1 className={'swiper__title'}>
                        {ReactHtmlParser(item?.data?.subtitle)}</h1>

                      :
                      <h2 className={'swiper__title '}>
                        {ReactHtmlParser(item?.data?.title)}</h2>
                  }
                  {
                    item?.data?.short_desc ?
                      <p className={''}>{ReactHtmlParser(item?.data?.short_desc)}</p>

                      : ''
                  }


                  {
                    filter ?
                      <div className="search-wrapper-home">
                        <LandingFilterForm data={filter}/>
                      </div>
                      : ''
                  }

                  <div className="coutner-wrapper">
                    <div className="single">
                      <CounterSingle img={'./images/static/acclaimed.svg'} text={'Acclaimed Institutes'} value={50}/>

                    </div>
                    <div className="single">
                      <CounterSingle img={'./images/static/enrollment.svg'} text={'Enrollment Support'} value={880}/>

                    </div>
                    <div className="single">
                      <CounterSingle img={'./images/static/courses.svg'} text={'Total Courses'} value={1500}/>

                    </div>
                  </div>

                </div>
              </Col>
            </Row>
          </Container>
        </div>


      );

    })}



  </StyledBanner>);
};

const StyledBanner = styled.section`
    min-height: 100svh;
    position: relative;
    //overflow: hidden;
    background: #040504;
    opacity: 0;
    visibility: hidden;


    &:after {
        position: absolute;
        left: 0;
        right: 0;
        height: 120px;
        width: 100%;
        bottom: 0;
        content: '';
        background: linear-gradient(to bottom, rgba(4, 5, 4, 0), rgba(4, 5, 4, 1));
        z-index: 0;
    }

    //all style for swiper slider

    .swiper-button-prev, .swiper-button-next, .swiper-pagination, .mobile {
        display: none;
    }

    .swiper-slide {
        overflow: hidden;
    }

    .swiper-pagination {
        display: none;
    }

    .swiper-button-disabled {
        opacity: 0.5 !important;
    }

    .swiper-slide-active, .swiper-slide-visible {
        .global-image {
            clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }

        .slide-inner {

            &__info {
                transition-delay: 0.3s;
                opacity: 1;
                transform: translateX(0px) !important;

                .dc-btn {
                    transition-delay: 0.4s;
                    opacity: 1;
                    transform: translateX(0px) !important;
                }
            }

        }
    }

    //style for next prev button


    //slider style 

    .slide-inner {
        position: relative;
        min-height: 100svh;


        .container {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 100px;
            z-index: 2;

            @media (width: 1280px) and (max-height: 620px) {
                bottom: 80px;

            }
        }

        .global-image {
            transition: clipPath .8s cubic-bezier(.29, .73, .45, 1), border-color 3.6s linear, -webkit-clipPath .8s cubic-bezier(.29, .73, .45, 1);
            will-change: clipPath;
            overflow: hidden;
            -webkit-clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);

            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                z-index: 2;
                background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
            }
        }

        &__info {
            position: relative;
            transition: all 2s cubic-bezier(.25, .74, .22, .99);
            transition-delay: 1.2s;
            opacity: 1;
            z-index: 9;
            @media (min-width: 1024px) {
                width: 100%;
            }

            h2, h1 {
                color: ${white};
                position: relative;
                margin: 0 0 20px;
                font-size: 80px;
                font-weight: 600;
                line-height: 80px;
                overflow: hidden;
                opacity: 0;
                visibility: hidden;


                @media (width: 1280px) and (max-height: 620px) {
                    font-size: 60px;
                    line-height: 60px;
                }

                span {
                    color: ${hover}
                }
            }


            p {
                font-size: 14px;
                line-height: 21px;
                color: ${white};
                font-weight: 500;
                overflow: hidden;
                opacity: 0;
                visibility: hidden;
            }

            .search-wrapper-home {
                margin: 40px 0;
                opacity: 0;
                visibility: hidden;
                @media (width: 1280px) and (max-height: 620px) {
                    margin: 20px 0;

                }
            }

            .coutner-wrapper {
                display: flex;
            }

            .single {
                margin-right: 30px;
                opacity: 0;
                visibility: hidden;

                &:last-child {
                    margin-right: 0;
                }
            }

        }
    }

    //slider style end 


    //navigation icon 

    .navigation {
        position: absolute;
        right: ${props => props.offset ? props.offset : '90'}px;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        z-index: 1;

        ul {
            display: flex;
            flex-direction: column;

            li {
                cursor: pointer;

                &:first-child {
                    margin-right: 0;
                }

                &:first-child {
                    margin-bottom: 20px;
                }

                svg {

                }

                &:hover {
                    svg {

                    }
                }
            }
        }
    }


    //responsive
    @media (min-width: 1550px) {
        .slide-inner {
            &__info {
                position: relative;

                h2, h1 {
                    font-size: 80px;
                    line-height: 100px;
                }

                .dc-btn {
                    a {
                        font-size: 22px;
                        line-height: 33px;
                    }
                }
            }
        }
    }
    @media (max-width: 992px) {
        .swipper-navigation-slider-custom {
            bottom: 250px;
        }
    }
    @media (max-width: 992px) and (min-width: 768px) {
        min-height: 100svh;
      
      .col-md-8{
        flex: 0 0 100%;
        max-width: 100%;
      }
        .swipper-navigation-slider-custom {
            bottom: 60px;

            .swiper-initialized {
                padding-right: 30%;
            }
        }

        .slide-inner {
            min-height: 100svh;

            .container {
            }
        }

        .navigation {
            position: absolute;
            right: unset;
            top: unset;
            transform: unset;
            display: inline-block;
            z-index: 1;
            left: ${props => props.offset ? props.offset : '90'}px;
            bottom: 180px;

            ul {
                flex-direction: row;

                li {
                    &:last-child {
                        margin-bottom: 0;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {

        .slide-inner__info .coutner-wrapper {
            flex-flow: wrap;
            gap: 15px;

            .single {
                flex: 0 0 calc(50% - 15px);
                max-width: calc(50% - 15px);
                margin: 0;
            }
        }

        min-height: 125svh;
        .desktop {
            display: none;
        }

        .mobile {
            display: block;
        }

        .slide-inner {
            min-height: 125svh;

            &__info {
                position: relative;
                overflow: hidden;
            }
        }

        .swipper-navigation-slider-custom {
            padding-left: 15px !important;
            padding-right: 15px !important;
            bottom: 30px;

            .swiper-initialized {
                padding-right: 30%;
            }
        }


        .scrollTo {
            left: 15px;
            right: unset;
            display: inline-block;
            bottom: 20px;
            transform: translateX(0);
        }

        .navigation {
            display: none;
        }


        .feature-box-container {

            display: none;
            position: relative;
            bottom: 150px;

            .feature-box-container_item {
                &:first-child {
                    order: 2;
                }

                &:nth-of-type(2) {
                    order: 3;
                }

                &:nth-of-type(3) {
                    order: 4;
                }

                &:last-child {
                    order: 1;

                    .content {
                    }
                }
            }

            .single-item-wrapper {
                border-right: none;
                border-bottom: 1px solid rgba(198, 198, 198, 0.5);

                .content {
                    align-items: flex-start !important;
                }

                &.last {
                    border: none;

                    .content {
                        align-items: center !important;
                    }
                }


            }

            .container {
                padding: 0;
            }
        }
    }



`;

export default React.memo(BannerCarousel);
