import React from 'react';
import styled from 'styled-components';

/**
 * Icon Right component
 * Pass the props with your data to the Icon Left component.
 **/
const BsRightSmall = () => {
  return (
    <StyledBsRightSmall>
      <svg xmlns="http://www.w3.org/2000/svg" width="4.414" height="7.414" viewBox="0 0 4.414 7.414">
        <g id="Arrow" transform="translate(0.707 0.707)">
          <line id="Line_7" data-name="Line 7" x2="3" y2="3" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
          <line id="Line_8" data-name="Line 8" y1="3" x2="3" transform="translate(0 3)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
        </g>
      </svg>

    </StyledBsRightSmall>
  );
};

/**
 * Styled component for Icon Right Single
 * Add your styles for  Icon Right component here.
 **/

const StyledBsRightSmall = styled.div``;

export default React.memo(BsRightSmall);
