import React, { useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import reactHtmlParser from 'react-html-parser';
import ButtonModal from '../nzuac-buttons/ButtonModal';
import {hover, text, white} from '../../styles/globalStyleVars';
import {ImgLazyLoad} from '../nzuac-image/ImgLazyLoad';

const TouchSection = ({data}) => {

  let [offset, setOffset] = useState(90);

  useEffect(() => {
    if (window.innerWidth > 767) {
      setOffset(document.querySelector('.container').offsetLeft + 15);
    }
  }, [data]);



  return (
    <StyledTouch className={'touch-section'}>

      <Container fluid>
        <Row>
          <Col md={6} className={'touch-section-left'} >
            <div className="image-wrapper" >
              <ImgLazyLoad offset={10} src={data?.posts?.list?.[0]?.images?.[0]?.full_path} alt={reactHtmlParser(data?.posts?.list?.[0]?.data?.title)}/>
              <div className="content" style={{paddingLeft: offset+'px'}}>
                <h4 className={'split-up'}>{reactHtmlParser(data?.posts?.list?.[0]?.data?.title)}</h4>

                <div className="button" >
                  <ButtonModal margin={'40px 0 0'} width={'205'}  height={50} text={'Get in Touch Today!'} hoverTextcolor={white} color={text} borderColor={white} background={white} hoverBackground={hover}/>

                </div>

              </div>
            </div>
          </Col>
          <Col md={6} className={'touch-section-right'}>
            <div className="item-both">
              <div className="item-single-one reverse ">
                <div className="image-wrapper ">

                  <ImgLazyLoad offset={10} src={data?.posts?.list?.[1]?.images?.[0]?.full_path} alt={reactHtmlParser(data?.posts?.list?.[1]?.data?.title)}/>
                </div>

                {/* eslint-disable-next-line no-undef */}
                <div className="content-wrapper">
                  <ImgLazyLoad offset={10} src={'./images/static/abstruct-bg.jpg'} alt={reactHtmlParser(data?.posts?.list?.[1]?.data?.title)}/>
                  <h4 className={'split-up'}>{reactHtmlParser(data?.posts?.list?.[1]?.data?.title)}</h4>
                </div>
              </div>
              <div className="item-single-one">
                <img className={'fixed-image'} src="/images/static/vector-cta.svg" alt=""/>
                <div className="content-wrapper">
                  <h4 className={''}>{reactHtmlParser(data?.posts?.list?.[2]?.data?.title)}</h4>
                </div>
                <div className="image-wrapper">
                  <ImgLazyLoad offset={10} src={data?.posts?.list?.[2]?.images?.[0]?.full_path} alt={reactHtmlParser(data?.posts?.list?.[2]?.data?.title)}/>
                </div>

              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledTouch>
  );
};

const StyledTouch = styled.section`

  .touch-section-left {
    padding-left: 0;
    padding-right: 0;

    .image-wrapper {
      position: relative;
      padding-top: calc(680 / 683 * 100%);
      height: 100%;

      .content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 60px;
        padding-right: 70px;

        h4 {
          color: ${white};
          font-weight: 600;
          line-height: 32px;
          font-size: 28px;
        }
      }
    }
  }

  .touch-section-right {
    padding-left: 0;
    padding-right: 0;
    position: relative;

    .item-both {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .item-single-one {
        height: 50%;
        display: flex;
        position: relative;
        overflow: hidden;
        .fixed-image{
          position: absolute;
          inset:0;
        }

        &.reverse {
          .content-wrapper {
            h4 {
              color: ${white};
              position: relative;
              z-index: 6;
            }
          }
        }

        .content-wrapper {
          max-width: 50%;
          flex: 0 0 50%;
          padding: 60px;
          display: flex;
          align-items: flex-end;
          position: relative;

          h4 {
            color: ${hover};
            font-weight: 600;
            line-height: 32px;
            font-size: 28px;
          }
        }

        .image-wrapper {
          max-width: 50%;
          flex: 0 0 50%;
          position: relative;
          //padding-top: calc(340 / 340 * 100%);
        }
      }

    }
  }

  @media (max-width: 992px) {
    .touch-section-left {
      flex: 0 0 100%;
      max-width: 100%;

      .content {
        padding-left: 70px !important;

      }
    }

    .touch-section-right {
      flex: 0 0 100%;
      max-width: 100%;

    }
  }
  @media (max-width: 767px) {
    .touch-section-left {
      flex: 0 0 100%;
      max-width: 100%;
        .image-wrapper{
          padding-top: calc(116.5608%);
        }
      .content {
        padding-right: 15px !important;
        padding-left: 15px !important;

      }
    }

    .touch-section-right {
      flex: 0 0 100%;
      max-width: 100%;

      .item-both {
        display: unset;
        .item-single-one {
          flex-direction: column;
          height: auto;
          &:last-child{
            flex-direction: column-reverse;
          }

          .image-wrapper {
            max-width: 100%;
            flex: 0 0 100%;
            padding-top: calc(375 / 375 * 100%);
          }

          .content-wrapper {
            max-width: 100%;
            flex: 0 0 100%;
            padding: 60px 15px;
            min-height: 375px;
            h4 {
              //margin-top: 100px;
              width: 70%;
            }
          }
        }
      }

    }
  }

`;
export default React.memo(TouchSection);