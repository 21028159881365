import React from 'react';
import styled from 'styled-components';
import {hover} from '../../styles/globalStyleVars';

/**
 * Icon Left component
 * Pass the props with your data to the Icon Left component.
 **/
const FooterCopyright = () => {
  const d = new Date();

  let year = d.getFullYear();


  return (
    <StyledFooterCopyright>
      <p>© {year} NZUAC. All Rights Reserved.</p>
      <p>Designed and Developed by <a href="http://dcastalia.com" target={'_blank'} rel="noreferrer">Dcastalia</a></p>
    </StyledFooterCopyright>
  );
};

/**
 * Styled component for Icon Left Single
 * Add your styles for  Icon Left component here.
 **/
const StyledFooterCopyright = styled.div`

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.5);
    margin: 0;

    a {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.5);

      &:hover {
        color: ${hover};
      }
    }
  }

`;

export default React.memo(FooterCopyright);
