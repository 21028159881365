import React from 'react';
import styled from 'styled-components';

/**
 * Icon Left component
 * Pass the props with your data to the Icon Left component.
 **/
const BsDown = () => {
  return (
    <StyledBsDown>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Group_24287" data-name="Group 24287" transform="translate(-2 -2)">
          <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(2 2)" fill="none" stroke="#fff" strokeWidth="1">
            <circle cx="10" cy="10" r="10" stroke="none"/>
            <circle cx="10" cy="10" r="9.5" fill="none"/>
          </g>
          <g id="Group_15708" data-name="Group 15708" transform="translate(10.5 9)">
            <line id="Line_7" data-name="Line 7" x2="3" y2="3" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
            <line id="Line_8" data-name="Line 8" y1="3" x2="3" transform="translate(0 3)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
          </g>
        </g>
      </svg>

    </StyledBsDown>
  );
};

/**
 * Styled component for Icon Left Single
 * Add your styles for  Icon Left component here.
 **/
const StyledBsDown = styled.div``;

export default React.memo(BsDown);
