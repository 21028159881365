import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Error from '../pages/404';
import About from '../pages/about';
import Home from '../pages/home';
import News from '../pages/news';
import NewsSingle from '../pages/news/single';
import Contacts from '../pages/contacts';
import ComponentsALl from '../pages/components';
import Search from '../pages/search';
import University from '../pages/university';
import English from '../pages/english';
import Student from '../pages/student';
import Parterns from '../pages/partners';
import Scholarship from '../pages/scholarships';
import UniversityDetails from '../pages/university/single';
import BecomePartner from '../pages/becomepartners';
import ScholarshipDetails from '../pages/scholarships/single';
import CourseDetails from '../pages/course/single';
import NewsSearch from '../pages/newsearch';

const RouterMain = ({filter}) => {



  return (

    <Routes>
      <Route
        path="/"
        element={

          <Home filter={filter}/>
        }
      /><Route
        path="/components"
        element={

          <ComponentsALl/>

        }
      />
      <Route exact
        path="/university"
        element={

          <University/>

        }
      />

      <Route exact
        path="/university/:slug"
        element={
          <UniversityDetails/>

        }
      />
      <Route exact
        path="/english-proficiency-test"
        element={
          <English/>

        }
      />
      <Route exact
        path="/partner"
        element={
          <BecomePartner/>

        }
      />
      <Route exact
        path="/student-life"
        element={
          <Student/>

        }
      />
      <Route exact
        path="/our-partners"
        element={

          <Parterns/>

        }
      />
      <Route exact
        path="/about"
        element={
          <About/>

        }
      />


      <Route exact
        path="/scholarship"
        element={

          <Scholarship/>

        }
      />

      <Route exact
        path="/scholarship/:slug"
        element={
          <ScholarshipDetails/>

        }
      />

      <Route exact
        path="/news"
        element={
          <News/>

        }
      />

      <Route exact
        path="/news/:slug"
        element={
          <NewsSingle/>

        }
      />

      <Route exact
        path="/course/:slug"
        element={
          <CourseDetails/>

        }
      />

      <Route exact
        path="/contacts"
        element={
          <Contacts/>

        }
      />
      <Route
        path="/search"
        element={
          <Search/>
        }
      />

      <Route
        path="/news-results"
        element={
          <NewsSearch/>
        }
      />

      {/* Other routes */}
      <Route path="*" element={<Error/>}/>
    </Routes>

  );
};

export default RouterMain;