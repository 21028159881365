import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import gsap, {CSSPlugin} from 'gsap';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {fetchPostsChild, fetchPosts} from '../../api/redux/scholarship';
import {useDispatch, useSelector} from 'react-redux';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {ApiParam} from '../../api/network/apiParams';
import InnerBanner from '../../components/nzuac-section/InnerBanner';
import CTA from '../../components/nzuac-section/CTA';
import OverviewFour from '../../components/nzuac-section/OverviewFour';
import CsrOverView from '../../components/nzuac-section/CsrOverView';
import {useLocation} from 'react-router-dom';
import Oppurtunities from '../../components/nzuac-section/Oppurtunities';
import {motion} from 'framer-motion';
import {PageAnimation} from '../../components/nzuic-animations/PageAnimation';


gsap.registerPlugin(ScrollTrigger, CSSPlugin);

const Scholarships = () => {


  const dispath = useDispatch();
  const location = useLocation();


  // api call





  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;
    let api_url_child = apiEndPoints.SCHOLERSHIPLIST;
    let param = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'scholarships',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };


    dispath(fetchPosts([api_url, param]));
    dispath(fetchPostsChild([api_url_child]));

  }, [dispath, location?.pathname]);







  let getPost = useSelector(store => store.scholarship);

  let page_data = getPost?.posts?.data?.page_data;
  let sections_list = getPost?.posts?.data?.sections;
  let child_data = getPost?.child?.data;



  useEffect(() => {
    // Use GSAP to animate opacity from 0 to 1
    gsap.to('.hide', {alpha: 1, visibility: 'visible', display: 'block', duration: 0.5, delay: 1});
    gsap.to('.pagetransition-loader', {alpha: 0, display: 'none', height: '0', duration: 0.5, delay: 1});
    gsap.to('.desktop-menu__bottom__parent-ul > li ul', {alpha: 0, duration: 0.5, delay: 1});

    // if()

    const body = document.querySelector('body');
    const html = document.querySelector('html');
    const applyNow = document.querySelector('.applyNow');

    if (html.classList.contains('mobile-menu-open')) {
      html.classList.remove('mobile-menu-open');
    }
    if (body.classList.contains('scroll-down')) {
      body.classList.remove('scroll-down');
    }

    if (body.classList.contains('scroll-up')) {
      body.classList.remove('scroll-up');
    }

    if (body.classList.contains('menu-is-open')) {
      body.classList.remove('menu-is-open');
    }

    if (html.classList.contains('menu-is-open')) {
      html.classList.remove('menu-is-open');
    }

    if (body.classList.contains('mobile-menu-open')) {
      body.classList.remove('mobile-menu-open');
    }

    if ((body.classList.contains('stop-scroll')) && window.innerWidth < 992) {
      body.classList.remove('stop-scroll');
    }

    if ((applyNow) && window.innerWidth < 992) {
      applyNow.classList.remove('mobile-menu-open');
    }

    var menuOpen = document.querySelectorAll('.Mobile-menu-wrap');
    var hamburger = document.querySelectorAll('.hamburger');
    var menu = document.querySelectorAll('.main_child');
    var menuSub = document.querySelectorAll('.main-child-sub ');
    var menuSubSub = document.querySelectorAll('.main-child-sub-sub');
    hamburger.forEach((i) => {
      i.classList.remove('menu-open');
      gsap.to(document.querySelector('.mobile-menu__items'), {
        opacity: 0,
        duration: '.2'
      });
      gsap.to(document.querySelector('.mobile-menu__items'), {
        display: 'none'
      });
    });
    menuOpen.forEach((i) => {
      i.classList.remove('menu-open');
    });

    menu.forEach((i) => {
      i.classList.remove('submenu-open-mb');
    });
    menuSub.forEach((i) => {
      i.classList.remove('submenu-open-next');
    });
    menuSubSub.forEach((i) => {
      i.classList.remove('submenu-open-next-next');
      i.classList.remove('submenu-open-next');
      i.classList.remove('submenu-open-mb');
    });


    const contact = document.querySelector('.submenu ');



    if((contact)  && window.innerWidth < 992 ){
      if(contact.classList.contains('sub-menu-open')){
        contact.classList.remove('sub-menu-open');

      }
    }
    // You can customize the animation duration and other properties as needed
  }, [location.pathname]); // Run this effect only once when the component mounts


  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`${page_data?.title ? page_data?.title + ' - New Zealand University Application Centre' : 'New Zealand University Application Centre - Provides institutional brokerage services'}`}</title>
        {
          page_data?.meta_title &&
              <meta name="title" content={page_data?.meta_title ? page_data?.meta_title : 'New Zealand University Application Centre'}/>

        }

        {
          page_data?.meta_description &&
              <meta name="description" content={page_data?.meta_description}/>

        }
        {
          page_data?.og_title &&
              <meta property="og:title" content={page_data?.og_title}/>

        }
        {
          page_data?.og_description &&
              <meta property="og:description" content={page_data?.og_description}/>

        }
      </Helmet>
      <motion.div key={'190'} className="page-loader pagetransition-loader" exit="exit" animate="anim"
        variants={PageAnimation}
        initial="init">
      </motion.div>
      <StyledComponent>

        {
          sections_list?.length > 0 && sections_list.map((item, index) => {
            switch (item?.section_data?.template) {
            case 'innerbanner':
              return (
                <React.Fragment key={index}>
                  <InnerBanner noPara data={item} key={index}/>
                </React.Fragment>
              );
            case 'overview_scholarship':
              return <OverviewFour data={item} key={index}/>;
            case 'gallery':
              return <CsrOverView data={item} key={index} hoverBox winWidth/>;
            case 'scholarship_oppurtunities':
              return <Oppurtunities data={item} list={child_data} key={index} hoverBox winWidth/>;
            case 'cta_section':
              return <CTA data={item} key={index}/>;
            default:
              return null; // If the template doesn't match any case, render nothing
            }
          })
        }

      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`
  background: #F1F0EE;
  
  .scholarship-section{
    padding-top: 100px;
  }
  
  @media(max-width: 767px){
    .about-partex{
      padding-top: 0 !important;
    }
  }
  
`;

export default Scholarships;
