import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {Img} from '../nzuac-image/Img';
import 'react-modal-video/css/modal-video.min.css';
import {LightgalleryItem, LightgalleryProvider} from 'react-lightgallery';
import 'lightgallery.js/dist/css/lightgallery.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Mousewheel, Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {text, white} from '../../styles/globalStyleVars';

const Overview = ({data}) => {




  const [offset, setOffset] = useState(0);
  useEffect(() => {
    setOffset(document.querySelector('.container').offsetLeft + 15);
    window.addEventListener('resize', () => {
      setOffset(document.querySelector('.container').offsetLeft + 15);
    });
  }, []);

  return (
    <StyledComponent offset={offset} className='about-partex parallax-img pb-160'>
      <Container>

        <Row>
          <Col md={{span: 6}} className={'custom-width'}>
            <h2 className={'split-up'}>We have helped dreams become a reality</h2>
          </Col>
          <Col md={{span: 6}}>
            <div className="navigation">
              <div className="blog-button">
                <div className="slider-nav">
                  <ul>
                    <li id={'gallery_prev'}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414" viewBox="0 0 11.207 11.414">
                        <g id="Arrow" transform="translate(0.707 0.707)">
                          <g id="Group_23756" data-name="Group 23756">
                            <line id="Line_3910" data-name="Line 3910" x1="5" y2="5" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                            <line id="Line_3911" data-name="Line 3911" x2="5" y2="5" transform="translate(0 5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                          </g>
                          <line id="Line_3912" data-name="Line 3912" x1="10" transform="translate(0 5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                        </g>
                      </svg>


                    </li>
                    <li id={'gallery_next'}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414" viewBox="0 0 11.207 11.414">
                        <g id="Arrow" transform="translate(-648 -1595.793)">
                          <g id="Group_4823" data-name="Group 4823" transform="translate(653.5 1596.5)">
                            <line id="Line_9" data-name="Line 9" x2="5" y2="5" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                            <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(0 5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                          </g>
                          <line id="Line_11" data-name="Line 11" x2="10" transform="translate(648.5 1601.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                        </g>
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>

      </Container>


      <LightgalleryProvider>

        <Container fluid className={'p-0'}>
          <Row>
            <Col className={'slider_overlap'}>
              <Swiper
                loop={true}
                spaceBetween={15}
                speed='1000'
                slidesPerView={4}
                keyboardControl={true}
                initialSlide={1}
                modules={[Pagination, Navigation, Mousewheel]}
                navigation={{
                  prevEl: '#gallery_prev',
                  nextEl: '#gallery_next'
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 15,

                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 15,

                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                className="mySwiper"
              >

                {
                  data?.images?.list && data?.images?.list?.length > 0 &&
                    data?.images?.list?.map((item, index) => {
                      return (
                        <SwiperSlide key={index} className={''}>
                          <div className="single single_testimonial">
                            <LightgalleryItem>
                              <div className="wrapper_box ">
                                <div className="image_wrapper">
                                  <Img src={item?.full_path}/>
                                </div>
                              </div>
                            </LightgalleryItem>
                          </div>

                        </SwiperSlide>
                      );
                    })
                }


              </Swiper>
            </Col>
          </Row>
        </Container>
      </LightgalleryProvider>

    </StyledComponent>
  );
};

const StyledComponent = styled.section`
    background: #F1F0EE;
    position: relative;

  .blog-button .slider-nav li{
    background: #FFFFFF;
    line{
      stroke: ${text};
      transition: 0.7s all ease;
    }
    &:hover{
      line{
        stroke: ${white};
      }
    }
  }
  .swiper-wrapper{
    //min-height: 450px;
  }
  

    &:after {
        position: absolute;
        height: 50svh;
        background: ${text};
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        content: '';
        z-index: 2;
      
      @media(min-width: 1800px){
        height: 38svh;
      }
    }

    .container, .container-fluid {
        position: relative;
        z-index: 4;
    }

    @media (max-width: 767px) {
        padding-top: 100%;
        padding-bottom: 0px;
    }


    .row {
        position: relative;
    }

    .about-partex__text {
        position: relative;
    }

    .slider_overlap {
        margin-top: 60px;
    }

    .bg-text-one {
        font-size: 240px;
        line-height: 240px;
        font-weight: 500;
        opacity: 60%;
        margin: 0;
        color: #fff;
        position: absolute;
        white-space: nowrap;


        @media (max-width: 767px) {
            font-size: 240px;
            line-height: 240px;
            position: absolute;
            top: -90%;
            padding-left: 15px;
        }
    }


    .bg-text-one {
        @media (max-width: 767px) {
            bottom: 120px;
        }
    }

    .bg-text-two {
        left: -10%;
        bottom: 0;
    }

    .upper-title {
        width: 50%;
        margin: 0 0 20px 0;
        font-weight: 500;;
    }

    h2 {
        color: ${white};
    }

    h3 {
        margin: 0 0 80px 0;

        span {
            color: #E50019;
        }
    }

    p {
        margin: 0 0 80px 0;
    }

    .upper-pera {
        font-weight: 500;
        margin: 0 0 20px 0;
    }

    .lower-para {
        margin: 0;
    }

    @media (max-width: 767px) {
        .upper-title {
            width: 100%;
        }

        h3, p, .sub-col {
            margin: 0 0 40px 0;
        }
    }

    //Gallery

    .swiper {
        padding-left: ${p => p.offset}px;
    }

    .swiper-button-next, .swiper-button-prev {
        display: none;
    }

    .gallery-svg__section {
        padding: 80px 0;

        &__arrow {
            ul {
                display: flex;

                li {
                    margin-right: 20px;
                }

                svg {
                    border-radius: 50px;

                    #Ellipse_4378 {
                        transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                        r: 26;
                        cursor: pointer;
                    }

                    line {
                        stroke: white;
                    }

                    &:hover {
                        #Ellipse_4378 {
                            fill: #E50019;
                            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                            cx: 26;
                        }

                        g {
                            line {
                                stroke: #f9f9f9;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 767px) {
            padding: 40px 0;
        }
    }


    .swiper-slide {
        transition: 0.7s all ease;
    }

    .swiper-pagination {
        width: auto;
        bottom: 120px;
        left: 155px;
        @media (min-width: 1550px) {
            //bottom: 60px;
        }
        @media (max-width: 767px) {
            left: 5px;
            bottom: 85px;
        }

        .swiper-pagination-bullet {
            width: 8px;
            height: 3px;
            display: inline-block;
            background: #C49F8A;
            border-radius: 4px;
            opacity: 1;
            margin: 0 10px;

            &.swiper-pagination-bullet-active {
                width: 70px !important;
                background: #565440;
            }
        }
    }

    .swiper-slide {
        @media (max-width: 767px) {
            //width: inherit !important;

        }
    }

    .swiper-slide-active {
        width: 50% !important;
    
        @media (min-width: 1550px) {
            width: 44.6666666% !important;
        }
        @media (max-width: 767px) {
            width: unset !important;

        }

        .single_testimonial {

            .image_wrapper {
                padding-top: calc(380 / 570 * 100%) !important;

            }
        }
    }

    .single_testimonial {
        box-sizing: border-box;

        @media (max-width: 767px) {
            padding-top: 0;
            padding-right: 0;
            box-sizing: border-box;
            padding-left: 0;
        }

        .wrapper_box {
            display: flex;
            position: relative;

            @media (max-width: 767px) {
                flex-direction: column;
                .image_wrapper {
                    margin-bottom: 30px;
                }
            }


            .image_wrapper {
                padding-top: calc(312 / 370 * 100%);
                transition: 0.7s all ease;
                position: relative;
                cursor: pointer;
                overflow: hidden;

                width: 100%;

                .play {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    top: 50%;

                    svg {
                        #Ellipse_396 {
                            transition: 0.7s all ease;
                        }
                    }
                }

                .global-image {
                    transition: 0.7s all ease;
                    transform: scale(1.01);
                }

                &:hover {
                    .global-image {
                        transform: scale(1.06);
                    }

                    svg {
                        #Ellipse_396 {
                            r: 25;
                        }
                    }
                }
            }


        }
    }

    @media (max-width: 767px) {
        .container-fluid {
            padding: 0 15px 0 0 !important;
        }

        .title {
            margin-bottom: 30px !important;
        }
    }

`;

export default Overview;
