import React from 'react';
import styled from 'styled-components';

/**
 * Close Icon For Mobile Devices component
 * Pass the props with your data to the Close Icon For Mobile Devices component.
 **/
const CloseIconMobile = () => {
  return (
    <StyledCloseIconMobile>
      <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
        <circle id="Ellipse_448" data-name="Ellipse 448" cx="22.5" cy="22.5" r="22.5" fill="#fff"/>
        <g id="Group_24249" data-name="Group 24249" transform="translate(-319.5 -17.5)">
          <line id="Line_3914" data-name="Line 3914" x2="15" transform="translate(336.696 45.303) rotate(-45)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="1"/>
          <line id="Line_3915" data-name="Line 3915" x2="15" transform="translate(336.696 34.697) rotate(45)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="1"/>
          <line id="Line_3916" data-name="Line 3916" x2="15" transform="translate(336.696 34.697) rotate(45)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="1"/>
        </g>
      </svg>

    </StyledCloseIconMobile>
  );
};

/**
 * Styled component for Close Icon For Mobile Devices
 * Add your styles for Close Icon For Mobile Devices component here.
 **/
const StyledCloseIconMobile = styled.div``;

export default React.memo(CloseIconMobile);
