import React from 'react';
import styled from 'styled-components';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import gsap, {CSSPlugin} from 'gsap';
import {Col, Container, Row} from 'react-bootstrap';
import SearchButton from '../components/nzuac-buttons/SearchButton';
import {coffe, hover, offwhite, text, white} from '../styles/globalStyleVars';
import ButtonIcon from '../components/nzuac-buttons/ButtonIcon';
import SelectField from '../components/nzuac-form/SelectField';
import LandingFilterForm from '../components/nzuac-form/LandingFilterForm';
import CounterSingle from '../components/nzuac-single-item/CounterSingle';
import NavigationIcon from '../components/nzuac-form/NavigationIcon';
import Social from '../components/nzuac-social-icons/Social';
import CTASmall from '../components/nzuac-single-item/CTASmall';
import NewsBox from '../components/nzuac-single-item/NewsBox';

gsap.registerPlugin(CSSPlugin);

const MyComponent = () => {


  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Title</title>
        <meta name="description" content="Description"/>
      </Helmet>

      <StyledComponent className={'pt-160 pb-160'}>
        <Container>
          <Row>
            <br/><br/><br/><br/><br/><br/>
            <div className="typography col-md-12">
              <p>Typography <br/><br/></p>
              <h1>H1</h1><br/>
              <h2>H2</h2><br/>
              <h3>H3</h3><br/>
              <ul>
                <li>
                  <h4>H4</h4>
                  <h4 className={'mid'}>H4</h4>
                </li>
              </ul>
              <br/>
              <ul>
                <li>
                  <h5>H5</h5>
                  <h5 className={'mid'}>H5</h5>
                </li>
              </ul>
              <br/>
              <ul>
                <li>
                  <h6>H6</h6>
                  <h6 className={'mid'}>H6</h6>
                </li>
              </ul>
              <br/>
              <p className="meta-data">
                                Meta Data
              </p>
              <p className="menu-data">
                                Menu
              </p>
              <p className="buttons-data">
                                Buttons
              </p>
              <p>P (BODY TEXT)</p>
              <p className="footer-data">
                                Footer
              </p>
              <p className="versity-name">
                                University Names
              </p>
            </div>

            <div className="buttons mt-5 col-md-12">
              <p>All Kind of Buttons , Selectors, Form Input </p>

              <div className={'search-button-click pt-2 pb-2 pl-2'} style={{'background': '#040504'}}>
                <SearchButton borderColor={white} icon={true} text={'Search any course'}/>
                <br/>
                <ButtonIcon src={'/sign-up'} iconSVG text={'Apply Now'} hoverTextcolor={text} borderColor={coffe} background={coffe}/>
                <br/><br/>
                <ButtonIcon width={'100'} src={'/sign-up'} text={'Contacts'} hoverTextcolor={white} borderColor={white} background={'transparent'} hoverBackground={hover}/>
                <br/><br/>
                <Col md={3}>
                  <SelectField border={offwhite}/> <br/><br/>
                  <p style={{color: 'white'}}>Disable</p>
                  <SelectField disabled={true} border={offwhite}/>
                </Col>
                <br/><br/><br/><br/><br/>


                <p style={{color: 'white'}}>Home Banner Search</p>

                <LandingFilterForm/>

                <br/><br/><br/><br/><br/>
                <p style={{color: 'white'}}>Counter</p>
                <CounterSingle value={100}/>

                <br/><br/><br/><br/><br/>
                <p style={{color: 'white'}}>All Navigation Button </p>

                <NavigationIcon/>

                <br/><br/><br/><br/><br/>
                <p style={{color: 'white'}}>Social Icon </p>
                <Social/>

                <br/><br/><br/><br/><br/>
                <p style={{color: 'white'}}>CTA Box </p>
                <Col md={4}>
                  <CTASmall/>
                </Col>

                <br/><br/><br/><br/><br/>
                <p style={{color: 'white'}}>News Item Single </p>

                <Col md={4}>
                  <NewsBox/>
                </Col>

              </div>


              <p>subtitle</p>
            </div>


          </Row>
        </Container>
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`
    .bg-black {
        width: 100%;
    }
  min-height: 400vh;
`;

export default MyComponent;
