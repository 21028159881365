import React, {useEffect, useState} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import styled from 'styled-components';
import {hover} from '../../styles/globalStyleVars';

// Initialize ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

const CallButton = ({targetSection}) => {
  const [offset, setOffset] = useState(90);

  useEffect(() => {
    const updateOffset = () => {
      if (window.innerWidth > 767) {
        const containerElement = document.querySelector('.container');
        if (containerElement) {
          setOffset(containerElement.offsetLeft + 15);
        }
      }
    };

    updateOffset();

    // Update the offset when the window is resized
    window.addEventListener('resize', updateOffset);

    return () => {
      window.removeEventListener('resize', updateOffset);
    };
  }, []);


  return (
    <StyledCallButton>
      <div className="call-button-two"
        style={{
          cursor: 'pointer',
          position: 'fixed',
          bottom: '100px',
          zIndex: 9999,
          right: `${offset - 50}px`,

        }}

      >
        <a href={'/'}>


          <svg xmlns="http://www.w3.org/2000/svg" className={'svg-normal'} width="50" height="50" viewBox="0 0 50 50">
            <g id="Group_24292" data-name="Group 24292" transform="translate(16366 5175)">
              <g id="Hover" transform="translate(-17658 -5845)">
                <circle id="Base" cx="25" cy="25" r="25" transform="translate(1292 670)" fill={hover}/>
                <g id="Icon" transform="translate(18042.805 6553.304)">
                  <path id="Path_9205" data-name="Path 9205" d="M117.648,13.637A6.855,6.855,0,0,0,124.5,6.818a6.856,6.856,0,0,0-13.711,0A6.855,6.855,0,0,0,117.648,13.637Z" transform="translate(-16843.453 -5871.435)" fill="#fff"/>
                  <path id="Path_9206" data-name="Path 9206" d="M47.75,252.8a6.345,6.345,0,0,0-.689-1.251,8.527,8.527,0,0,0-5.9-3.692,1.267,1.267,0,0,0-.868.208,7.34,7.34,0,0,1-8.622,0,1.128,1.128,0,0,0-.868-.208,8.465,8.465,0,0,0-5.9,3.692,7.316,7.316,0,0,0-.689,1.251.63.63,0,0,0,.03.566,11.88,11.88,0,0,0,.808,1.191,11.335,11.335,0,0,0,1.377,1.548,17.921,17.921,0,0,0,1.377,1.191,13.712,13.712,0,0,0,16.286,0,13.144,13.144,0,0,0,1.377-1.191,13.774,13.774,0,0,0,1.377-1.548,10.44,10.44,0,0,0,.808-1.191A.506.506,0,0,0,47.75,252.8Z" transform="translate(-16761.787 -6105.15)" fill="#fff"/>
                </g>
              </g>
            </g>
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" className={'svg-hover'} width="50" height="50" viewBox="0 0 50 50">
            <g id="Group_24292" data-name="Group 24292" transform="translate(16366 5175)">
              <g id="Hover" transform="translate(-17658 -5845)">
                <circle id="Base" cx="25" cy="25" r="25" transform="translate(1292 670)" fill="#fff"/>
                <g id="Icon" transform="translate(18042.805 6553.304)">
                  <path id="Path_9205" data-name="Path 9205" d="M117.648,13.637A6.855,6.855,0,0,0,124.5,6.818a6.856,6.856,0,0,0-13.711,0A6.855,6.855,0,0,0,117.648,13.637Z" transform="translate(-16843.453 -5871.435)" fill="#a08750"/>
                  <path id="Path_9206" data-name="Path 9206" d="M47.75,252.8a6.345,6.345,0,0,0-.689-1.251,8.527,8.527,0,0,0-5.9-3.692,1.267,1.267,0,0,0-.868.208,7.34,7.34,0,0,1-8.622,0,1.128,1.128,0,0,0-.868-.208,8.465,8.465,0,0,0-5.9,3.692,7.316,7.316,0,0,0-.689,1.251.63.63,0,0,0,.03.566,11.88,11.88,0,0,0,.808,1.191,11.335,11.335,0,0,0,1.377,1.548,17.921,17.921,0,0,0,1.377,1.191,13.712,13.712,0,0,0,16.286,0,13.144,13.144,0,0,0,1.377-1.191,13.774,13.774,0,0,0,1.377-1.548,10.44,10.44,0,0,0,.808-1.191A.506.506,0,0,0,47.75,252.8Z" transform="translate(-16761.787 -6105.15)" fill="#a08750"/>
                </g>
              </g>
            </g>
          </svg>

        </a>
      </div>
    </StyledCallButton>
  );
};
const StyledCallButton = styled.div`

  .call-button-two {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: relative;
    background: ${hover};
    box-shadow: 0 5px 10px rgba(0,0,0,0.16);

    @keyframes button-halo-animation {
      0% {
        transform: scale(1);
        opacity: 0;
      }
      50% {
        transform: scale(1);
        opacity: 1;
      }
      to {
        transform: scale(1.5);
        opacity: 0;
      }
    }

    &:before {
      position: absolute;
      content: '';
      height: 50px;
      width: 50px;
      box-shadow: 0 0 0 1px ${hover};
      left: 0;
      border-radius: 40px;
      transition: all .5s cubic-bezier(.54,.32,.05,.96);
      animation-delay: -2.25s;
      z-index: -1;
      top: 0;
      animation: button-opacity 4.5s infinite;

    }

    &:after {
      position: absolute;
      content: '';
      height: 50px;
      width: 50px;
      box-shadow: 0 0 0 1px ${hover};
      left: 0;
      top: 0;
      border-radius: 40px;
      transition: all .3s cubic-bezier(.54,.32,.05,.96);
      animation: button-halo-animation 2.5s infinite;
      z-index: -1;
    }


    @media (min-width: 1550px) {
      right: 60px !important;
    }

    @media (max-width: 992px) {
      right: 15px !important;
    }
    a {
      height: 50px;
      width: 50px;
      overflow: hidden;
      border-radius: 50%;
      position: relative;
      display: block;

      svg {
        position: absolute;
        inset: 0;
        height: 50px;
        width: 50px;

        &.svg-normal {
          transform: translateY(0);
          transition: 0.5s all ease;
        }

        &.svg-hover {
          transform: translateY(60px);
          transition: 0.5s all ease;
        }
      }

      &:hover {
        .svg-normal {
          transform: translateY(-60px);

        }

        .svg-hover {
          transform: translateY(0);

        }
      }
    }
  }
`;

export default CallButton;
