import React, {useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import styled from 'styled-components';
import {Autoplay, Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Swiper, SwiperSlide} from 'swiper/react';
import {hover, text, white} from '../../styles/globalStyleVars';
// import NavigationIcon from '../nzuac-form/NavigationIcon';
import Subtitle from '../nziuac-title/Subtitle';
import NewsBox from '../nzuac-single-item/NewsBox';
import ButtonIcon from '../nzuac-buttons/ButtonIcon';
import {useLocation} from 'react-router-dom';

/**
 * Blog List Type 2 component section
 * Pass the props with your data to the Blog List Type 2 component.
 * Check the page folder to see the components used.
 **/

const BlogListTwo = ({padding, bg, data}) => {
  /**
   * variables
   * Change / add your custom variable
   **/
  let sliderRef = useRef();
  let [offset, setOffset] = useState(90);
  let loacation = useLocation();

  /**
   *  set container offset gap value
   *  get this value for container fuild to container width on change window
   **/
  useEffect(() => {
    setOffset(document.querySelector('.container').offsetLeft);
  }, [setOffset]);



  return (
    <StyledBlogListTwo bg={bg} offset={offset} className={`blog-slider ${padding ? padding : 'pt-160 pb-160'}`}>
      <Container>
        <Row>
          <Col sm={12}>
            <Subtitle
              fontSize={16}
              margin={'0 0 40px'}
              fontWeight={'400'}
              text={'News & Events'}
              color={text}
              borderColor={text}
              textTransform={'Normal'}
            />
          </Col>
          <Col sm={12} className="full-title">
            <div className="blog-button">
              <div className="slider-nav">

                <ul>
                  <li id={'slider_prev'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414" viewBox="0 0 11.207 11.414">
                      <g id="Arrow" transform="translate(0.707 0.707)">
                        <g id="Group_23756" data-name="Group 23756">
                          <line id="Line_3910" data-name="Line 3910" x1="5" y2="5" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                          <line id="Line_3911" data-name="Line 3911" x2="5" y2="5" transform="translate(0 5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                        </g>
                        <line id="Line_3912" data-name="Line 3912" x1="10" transform="translate(0 5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                      </g>
                    </svg>


                  </li>
                  <li id={'slider_next'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414" viewBox="0 0 11.207 11.414">
                      <g id="Arrow" transform="translate(-648 -1595.793)">
                        <g id="Group_4823" data-name="Group 4823" transform="translate(653.5 1596.5)">
                          <line id="Line_9" data-name="Line 9" x2="5" y2="5" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                          <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(0 5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                        </g>
                        <line id="Line_11" data-name="Line 11" x2="10" transform="translate(648.5 1601.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                      </g>
                    </svg>
                  </li>
                </ul>
              </div>
            </div>
            <ButtonIcon width={'120'} src={'/news'} text={'View All'} hoverTextcolor={white} height={50} borderColor={white} background={hover} hoverBackground={text}/>

          </Col>
        </Row>
      </Container>

      <div className={'fade-up blog-slider__slider-wrap'}>
        <div className="blog-slider__slider-wrap__inner">

          {
            data &&
              <Swiper
                spaceBetween={30}
                slidesPerView={3}
                allowSlideNext={true}
                allowSlidePrev={true}
                allowTouchMove={true}
                speed={900}
                pagination={false}
                navigation={{
                  prevEl: '#slider_prev',
                  nextEl: '#slider_next'
                }}
                modules={[Autoplay, Pagination, Navigation]}
                breakpoints={{
                  300: {
                    slidesPerView: 1,
                    spaceBetween: 30
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30
                  }
                }}
                ref={sliderRef}
              >
                {data && data?.length > 0 &&
                      data?.map((e, index) => {
                        var date = new Date(e.data?.date);

                        // Extract day, month, and year
                        var day = date.getDate();
                        var month = date.toLocaleString('default', {month: 'long'});
                        var year = date.getFullYear();
                        var fullDate = day+' '+month+' ' + year;

                        return (
                          <SwiperSlide key={index}>
                            <NewsBox cat={e?.data?.category_id === 1 ? 'News' : e?.data?.category_id === 2 ? 'Blog'  : e?.data?.category_id === 3 ? 'Events' : ''} title={e.data?.title} img={e?.images?.list?.[0]?.full_path} date={fullDate} link={loacation?.pathname === '/' ? '/news/'+e?.data?.slug : '/news/'+e?.data?.slug}  />
                          </SwiperSlide>
                        );
                      })}
              </Swiper>
          }

        </div>
      </div>
    </StyledBlogListTwo>
  );
};

/**
 * Styled component for Blog List Type 2 component
 * Add your custom styles to this styled component
 **/
const StyledBlogListTwo = styled.section`
  background: ${(props) => (props.bg ? props.bg : '#F1F0EE')};
  margin-top: -1px;
  position: relative;

  @media(max-width: 767px){
    margin-top: 0;
  }
  
  /** 
  * typography 
  * change the typography subtitle , heading h2 , full with title  
**/
  .sub-title {
    margin: 0 0 20px 0;
    font-weight: 500;
  }

  h2 {
    text-transform: none !important;
  }

  .blog-button{
    .slider-nav{
      ul{
        display: flex;
        gap: 15px;
      }
      li{
        height: 48px;
        width: 48px;
        background: #040504;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        svg{
          position: relative;
          z-index: 3;
        }
        &:after{
          content: '';
          position: absolute;
          inset: 0;
          background: ${hover};
          border-radius: 50%;
          transition: 0.7s all ease;
          z-index: 2;
          transform: translateY(100px);
        }
        &:hover{
          &:after{
            transform: translateY(0);
          }
        }
      }
    }
  }

  .full-title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 80px 0;

    .blog-title {
      padding-left: 0;

      h3 {
        span {
          color: ${text};
        }
      }
    }

  }

  .blog-slider__slider-wrap {
    .swiper-initialized {
      padding-right: ${(props) => (props.offset ? props.offset + 15 + 'px' : '85px')};
    }

    .blog-slider__slider-wrap__inner {
      padding-left: ${(props) => (props.offset ? props.offset + 15 + 'px' : '85px')};
      @media (max-width: 767px) {
        padding-left: 15px !important;
      }

      .blog-single {
        .blog-single__inner {
          padding-top: calc(440 / 370 * 100%);
          position: relative;

          a {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 3;
          }

          &__content {
            &:after {
              content: '';
              height: 0;
              width: 100%;
              background-color: ${hover};
              bottom: 0;
              left: 0;
              right: 0;
              position: absolute;
              transition: height 0.4s ease;
            }

            &__top {
              .upper {
                position: absolute;
                left: 30px;
                top: 40px;
                z-index: 2;
                right: 30px;
                margin: 0;
                transform: translateY(-30px);
                opacity: 0;
                transition: all 0.6s ease;
              }

              p {
                font-size: 20px;
                line-height: 24px;
                font-weight: 300;
                color: ${text};
                margin: 0 0 40px 0;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
              }

              .read-more {
                font-size: 20px;
                line-height: 24px;
                font-weight: 300;
                color: ${text};
                margin: 0;
                text-decoration: underline;
              }

              h2 {
                position: absolute;
                top: 40px;
                left: 30px;
                right: 30px;
                z-index: 2;
                font-size: 28px;
                font-weight: 500;
                line-height: 32px;
                color: ${text};
                margin: 0;
                transition: all 0.3s ease;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
              }
            }

            &__bottom {
              position: absolute;
              margin: 0;
              left: 30px;
              right: 30px;
              bottom: 24px;
              display: flex;
              justify-content: space-between;
              padding-top: 20px;
              z-index: 2;

              h3 {
                color: ${text};
                transition: color 0.3s ease;

                p {
                  display: block;
                  color: ${text};
                }
              }

              p {
                font-size: 16px;
                color: ${text};
                text-align: right;
                line-height: 20px;
                transition: color 0.3s ease;

                span {
                  display: block;
                  color: ${text};
                }
              }
            }
          }

          &:hover {
            .blog-single__inner__content__top {
              h2 {
                opacity: 0;
                transform: translateY(-20px);
              }

              .upper {
                transform: none;
                opacity: 1;
              }
            }

            .blog-single__inner__content__bottom {
              border-color: ${text};

              h4 {
                color: ${text};
              }

              p {
                color: ${text};

                span {
                  color: ${text};
                }
              }
            }
          }
        }

        &:hover {
          .blog-single__inner__content:after {
            height: 100%;
          }
        }
      }

      .blog-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
  
      }
    }

  }

  /** 
     * Responsive 
     * Change the media query or add custom style here  
   **/
  @media (max-width: 992px) {
    .full-title {
      //flex-direction: column;
      margin-top: 40px;
      margin-bottom: 40px;
      align-items: center;
      .blog-button{
        margin-top: 0;
      }
    }

    .subtitle {
      margin-bottom: 0 !important;
    }

    .blog-title {
      .title {
        margin-bottom: 0 !important;
      }
    }


  }

  @media (max-width: 767px) {
    padding-bottom: 80px !important;
    .swiper-initialized {
      padding-right: 15px !important;
    }
  }
`;
export default React.memo(BlogListTwo);
