import React from 'react';
import styled from 'styled-components';
import {hover} from '../../styles/globalStyleVars';

/**
 * Hamburger Icon component
 * Pass the props with your data to the Hamburger Icon.
 **/
const CloseIconSearch = () => {
  return (
    <StyledCloseIconSearch>
      <div className="CloseIconSearchSvg">


        <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
          <g id="Mask_Group_403" data-name="Mask Group 403" transform="translate(14549 5930)">
            <circle id="Mask" cx="25" cy="25" r="25" transform="translate(-14549 -5930)" fill="none" stroke="none" strokeWidth="1"/>
            <g id="Base" transform="translate(-14549 -5930)" fill="none" stroke="none" strokeWidth="1">
              <circle cx="25" cy="25" r="25" stroke="none"/>
              <circle cx="25" cy="25" r="24.5" fill="none"/>
            </g>
            <g id="Cross" transform="translate(2.5 1.5)">
              <line id="Line_3919" data-name="Line 3919" x2="10" y2="10" transform="translate(-14531.5 -5911.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
              <line id="Line_3920" data-name="Line 3920" x1="10" y2="10" transform="translate(-14531.5 -5911.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
            </g>
            <g id="Hover" transform="translate(0 54)">
              <circle id="Base-2" data-name="Base" cx="25" cy="25" r="25" transform="translate(-14549 -5930)" fill="#a08750"/>
              <g id="Cross-2" data-name="Cross" transform="translate(2.5 1.5)">
                <line id="Line_3919-2" data-name="Line 3919" x2="10" y2="10" transform="translate(-14531.5 -5911.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                <line id="Line_3920-2" data-name="Line 3920" x1="10" y2="10" transform="translate(-14531.5 -5911.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
              </g>
            </g>
          </g>
        </svg>


        <svg id="Hover" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">

          <g id="Mask_Group_403" data-name="Mask Group 403" transform="translate(14549 5930)" >
            <circle id="Mask" cx="25" cy="25" r="25" transform="translate(-14549 -5930)" fill="none" stroke="none" strokeWidth="1"/>

            <g id="Base" transform="translate(-14549 -5930)" fill={hover} stroke={hover} strokeWidth="1">
              <circle cx="25" cy="25" r="25" stroke="none"/>
              <circle cx="25" cy="25" r="24.5" fill="none"/>
            </g>
            <g id="Cross" transform="translate(2.5 1.5)">
              <line id="Line_3919" data-name="Line 3919" x2="10" y2="10" transform="translate(-14531.5 -5911.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
              <line id="Line_3920" data-name="Line 3920" x1="10" y2="10" transform="translate(-14531.5 -5911.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
            </g>
            <g id="Hover" transform="translate(0 54)">
              <circle id="Base-2" data-name="Base" cx="25" cy="25" r="25" transform="translate(-14549 -5930)" fill="#a08750"/>
              <g id="Cross-2" data-name="Cross" transform="translate(2.5 1.5)">
                <line id="Line_3919-2" data-name="Line 3919" x2="10" y2="10" transform="translate(-14531.5 -5911.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                <line id="Line_3920-2" data-name="Line 3920" x1="10" y2="10" transform="translate(-14531.5 -5911.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
              </g>
            </g>
          </g>
        </svg>


      </div>


    </StyledCloseIconSearch>
  );
};
/**
 * Styled component for Hamburger Icon
 * Add your styles for Hamburger Icon component here.
 **/
const StyledCloseIconSearch = styled.div`
  .CloseIconSearchSvg {
    position: relative;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid white;
    transition: 0.7s all ease;
    cursor: pointer;

    svg {
      position: absolute;
      left: -1px;
      top: -1px;
      z-index: 999;

      &:first-child {
        z-index: 1;
        transform: translateY(0);
        transition: 0.7s all ease;
        
      }

      &:last-child {
        transform: translateY(50px);
        z-index: 2;
        transition: 0.7s all ease;
      }

    }

    &:hover {
      border-color: ${hover};
      svg {
        &:first-child {
          transform: translateY(-50px);

        }
        &:last-child {
          transform: translateY(0);
        }
      }
    }
  }
`;

export default React.memo(CloseIconSearch);
