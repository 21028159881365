import React, { useRef, useState} from 'react';
import styled from 'styled-components';
import SelectField from './SelectField';
import {offwhite, white} from '../../styles/globalStyleVars';
import SearchButton from '../nzuac-svg/SearchButton';
import {useDispatch, useSelector} from 'react-redux';
import {fetchPostsChildFilter} from '../../api/redux/home';
import {ApiParam} from '../../api/network/apiParams';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {toast} from 'react-toastify';

const LandingFilterForm = (data) => {


  const selectUniversityFieldRef = useRef();
  const selectCategoryFieldRef = useRef();
  const selectCourseFieldRef = useRef();

  const universityArray = data?.data?.university;

  const categoryArray = data?.data?.category;
  const courseArray = data?.data?.course;
  let universityOptions = [];
  let categoryOptions = [];
  let courseOptions = [];


  // Check if universityArray is defined and not empty
  if (universityArray && universityArray.length > 0) {
    universityOptions = universityArray.map(university => ({
      value: university?.product_data?.id,
      label: university?.product_data.title
    }));


  } else {
  }


  // Check if universityArray is defined and not empty
  if (categoryArray && categoryArray.length > 0) {
    categoryOptions = categoryArray.map(university => ({
      value: university?.id,
      label: university?.title
    }));


  } else {
  }


  // Check if universityArray is defined and not empty
  if (courseArray && courseArray.length > 0) {
    courseOptions = courseArray.map(university => ({
      value: university?.id,
      label: university?.title
    }));


  } else {
  }


  const dispath = useDispatch();
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedValueCategory, setSelectedValueCateogry] = useState('');
  const [selectedButtonenable, setSelectedButtonenable] = useState(false);
  const [disableValue, setDisableValue] = useState(true);
  const [disableCourse, setDisableCourse] = useState(true);
  let getPost = useSelector(state => state.home);

  let filter = getPost?.filter?.filter;
  const handleSelectChange = (value) => {
    setSelectedValue(value?.value);


    let api_filter = apiEndPoints.FILTER;
    let param = {
      [ApiParam.PRODUCT]: value?.value,
      [ApiParam.CATEGORY]: '',
      [ApiParam.COURSE]: '',

    };
    dispath(fetchPostsChildFilter([api_filter, param]));

    setSelectedButtonenable(false);
    setDisableValue(false);
    setDisableCourse(true);

    var div2 = document.querySelector('.category .filter__single-value');
    if (div2) {
      div2.textContent = 'Select Field of Study'; // Change the text content
    }
    var div3 = document.querySelector('.course .filter__single-value');
    if (div3) {
      div3.textContent = 'Select Course'; // Change the text content
    }

  };


  const handleSelectCategory = (value) => {
    setSelectedValueCateogry(value?.value);

    let api_filter = apiEndPoints.FILTER;
    let param = {
      [ApiParam.PRODUCT]: selectedValue,
      [ApiParam.CATEGORY]: value?.value ? value?.value : '',
      [ApiParam.COURSE]: '',

    };
    dispath(fetchPostsChildFilter([api_filter, param]));



    var div3 = document.querySelector('.course .filter__single-value');
    if (div3) {
      div3.textContent = 'Select Course'; // Change the text content
    }

    setSelectedButtonenable(false);
    setDisableCourse(false);
  };


  const error = (msg) => toast.error(msg, {
    position: 'top-right', autoClose: 4000, closeOnClick: true, progress: undefined,

  });


  const handleSelectCourse = (value) => {

    let api_filter = apiEndPoints.FILTER;
    let param = {
      [ApiParam.PRODUCT]: selectedValue ? selectedValue : '',
      [ApiParam.CATEGORY]: selectedValueCategory ? selectedValueCategory : '',
      [ApiParam.COURSE]: value?.value ? value?.value : '',

    };
    dispath(fetchPostsChildFilter([api_filter, param]));
    setSelectedButtonenable(true);
    // setDisableValue(true);
    // setDisableCourse(true);
    var menuList = document.querySelector('.university .filter__menu_item');


    if(menuList){
      menuList.classList.remove('active');
      menuList.classList.remove('current');
      menuList.attributes.removeNamedItem('selected');
    }

    clearSelectValue();
    setDisableCourse(false);

  };
  const handleClick = (value) => {


    if(selectedButtonenable === true){

      if (filter?.course?.[0]?.slug) {
        window.location.href = '/course/' + filter?.course?.[0]?.slug;
      }
    }else {
      error('Please Select Correct University and Filed of Study');
    }


    // setDisableCourse(true);
    // setDisableCourse(true);

  };

  const clearSelectValue = () => {
    if (selectUniversityFieldRef.current) {
      selectUniversityFieldRef.current.clearValue();
    }
  };

  return (

    <StyledLandingFilterForm>
      <div className="search-filer-home">
        <div className="select-option-single university">
          <SelectField ref={selectUniversityFieldRef} selection={universityOptions}  onChange={handleSelectChange} placeholder={'Select University'} border={offwhite}/>

        </div>
        <div className="select-option-single category">
          <SelectField ref={selectCategoryFieldRef} selection={categoryOptions} onChange={handleSelectCategory}  placeholder={'Select Field of Study'} disabled={disableValue} border={offwhite}/>
        </div>
        <div className="select-option-single course">
          <SelectField ref={selectCourseFieldRef}  selection={courseOptions} onChange={handleSelectCourse} placeholder={'Select Course'} disabled={disableCourse} border={offwhite}/>
        </div>
        <div className="select-option-button" id={'submit-search'}>
          <SearchButton  enable={selectedButtonenable} onClick={handleClick}/>
        </div>
      </div>
    </StyledLandingFilterForm>

  );
};


const StyledLandingFilterForm = styled.div`

  .search-filer-home {
    display: inline-flex;
    background: ${white};
    padding: 15px;
    border-radius: 50px;
  }

  .select-option-single {
    margin-right: 16px;
  }

  //responsive
  @media (width: 1280px) and (max-height: 620px) {
    .search-filer-home {
      padding: 10px;
      gap: 10px;
    }
  }
  @media (max-width: 992px) {
    .search-filer-home {
      flex-flow: wrap;
      background: transparent;
      flex-wrap: wrap;
      display: flex;
      width: 100%;
      gap: 15px;
      padding: 0;

      .select-option-single {
        margin: 0;

        &:nth-of-type(1) {
          flex: 0 0 100%;
          max-width: 100%;

          .selector-custom-nzuac {
            max-width: 100%;
          }
        }

        &:nth-of-type(2) {
          flex: 0 0 100%;
          max-width: 100%;

          .selector-custom-nzuac {
            max-width: 100%;
          }
        }

        &:nth-of-type(3) {
          flex: 0 0 calc(100% - 65px);
          max-width: calc(100% - 65px);

          .selector-custom-nzuac {
            max-width: 100%;
          }
        }
      }

      .select-option-button {
        flex: 0 0 50px;
        max-width: 50px;
        border-radius: 50%;

      }
    }
  }
`;


export default React.memo(LandingFilterForm);