import React, {useEffect, useLayoutEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import GlobalStyle from './styles/globalStyle';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {ScrollSmoother} from 'gsap/dist/ScrollSmoother';
import {SmootherContext} from './components/nzuac-helpers/SmootherContext';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from 'react-redux';
import {apiEndPoints} from './api/network/apiEndPoints';
import {fetchPostsChildFilter} from './api/redux/home';
import {AnimatePresence} from 'framer-motion';
// import {Loading} from './components/nzuac-preload/Loading';
import RouterMain from './router/RouterMain';
import PageLoaderNew from './components/nzuac-preload/PageLoaderNew';
import MainFooter from './global/MainFooter';
import SignUpButton from './components/nzuac-buttons/SignUpButton';
import MessengerButton from './components/nzuac-buttons/MessengerButton';
import MainMenu from './global/MainMenu';
import {SplitUp} from './components/nzuic-animations/TextAnimation';
import {ImageReveal} from './components/nzuic-animations/imageReveal';
import MobileMenuNew from './global/parts/MobileMenuNew';
import {Loading} from './components/nzuac-preload/Loading';
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
function App() {
  const location = useLocation();
  const main = useRef();
  const smoother = useRef();
  // smooth scroll
  const useIsomorphicLayoutEffect = typeof window !== 'undefined'
    ? useLayoutEffect
    : useEffect;


  // smooth scroll
  useIsomorphicLayoutEffect(() => {
    const isMobile = window?.innerWidth < 992;



    const ctx = gsap.context(() => {

      if (!ScrollTrigger.isTouch) {
        smoother.current = ScrollSmoother.create({
          wrapper: '#smooth-wrapper',
          content: '#smooth-content',
          smooth: isMobile ? 0 : 2,
          effects: isMobile ? false : true,
          normalizeScroll: {
            allowNestedScroll:  isMobile ? false : true,
          },
          ignoreMobileResize: isMobile ? false : true,
          preventDefault: true,
        }, main);



      }
    }, main);

    if (location.hash) {
      setTimeout(() => {
        smoother.scrollTo(location.hash, 3);
      }, 500);
    }



    return () => {
      ctx.revert();
    };
  }, [location.pathname, location.hash]);






  // api call
  const dispatch = useDispatch();

  useEffect(() => {
    let api_filter = apiEndPoints.FILTER;
    dispatch(fetchPostsChildFilter([api_filter]));
  }, [dispatch, location]);

  let getPost = useSelector(state => state.home);
  let filter = getPost?.filter?.filter;

  useEffect(() => {
    const currentUrl = window.location.origin + location.pathname + location.search;
    const link = document.createElement('link');
    link.rel = 'canonical';
    link.href = currentUrl;
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [location.pathname, location.search]);

  useEffect(() => {
    document.body.classList.toggle('in-home-page', location.pathname === '/');
  }, [location.pathname]);
  useEffect(() => {
    // Scroll to top whenever the route changes
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const store = useSelector(s => s);


  useEffect(() => {
    // Use GSAP to animate opacity from 0 to 1
    gsap.to('.hide', {alpha: 1, visibility: 'visible', display: 'block', duration: 0.5, delay: 1});
    gsap.to('.pagetransition-loader', {alpha: 0, display: 'none', height: '0', duration: 0.5, delay: 1});
    gsap.to('.desktop-menu__bottom__parent-ul > li ul', {alpha: 0, duration: 0.5, delay: 1});

    // if()

    const body = document.querySelector('body');
    const applyNow = document.querySelector('.applyNow');

    if (body.classList.contains('scroll-down')) {
      body.classList.remove('scroll-down');
    }

    if (body.classList.contains('scroll-up')) {
      body.classList.remove('scroll-up');
    }

    if (body.classList.contains('menu-is-open')) {
      body.classList.remove('menu-is-open');
    }

    if ((body.classList.contains('stop-scroll')) && window.innerWidth < 992) {
      body.classList.remove('stop-scroll');
    }

    if ((applyNow) && window.innerWidth < 992) {
      applyNow.classList.remove('mobile-menu-open');
    }


    // You can customize the animation duration and other properties as needed
  }, [location.pathname]); // Run this effect only once when the component mounts

  SplitUp(store);
  ImageReveal(store);
  return (
    <>


      {/* Loading components for various slices */}
      {store?.home?.loadingForm && <Loading/>}
      {store?.contact?.loadingForm && <Loading/>}



      <GlobalStyle/>
      <ToastContainer position="top-right" autoClose={4000} closeOnClick hideProgressBar={true}/>
      {window?.innerWidth > 992 ? <MainMenu filter={filter} /> : <MobileMenuNew/>}

      {location?.pathname === '/' ? <PageLoaderNew data={getPost} /> : ''}


      <div className="hide">
        {/*<CallButton/>*/}

        {
          window.innerWidth > 767 &&
            <SignUpButton/>
        }
        <MessengerButton/>
      </div>

      <SmootherContext.Provider value={smoother.current}>
        <div className="App" ref={main} id="smooth-wrapper">

          <div id="smooth-content">
            <AnimatePresence exitBeforeEnter>
              <RouterMain key="router-main" filter={filter}/> {/* Assign a unique key */}
              <MainFooter key="main-footer"/> {/* Assign a unique key */}
            </AnimatePresence>
          </div>
        </div>
      </SmootherContext.Provider>
    </>


  );
}

export default App;
