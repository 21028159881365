import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import {text, title} from '../../styles/globalStyleVars';

/**
 * Subtitle component
 * Pass the props with your data to the Subtitle like Font Size, Font Weight, Color, Letter Spacing, Line Height, Text Transform, Margin, Padding, Border Color, Varient, Text, Noanim.
 **/
const Subtitle = ({
  fontSize,
  fontWeight,
  color,
  letterSpacing,
  lineHeight,
  textTransform,
  margin,
  padding,
  borderColor,
  varient,
  text,
  noanim
}) => {
  return (
    <StyledSubtitle
      className={'subtitle parallux_sub'}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      lineHeight={lineHeight}
      LetterSpacing={letterSpacing}
      textTransform={textTransform}
      margin={margin}
      padding={padding}
      varient={varient}
      borderColor={borderColor}
    >
      {text ? (
        <p className={`subtitle ${noanim ? noanim : 'split-up'} `}>{ReactHtmlParser(text)}</p>
      ) : (
        ''
      )}
    </StyledSubtitle>
  );
};

/**
 * Styled component for Subtitle
 * Add your styles for Subtitle component here.
 **/
const StyledSubtitle = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: ${(props) => props.margin || '0px'};

  .subtitle {
    text-transform: ${(props) => props.textTransform || 'Capitalize'};
    font-weight: ${(props) => props.fontWeight || '400'};
    color: ${(props) => props.color || text};
    font-family: ${title};
    font-size: ${(probs) => probs.fontSize + 'px' || '16px'};
    position: relative;
    width: 100%;
    padding-bottom: 20px;

    span {
      display: block;
    }

    p {
      color: ${(props) => props.color || text};
    }
  }

  &:after {
    position: absolute;
    left: 0;
    width: 100%;
    content: '';
    background: rgba(4,5,4,0.2);
    height: 1px;
    bottom: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 40px;
    display: grid;
    grid-auto-flow: row;
    .subtitle {
      order: 1;
      margin-bottom: 30px;
    }
  }
`;

export default React.memo(Subtitle);
