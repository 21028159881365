import React from 'react';
import styled from 'styled-components';
import {Link, useLocation} from 'react-router-dom';
import {BsChevronDown} from 'react-icons/bs';

/**
 * Icon Left component
 * Pass the props with your data to the Icon Left component.
 **/
const DesktopMenuItem = () => {

  const location = useLocation();
  return (
    <StyledDesktopMenuItem>
      <div className='desktop-menu__bottom'>
        <ul className='desktop-menu__bottom__parent-ul'>
          <li className={location?.pathname === '/' ? 'active' : ''}><Link to={'/'}>Home</Link>
          </li>
          <li className={location?.pathname === '/university' || location?.pathname === '/student-life' || location?.pathname === '/scholarship' || location?.pathname === '/english-proficiency-test' ? 'active' : ''}><Link to={'javascript:void(0)'}>Study in New Zealand <BsChevronDown/></Link>
            <ul>
              <li ><Link to={'/university'}>Universities </Link></li>
              <li ><Link to={'/student-life'}>Student Life </Link></li>
              <li ><Link to={'/scholarship'}>Scholarships </Link></li>
              <li ><Link to={'/english-proficiency-test'}>English Proficiency Test </Link></li>
            </ul>
          </li>
          <li className={location?.pathname === '/about' ? 'active' : ''}><Link to={'/about'}>About NZUAC</Link>
          </li>
          <li className={location?.pathname === '/our-partners' || location?.pathname === '/partner' ? 'active' : ''}><Link to={'javascript:void(0)'}>Partnership <BsChevronDown/>
          </Link>
          <ul>
            <li><Link to={'/our-partners'}>Our Partners </Link></li>
            <li><Link to={'/partner'}>Become a Partner Agent </Link></li>
          </ul>
          </li>
          <li className={location?.pathname === '/news' ? 'active' : ''}><Link to={'/news'}>News & Events</Link>
          </li>
        </ul>
      </div>
    </StyledDesktopMenuItem>
  );
};

/**
 * Styled component for Icon Left Single
 * Add your styles for  Icon Left component here.
 **/
const StyledDesktopMenuItem = styled.div`

`;

export default React.memo(DesktopMenuItem);
