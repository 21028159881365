import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {hover, text} from '../../styles/globalStyleVars';
import reactHtmlParser from 'react-html-parser';

const BigText = ({data}) => {

  return (
    <StyledTouch className={'touch-bigtex'}>
      <Container >
        <Row>
          <Col md={12} className={''} >
            {
              data?.section_data?.description &&
                  reactHtmlParser(data?.section_data?.description)
            }
          </Col>

        </Row>
      </Container>
    </StyledTouch>
  );
};

const StyledTouch = styled.section`
    background: #F1F0EE;
    padding-top: 160px;
    padding-bottom: 160px;

    h2 {
        font-size: 80px;
        font-weight: 600;
        line-height: 80px;

        span {
            color: ${hover};
            font-size: 80px;
            font-weight: 600;
            line-height: 80px;
        }

        small {
            margin-top: 40px;
            display: block;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: ${text};
        }
        
        @media(max-width: 992px){
            font-size: 50px;
            font-weight: 600;
            line-height: 50px;

            span {
                color: ${hover};
                font-size: 50px;
                font-weight: 600;
                line-height: 50px;
            }
        }
    }

`;
export default React.memo(BigText);