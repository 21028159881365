import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {text, white, hover} from '../../styles/globalStyleVars';
import {Link} from 'react-router-dom';
import reactHtmlParser from 'react-html-parser';


const CourseSingle = ({type, title, link, id}) => {


  return (

    <StyledCourseSingle className={'slider-counter-single'}>
      <div className="course-box">
        <Link to={`/course/${link}`}></Link>
        <div className="heading">
          <p>{reactHtmlParser(type)}</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Group_19650" data-name="Group 19650" transform="translate(-2 -2)">
              <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(2 2)" fill="none" stroke="#fff" strokeWidth="1">
                <circle cx="10" cy="10" r="10" stroke="none"/>
                <circle cx="10" cy="10" r="9.5" fill="none"/>
              </g>
              <g id="Group_15708" data-name="Group 15708" transform="translate(10.5 9)">
                <line id="Line_7" data-name="Line 7" x2="3" y2="3" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                <line id="Line_8" data-name="Line 8" y1="3" x2="3" transform="translate(0 3)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
              </g>
            </g>
          </svg>

        </div>
        <div className="footer">
          <p>{reactHtmlParser(title)}</p>
        </div>
      </div>
    </StyledCourseSingle>

  );

};

const StyledCourseSingle = styled.section`
    .course-box {
        padding-top: calc(150 / 270 * 100%);
        position: relative;
        background: ${text};

        &:after {
            position: absolute;
            background: ${hover};
            content: '';
            bottom: 0;
            height: 0;
            width: 100%;
            transition: 0.7s all ease;
        }

        a {
            position: absolute;
            inset: 0;
            z-index: 3;
          
        }

        .heading {
            position: absolute;
            top: 20px;
            left: 0;
            right: 0;
            display: flex;
            justify-content: space-between;
            padding: 0 15px;
            z-index: 2;

            p {
                font-size: 12px;
                font-weight: 500;
                line-height: 24px;
                color: rgba(255, 255, 255, 0.5);
            }

            svg {
                visibility: visible !important;
            }
        }

        .footer {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 20px;
            padding: 0 15px;
            z-index: 2;

            p {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                color: ${white};
            }
        }

        &:hover {
            &:after {
                height: 100%;
            }
        }
    }
`;


export default React.memo(CourseSingle);