import React from 'react';
import styled from 'styled-components';

/**
 * Hamburger Icon component
 * Pass the props with your data to the Hamburger Icon.
 **/
const HamburgerMobile = () => {
  return (
    <StyledHamburgerMobile>
      <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
        <g id="Hamburger" transform="translate(-318 -14)">
          <circle id="Ellipse_448" data-name="Ellipse 448" cx="22.5" cy="22.5" r="22.5" transform="translate(318 14)" fill="#fff"/>
          <g id="Group_23779" data-name="Group 23779" transform="translate(-1 -2.5)">
            <line id="Line_3914" data-name="Line 3914" x2="15" transform="translate(333.5 34.5)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="1"/>
            <line id="Line_3915" data-name="Line 3915" x2="15" transform="translate(333.5 38.5)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="1"/>
            <line id="Line_3916" data-name="Line 3916" x2="15" transform="translate(333.5 42.5)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="1"/>
          </g>
        </g>
      </svg>

    </StyledHamburgerMobile>
  );
};
/**
 * Styled component for Hamburger Icon
 * Add your styles for Hamburger Icon component here.
 **/
const StyledHamburgerMobile = styled.div`

`;

export default React.memo(HamburgerMobile);
