import React from 'react';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import {Col, Container, Row} from 'react-bootstrap';
import {hover, text, white} from '../../styles/globalStyleVars';
import {ImgLazyLoad} from '../nzuac-image/ImgLazyLoad';
import ButtonModal from '../nzuac-buttons/ButtonModal';

const MyComponent = ({data}) => {



  return (
    <StyledComponent className={`ctat ${data?.section_data?.gap === 'key1' ? 'mt-100' : ''}`}>
      <ImgLazyLoad src={data?.images?.list?.[0]?.full_path} alt={data?.section_data?.subtitle}/>
      <Container>
        <Row>
          <Col md={5} className={'pr-0'}>
            <h4 className={'split-up'}>{data?.section_data?.subtitle}</h4>
            <div className="button">
              <ButtonModal margin={'40px 0 0'} width={'205'} height={50} text={'Get in Touch Today!'} hoverTextcolor={white} color={text} borderColor={white} background={white} hoverBackground={hover}/>

            </div>
          </Col>
        </Row>
      </Container>
    </StyledComponent>
  );
};
const StyledComponent = styled.section`
    position: relative;
    padding: 150px 0;

    h4 {
        font-weight: 600;
        color: ${white};
    }

    @media (max-width: 992px) {
        .pr-0{
            padding-right: 15px !important;
        }
        .col-md-5{
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }
    @media(max-width: 767px){
        .pr-0{
            padding-right: 15px !important;
        }
    }
`;
export default MyComponent;