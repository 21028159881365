import React from 'react';
import styled from 'styled-components';

/**
 * Icon Right component
 * Pass the props with your data to the Icon Left component.
 **/
const Phone = () => {
  return (
    <StyledPhone>
      <svg xmlns="http://www.w3.org/2000/svg" width="14.912" height="16" viewBox="0 0 14.912 16">
        <path id="Path_9567" data-name="Path 9567" d="M14.113,11.136c-.967-.827-1.947-1.327-2.9-.5l-.57.5c-.417.362-1.193,2.054-4.191-1.395s-1.214-3.981-.8-4.34l.573-.5c.95-.827.591-1.869-.094-2.941L5.72,1.308C5.032.238,4.283-.464,3.331.362l-.515.45a4.83,4.83,0,0,0-1.883,3.2c-.343,2.272.74,4.873,3.222,7.727s4.906,4.29,7.2,4.265a4.843,4.843,0,0,0,3.425-1.419l.516-.45c.95-.825.36-1.666-.608-2.494Z" transform="translate(-0.872 0)" fill="#fff"/>
      </svg>
    </StyledPhone>
  );
};

/**
 * Styled component for Icon Right Single
 * Add your styles for  Icon Right component here.
 **/

const StyledPhone = styled.div`

    display: inline-block;
`;

export default React.memo(Phone);
