import React from 'react';
import styled from 'styled-components';
import {hover} from '../../styles/globalStyleVars';

/**
 * Icon Left component
 * Pass the props with your data to the Icon Left component.
 **/
const BsDown = () => {
  return (
    <StyledBsDown className={'map-icon'}>

      <svg id="Group_24266" data-name="Group 24266" xmlns="http://www.w3.org/2000/svg" width="99" height="48" viewBox="0 0 99 48">
        <g id="Base" fill="none" stroke="none" strokeWidth="1">
          <rect width="99" height="48" rx="24" stroke="none"/>
          <rect x="0.5" y="0.5" width="98" height="47" rx="23.5" fill="none"/>
        </g>
        <text id="Map" transform="translate(20 29)" fill="#fff" fontSize="16" fontFamily="Graphik-Medium, Graphik" fontWeight="500">
          <tspan x="0" y="0">Map</tspan>
        </text>
        <g id="Arrow" transform="translate(79 22.5) rotate(90)">
          <line id="Line_7" data-name="Line 7" x2="3" y2="3" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
          <line id="Line_8" data-name="Line 8" y1="3" x2="3" transform="translate(0 3)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
        </g>
        <g id="Mask" fill="none" stroke="none" strokeWidth="1">
          <rect width="99" height="48" rx="24" stroke="none"/>
          <rect x="0.5" y="0.5" width="98" height="47" rx="23.5" fill="none"/>
        </g>
      </svg>


      <svg className={'hover-svg-map'} xmlns="http://www.w3.org/2000/svg" width="99" height="48" viewBox="0 0 99 48">
        <g id="Hover" transform="translate(-1169 -660)">
          <rect id="Base" width="99" height="48" rx="24" transform="translate(1169 660)" fill="#a08750"/>
          <text id="Map" transform="translate(1189 689)" fill="#fff" fontSize="16" fontFamily="Graphik-Medium, Graphik" fontWeight="500">
            <tspan x="0" y="0">Map</tspan>
          </text>
          <g id="Arrow" transform="translate(1248 682.5) rotate(90)">
            <line id="Line_7" data-name="Line 7" x2="3" y2="3" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
            <line id="Line_8" data-name="Line 8" y1="3" x2="3" transform="translate(0 3)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
          </g>
        </g>
      </svg>

    </StyledBsDown>
  );
};

/**
 * Styled component for Icon Left Single
 * Add your styles for  Icon Left component here.
 **/
const StyledBsDown = styled.div`

    position: relative;
    border-radius: 24px;
    overflow: hidden;
    height: 48px;
    min-width: 99px;
    border: 1px solid white;
    @media (max-width: 992px) {
        max-width: 100%;
        min-width: 100%;
      svg{
        max-width: 100%;
        min-width: 100%;
        width: 100%;
      }
    }

    svg {
        position: absolute;
        transition: 0.7s all ease;

        &:first-child {
            z-index: 0;
        }

        &:last-child {
            transform: translateY(50px);
            z-index: 1;
        }

    }

    &:hover {
      border: 1px solid ${hover};
      
        svg {
            &:first-child {
                z-index: 0;
                transform: translateY(-50px);

            }

            &:last-child {
                transform: translateY(0);
            }
        }
    }

`;


export default React.memo(BsDown);
