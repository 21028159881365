import homeReducer from '../redux/home';
import aboutReducer from '../redux/about';
import contactReducer from '../redux/contact';
import searchReducer from '../redux/search';
import newsletterReducer from '../redux/newsletter';
import newsReducer from '../redux/news';
import universityReducer from '../redux/university';
import englishReducer from '../redux/english';
import studentReducer from '../redux/student';
import parternsReducer from '../redux/parterns';
import scholarshipReducer from '../redux/scholarship';
import courseReducer from '../redux/course';
import becomeparternsReducer from '../redux/becomeparterns';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web


// Combine reducers
const rootReducer = combineReducers({
  home: homeReducer,
  about: aboutReducer,
  contact: contactReducer,
  search: searchReducer,
  newsletter: newsletterReducer,
  news: newsReducer,
  university: universityReducer,
  english: englishReducer,
  student: studentReducer,
  partners: parternsReducer,
  scholarship: scholarshipReducer,
  course: courseReducer,
  becomeparterns: becomeparternsReducer,
});

// Configure Redux Persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['home', 'about', 'contact', 'search', 'newsletter', 'news', 'university' , 'english' , 'becomeparterns' , 'course',  'student' , 'partners' , 'scholarship'], // Add any reducer keys you want to persist
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure Redux Store
const store = configureStore({
  reducer: persistedReducer,
});



export default store;

