import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import {hover, text} from '../../styles/globalStyleVars';
import {ImgLazyLoad} from '../nzuac-image/ImgLazyLoad';
import ReactHtmlParser from 'react-html-parser';
import MapButton from '../nzuac-svg/MapButton';

const MyComponent = ({data, open}) => {

  const addressList = data?.posts?.list;


  return (
    <StyledComponent className={'pt-160 pb-160'}>

      {
        addressList && addressList.length > 0 ?
          <Accordion defaultActiveKey={open ? '0' : '0'}>
            {
              addressList && addressList.length > 0 &&
                            addressList.map((element, index) => {

                              return (
                                <Accordion.Item key={index} eventKey={`${index}`}>
                                  <Container>
                                    <Accordion.Header>
                                      <Row>
                                        <Col md={3}>
                                          <h6 className={''}>{element?.data?.title}</h6>
                                        </Col>
                                        <Col md={4}>
                                          <h5 className={''}>Visit</h5>
                                          <a href={element?.data?.subtitle} target="_blank" rel="noreferrer">
                                            <p className={''}>
                                              {ReactHtmlParser(element?.data?.short_desc)}
                                            </p>
                                          </a>

                                        </Col>
                                        <Col md={3}>
                                          <h5 className={''}>Contacts</h5>
                                          {ReactHtmlParser(element?.data?.description)}
                                        </Col>
                                        <Col md={2} className={'mapButton'}>
                                          <MapButton/>

                                        </Col>
                                      </Row>
                                    </Accordion.Header>
                                  </Container>

                                  <Accordion.Body>

                                    <div className="mapImage">
                                      <a href={element?.data?.subtitle} target="_blank" rel="noreferrer">
                                        <ImgLazyLoad  alt={element?.images?.[0]?.short_title} src={element?.images?.[0]?.full_path}/>
                                      </a>
                                    </div>
                                    <Container>
                                      <Row>
                                        <Col md={12}>
                                          <div className={'header-border'}></div>
                                        </Col>
                                      </Row>
                                    </Container>
                                  </Accordion.Body>
                                </Accordion.Item>
                              );
                            })
            }


          </Accordion> : ''

      }

    </StyledComponent>
  );
};

const StyledComponent = styled.section`
    background-color: ${text};

    

  
    .title {
        margin-bottom: 60px;
        @media (max-width: 767px) {
            margin-bottom: 0;
        }
    }

    .accordion-item {
        //margin-top: 60px;
        //margin-bottom: 60px;
      &:first-child{
        button{
          margin-top: 0;
        }
      }
        &:nth-last-child(1) {
            margin-bottom: 0;
        }

        p {
            color: #FFFFFF;
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;

            a {
                color: #FFFFFF;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;

                &:hover {
                    a {
                        color: ${hover};

                    }
                }
            }

            @media (max-width: 992px) {
                margin-bottom: 30px;
            }

            &:hover {
                color: ${hover};
            }
        }

        a {
            color: #FFFFFF;
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;

            &:hover {
                a {
                    color: ${hover};

                }
            }
        }

        h6 {
            color: #FFFDFB;
            font-size: 24px;
            line-height: 28px;
            @media (max-width: 767px) {
                margin-bottom: 30px;
            }

            &:hover {
                color: #897059;
            }
        }

        h5 {
            margin: 0 0 10px;
            color: rgba(255, 255, 255, 0.5);
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
        }

        &:last-child {
            .accordion-body {
                .header-border {
                    padding-top: 40px;
                    border-bottom: 1px solid transparent;
                    border-left: none;
                    border-right: none;
                }
            }
        }
    }

    .accordion-header {
        position: relative;
        margin: 0;

        .mapButton {
            display: flex;
            justify-content: flex-end;
            gap: 20px;

            &__wrap {
                margin-top: -11px;

            }

            @media (max-width: 767px) {
                justify-content: flex-start;
                flex-direction: column;
            }
        }

        button {
            border: none;
            background-color: transparent;
            padding-left: 0;
            color: ${hover};
            font-size: 26px;
            font-weight: bold;
            line-height: 32px;
            text-transform: capitalize;
            padding-bottom: 40px;
            border-bottom: 1px solid rgba(255, 253, 251, 0.5);
            width: 100%;
            width: 100%;
            text-align: left;
            //margin-bottom: 50px;
            transition: all .4s ease;
            margin-top: 35px;
            
          
            &.collapsed {
                //color: #1A1818;
                //border-color: #1A1818;
                padding-bottom: 40px;

                svg {
                }

                .mapButton__wrap {
                    margin-top: -11px;

                    svg {
                        transform: rotate(0deg);

                        #Line_3865 {
                            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                            stroke: #FFFFFF;

                        }

                        #Line_3866 {
                            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                            stroke: #FFFFFF;
                        }

                        #Ellipse_4378 {
                            r: 26;
                            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

                        }

                        #Ellipse_4377 {
                            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                        }

                        &:hover {
                            #Ellipse_4378 {
                                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                                r: 0;
                            }

                            #Line_3865 {
                                stroke: #FFFDFB;
                            }

                            #Line_3866 {
                                stroke: #FFFDFB;
                            }

                            #Ellipse_4377 {
                            }
                        }
                    }
                }

            }


        }


      button[aria-expanded="true"] {
        .map-icon{
          border: 1px solid ${hover};
          
        }
        svg {
          &:first-child {
            z-index: 0;
            transform: translateY(-50px);

          }

          &:last-child {
            transform: translateY(0);
          }
        }
      }


      .accordion-item:last-child .button {
            border-bottom: none;
        }


        &:hover {

            button {
                color: ${hover};
                border-color: ${hover};
            }
        }
    }

    .accordion-body {
        .mapImage {
            position: relative;
            padding-top: calc(520 / 1366 * 100%);

            @media (max-width: 767px) {
                padding-top: calc(280 / 375 * 100%);
            }
        }

        .header-border {
            padding-top: 40px;
            border-bottom: 1px solid rgba(255, 253, 251, 0.5);
            border-left: none;
            border-right: none;
        }
    }

    .accordion-item:last-child .accordion-header .accordion-button {
        border-bottom: none !important;
    }


  
  @media(max-width: 992px){
    .col-md-3, .col-md-4, .col-md-2{
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .accordion-header .mapButton{
      justify-content: flex-start;
    }
    .accordion-header button {
      svg{
        rect{
          width: 100%;
        }
      }
      .map-icon{
        max-width: unset;
        min-width: 99px;
      }
    }
  }
  
`;

export default MyComponent;
