import ErrorPage from '../components/nzuac-error/404';
import gsap, {CSSPlugin} from 'gsap';
import {useLocation} from 'react-router-dom';
import {useEffect} from 'react';
gsap.registerPlugin(CSSPlugin);

const Eror = () => {


  const location = useLocation();
  useEffect(() => {
    // Use GSAP to animate opacity from 0 to 1
    gsap.to('.hide', {alpha: 1, visibility: 'visible', display: 'block', duration: 0.5, delay: 1});
    gsap.to('.pagetransition-loader', {alpha: 0, display: 'none', height: '0', duration: 0.5, delay: 1});
    gsap.to('.desktop-menu__bottom__parent-ul > li ul', {alpha: 0, duration: 0.5, delay: 1});

    // if()

    const body = document.querySelector('body');
    const html = document.querySelector('html');
    const applyNow = document.querySelector('.applyNow');

    if (body.classList.contains('scroll-down')) {
      body.classList.remove('scroll-down');
    }

    if (body.classList.contains('scroll-up')) {
      body.classList.remove('scroll-up');
    }

    if (body.classList.contains('menu-is-open')) {
      body.classList.remove('menu-is-open');
    }

    if (html.classList.contains('menu-is-open')) {
      html.classList.remove('menu-is-open');
    }

    if (body.classList.contains('mobile-menu-open')) {
      body.classList.remove('mobile-menu-open');
    }

    if (html.classList.contains('mobile-menu-open')) {
      html.classList.remove('mobile-menu-open');
    }
    if ((body.classList.contains('stop-scroll')) && window.innerWidth < 992) {
      body.classList.remove('stop-scroll');
    }

    if ((applyNow) && window.innerWidth < 992) {
      applyNow.classList.remove('mobile-menu-open');
    }
    var menuOpen = document.querySelectorAll('.Mobile-menu-wrap');
    var hamburger = document.querySelectorAll('.hamburger');
    var menu = document.querySelectorAll('.main_child');
    var menuSub = document.querySelectorAll('.main-child-sub ');
    var menuSubSub = document.querySelectorAll('.main-child-sub-sub');
    hamburger.forEach((i) => {
      i.classList.remove('menu-open');
      gsap.to(document.querySelector('.mobile-menu__items'), {
        opacity: 0,
        duration: '.2'
      });
      gsap.to(document.querySelector('.mobile-menu__items'), {
        display: 'none'
      });
    });
    menuOpen.forEach((i) => {
      i.classList.remove('menu-open');
    });

    menu.forEach((i) => {
      i.classList.remove('submenu-open-mb');
    });
    menuSub.forEach((i) => {
      i.classList.remove('submenu-open-next');
    });
    menuSubSub.forEach((i) => {
      i.classList.remove('submenu-open-next-next');
      i.classList.remove('submenu-open-next');
      i.classList.remove('submenu-open-mb');
    });


    const contact = document.querySelector('.submenu ');



    if((contact)  && window.innerWidth < 992 ){
      if(contact.classList.contains('sub-menu-open')){
        contact.classList.remove('sub-menu-open');

      }
    }
    // You can customize the animation duration and other properties as needed
  }, [location.pathname]); // Run this effect only once when the component mounts


  return (
    <>
      <ErrorPage/>
    </>
  );


};


export default Eror;



