import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {get} from '../../network/axiosServices';


const initialState = {
  loading: false,
  posts: [],
  error: '',
  detail: [],
  feature: [],
  detailLoading: false,
  detailError: ''
};

export const fetchPosts = createAsyncThunk('fetchDataStudents', (params) => {
  return get(params);
});



const postSlice = createSlice({
  name: 'posts',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchPosts.pending, (state) => {
      state.loading = true;
      state.posts = [];
      state.error = '';
    });
    builder.addCase(fetchPosts.fulfilled, (state, action) => {
      state.loading = false;
      state.posts = action.payload;
      state.error = '';
    });
    builder.addCase(fetchPosts.rejected, (state, action) => {
      state.loading = false;
      state.posts = [];
      state.error = action.error;
    });
  }
});


export default postSlice.reducer;
