import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {hover, text, white} from '../../styles/globalStyleVars';
import NewsBox from '../nzuac-single-item/NewsBox';
import ButtonIconAnchor from '../nzuac-buttons/ButtonIconAnchor';
import SearchButtonNews from '../nzuac-buttons/SearchButtonNews';
import {useLocation, useNavigate} from 'react-router-dom';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {ApiParam} from '../../api/network/apiParams';
import {fetchPostsChild} from '../../api/redux/news';
import {useDispatch} from 'react-redux';

const Directors = ({padding, data}) => {

  const dispath = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // Define a function to handle the redirection
  const redirectToNewUrl = (e) => {
    let api_url_child = apiEndPoints.BLOGLIST;
    dispath(fetchPostsChild([api_url_child]));
    var newUrl = '/news';
    // Update the browser's address bar
    navigate(newUrl);
  };


  // Function to parse query parameters from location search string
  function getQueryParams(location) {
    const searchParams = new URLSearchParams(location.search);
    const queryParams = {};
    for (const [key, value] of searchParams) {
      queryParams[key] = value;
    }
    return queryParams;
  }

  // Get the category value
  const queryParams = getQueryParams(location);
  const category = queryParams['category'];
  let category_id = null;

  if (category === 'news') {
    category_id = 1;
  } else if (category === 'blogs') {
    category_id = 2;
  } else if (category === 'events') {
    category_id = 3;
  } else {
    category_id = '';
  }


  const handleCLickTab = (value) => {


    let api_url_child = apiEndPoints.BLOGLIST;

    let param_2 = {
      [ApiParam.CATEGORY_ID]: value ? value : null,
    };
    dispath(fetchPostsChild([api_url_child, param_2]));

    let category_id = null;


    if (value === 1) {
      category_id = 'news';
    } else if (value === 2) {
      category_id = 'blogs';
    } else if (value === 3) {
      category_id = 'events';
    } else {
      category_id = '';
    }


    let newUrl = null;
    if (category_id) {
      // Construct the new URL
      newUrl = `/news?category=${category_id}`;

    } else {
      newUrl = '/news';
    }


    // Update the browser's address bar
    navigate(newUrl);
  };



  return (
    <StyledDirectors  id={'news-listing'} className={`${padding ? padding : 'pt-100 pb-100'}`}>

      <Container className={'filter-container'}>
        <Row>
          <Col md={8} className={'filter'}>
            <ul>
              <li className={`${category_id ? '' : 'active'}`} onClick={redirectToNewUrl}>
                <ButtonIconAnchor text={'All'} src={'#'} width={60} hoverTextcolor={white} color={text} borderColor={text} background={'transparent'} hoverBackground={hover}/>
              </li>
              <li className={`${category_id === 2 ? 'active' : ''}`} onClick={() => handleCLickTab(2)}>
                <ButtonIconAnchor src={'#'} text={'Blogs'} width={85} hoverTextcolor={white} color={text} borderColor={text} background={'transparent'} hoverBackground={hover}/>
              </li>
              <li className={`${category_id === 1 ? 'active' : ''}`} onClick={() => handleCLickTab(1)}>
                <ButtonIconAnchor src={'#'} text={'News'} width={85} hoverTextcolor={white} color={text} borderColor={text} background={'transparent'} hoverBackground={hover}/>
              </li>
              <li className={`${category_id === 3 ? 'active' : ''}`} onClick={() => handleCLickTab(3)}>
                <ButtonIconAnchor src={'#'} text={'Events'} width={85} hoverTextcolor={white} color={text} borderColor={text} background={'transparent'} hoverBackground={hover}/>
              </li>

            </ul>
          </Col>
          <Col md={4} className={'search-form'}>
            <SearchButtonNews borderColor={white} icon={true} text={'Search'}/>

          </Col>
        </Row>
      </Container>
      <Container>


        <Row className={'list'}>
          {
            data && data?.length > 0 &&
              data?.map((item, index) => {
                let thumb = item?.images?.list.find(f => f?.thumb === 'on');

                return (
                  <Col key={index} md={4}>
                    <NewsBox cat={item?.data?.category_id === 1 ? 'News' : item?.data?.category_id === 2 ? 'Blog' : item?.data?.category_id === 3 ? 'Events' : ''} date={item?.data?.date} link={`${item?.data?.slug}`} img={thumb?.full_path} title={item?.data?.title}/>
                  </Col>
                );
              })
          }


        </Row>

      </Container>

    </StyledDirectors>
  );
};

const StyledDirectors = styled.section`
  background: #F1F0EE;
  margin-top: -1px;

  @media(max-width: 767px){
    margin-top: 0;
  }
  .filter {
    margin-bottom: 60px;

    ul {
      display: flex;

      li {
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          a {
            border: 1px solid ${hover};
          }

          .dc-btn {


            .show-text {
              transform: translateY(-100px);

            }

            .hover-text {
              transform: translateY(0);

            }
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .filter-container {
      .row {
        flex-direction: column-reverse;

        .search-form {
          margin-bottom: 30px;
        }

        .col-md-8 {
          max-width: 100%;
        }

        .col-md-4 {
          max-width: 100%;
        }
      }

      .filter {
        ul {
          display: flex;
          flex-flow: wrap;
          gap: 25px;

          li {
            margin: 0 !important;
            //max-width: calc(25% - 25px);
            //width: 100%; /* Set the width of li to 100% */
            //box-sizing: border-box; /* Include padding and border in the element's total width and height */
            //display: inline-block;

            .dc-btn {
              min-width: unset;
              width: 100%;

              a {
                padding: 0 40px;
              }
            }
          }
        }
      }
    }

    .col-md-4 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }


  @media (max-width: 767px) {
    .filter-container {
      .row {
        flex-direction: column-reverse;

        .search-form {
          margin-bottom: 30px;
        }

        .col-md-8 {
          max-width: 100%;
        }

        .col-md-4 {
          max-width: 100%;
        }
      }

      .filter {
        ul {
          display: flex;
          flex-flow: wrap;
          gap: 25px;

          li {
            margin: 0 !important;
            //max-width: calc(50% - 25px);
            //width: 100%; /* Set the width of li to 100% */
            //box-sizing: border-box; /* Include padding and border in the element's total width and height */
            //display: inline-block;

            .dc-btn {
              min-width: unset;
              width: 100%;
            }
          }
        }
      }
    }

    .col-md-4 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .search-form {
    .dc-btn {
      width: 100%;
    }
  }

  h2 {
    margin-bottom: 40px;
    color: ${white};
  }

  .list {
    min-height: 50vh;
  }

  .list .col-md-4 {
    margin-bottom: 30px;

  }

  @media (max-width: 767px) {
    .col-md-4 {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export default Directors;




