import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {hover, text, white} from '../../styles/globalStyleVars';
import {PinterestShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share';

const SocialShare = ({direction}) => {


  let [shareUrl, setShareUrl] = useState('');


  useEffect(() => {
    setShareUrl(window.location.href);
  }, [shareUrl]);

  return (

    <StyledSocial direction={direction}>
      <p>Share:</p>
      <ul className={'social'}>
        <li>
          <FacebookShareButton url={shareUrl}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
              viewBox="0 0 25 25">
              <g id="Group_19953" data-name="Group 19953"
                transform="translate(-398 -664)">
                <circle id="Ellipse_447" data-name="Ellipse 447" cx="12.5"
                  cy="12.5" r="12.5"
                  transform="translate(398 664)" fill={hover} stroke={hover}/>
                <circle className={'hover_circle'} data-name="Ellipse 594"
                  cx="12.5" cy="12.5" r="0"
                  transform="translate(398 664)" fill={white} stroke={white}/>
                <path id="Path_2115" data-name="Path 2115"
                  d="M1204.9,102.967l.339-2.21h-2.12V99.323a1.1,1.1,0,0,1,1.246-1.194h.964V96.248a11.752,11.752,0,0,0-1.711-.149,2.7,2.7,0,0,0-2.887,2.974v1.684h-1.941v2.21h1.941v5.341h2.388v-5.341Z"
                  transform="translate(-791.62 574.567)" fill={white}/>
              </g>
            </svg>

          </FacebookShareButton>
        </li>
        <li>
          <TwitterShareButton url={shareUrl}>

            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
              viewBox="0 0 25 25">
              <g id="Group_19952" data-name="Group 19952"
                transform="translate(-438 -664)">
                <circle id="Ellipse_93" data-name="Ellipse 93" cx="12.5"
                  cy="12.5" r="12.5"
                  transform="translate(438 664)" fill={hover} stroke={hover}/>
                <circle className={'hover_circle'} data-name="Ellipse 594"
                  cx="12.5" cy="12.5" r="0"
                  transform="translate(438 664)" fill={white} stroke={white}/>

                <g id="layer1" transform="translate(444.667 671.5)">
                  <path id="path1009"
                    d="M281.527,167.31l4.26,5.7-4.287,4.632h.965l3.754-4.055,3.033,4.055h3.284l-4.5-6.017,3.991-4.311h-.965l-3.457,3.735-2.793-3.735Zm1.419.711h1.509l6.661,8.907h-1.509Z"
                    transform="translate(-281.5 -167.31)" fill={white}/>
                </g>
              </g>
            </svg>


          </TwitterShareButton>
        </li>
        <li>
          <LinkedinShareButton url={shareUrl}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
              viewBox="0 0 25 25">
              <g id="Group_19949" data-name="Group 19949"
                transform="translate(-558 -664)">
                <circle id="Ellipse_594" data-name="Ellipse 594" cx="12.5"
                  cy="12.5" r="12.5"
                  transform="translate(558 664)" fill={hover} stroke={hover}/>
                <circle className={'hover_circle'} data-name="Ellipse 594"
                  cx="12.5" cy="12.5" r="0"
                  transform="translate(558 664)" fill={white} stroke={white}/>
                <g id="_x31_0.Linkedin" transform="translate(565.5 671.5)">
                  <path id="Path_2123" data-name="Path 2123"
                    d="M44.965,42.352V38.575c0-1.856-.4-3.275-2.565-3.275a2.239,2.239,0,0,0-2.024,1.109H40.35v-.941H38.3v6.884h2.14V38.936c0-.9.168-1.766,1.276-1.766s1.109,1.018,1.109,1.818v3.352h2.14Z"
                    transform="translate(-34.652 -32.038)"
                    fill={white}/>
                  <path id="Path_2124" data-name="Path 2124"
                    d="M11.3,36.6h2.14v6.884H11.3Z"
                    transform="translate(-11.132 -33.171)"
                    fill={white}/>
                  <path id="Path_2125" data-name="Path 2125"
                    d="M11.238,10a1.244,1.244,0,1,0,1.238,1.238A1.238,1.238,0,0,0,11.238,10Z"
                    transform="translate(-10 -10)" fill={white}/>
                </g>
              </g>
            </svg>
          </LinkedinShareButton>
        </li>
        <li>
          <PinterestShareButton url={shareUrl}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
              <circle id="Ellipse_99" data-name="Ellipse 99" cx="15" cy="15" r="15"  fill={hover} stroke={hover}/>
              <circle className={'hover_circle'}  id="Ellipse_99" data-name="Ellipse 99" cx="15" cy="15" r="0" fill={white} stroke={white}/>
              <g id="Group_23857" data-name="Group 23857" transform="translate(-23.176 6.924)">
                <path id="Path_9210" data-name="Path 9210" d="M44.92,4.239a3.873,3.873,0,0,1-2.34-.782A3.874,3.874,0,0,1,41.033.39h-2.51V7.248l0,3.756a2.275,2.275,0,1,1-1.56-2.156V6.3a4.939,4.939,0,0,0-.724-.054A4.812,4.812,0,0,0,32.62,7.864a4.716,4.716,0,0,0,.213,6.5,4.9,4.9,0,0,0,.453.4,4.813,4.813,0,0,0,2.95,1,4.939,4.939,0,0,0,.724-.053,4.8,4.8,0,0,0,2.68-1.34,4.7,4.7,0,0,0,1.41-3.344l-.013-5.609a6.359,6.359,0,0,0,3.891,1.322v-2.5H44.92Z" fill="#fff"/>
              </g>
            </svg>

          </PinterestShareButton>

        </li>
      </ul>
    </StyledSocial>

  );
};

const StyledSocial = styled.div`
  margin-bottom: 0;

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${text};
    margin-bottom: 10px;
  }

  .social {
    display: flex;
    flex-direction: ${props => props.direction ? 'column' : 'row'};
    gap: 20px;

    li {
      list-style: none !important;

      &:after {
        display: none !important;
      }

      &:before {
        display: none !important;
      }

      a {
        &:after {
          display: none !important;
        }

        &:before {
          display: none !important;
        }
      }
    }

    svg {
      overflow: hidden;
      border-radius: 50%;
      height: 30px;
      width: 30px;

      .hover_circle, path, #Rectangle_6283 {
        transition: 0.5s all cubic-bezier(.25, .74, .22, .99);
      }
    }

    li {
      margin-left: 0px;
      margin-bottom: 0 !important;

      &:first-child {
        margin-left: 0;
      }

      button {
        &:hover {
          svg {
            .hover_circle {
              r: 12.5px;
              opacity: 1;
            }
            #Ellipse_99{
              r: 15px;
            }

            path {
              fill: ${text};
                        }

                        #Path_174, #path_173 {
                            fill: none !important;
                            stroke: ${text} !important;
                        }

                    }
                }
            }
        }
    }

    @media (max-width: 992px) and (min-width: 767px) {
        .social {
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: flex-end;
            gap: 20px;

            li {
                margin-left: 0;
            }
        }
    }
    @media (max-width: 767px) {
        margin-top: 30px;
        .social {
            flex-direction: row;
        }
    }
`;

export default React.memo(SocialShare);














