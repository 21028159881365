export const text = '#040504';
export const hover = '#A08750';
export const coffe = '#A08750';
export const gray = '#888888';
export const white = '#FFFFFF';
export const offwhite = '#F6F6F8';
export const softGray = '#F1F0EE';
export const body = '"Graphik", Arial, Helvetica, freesans, sans-serif ';
export const title = '"Graphik", Arial, Helvetica, freesans, sans-serif ';




// need to delete this part extra variables
export const downloadBg = '#F8F8F8';
export const serviceBg = '#C7C4B8';
export const whiteSoft = '#FAFAFA';
export const serviceBox = '#293A8A';
export const serviceBoxBlue = '#04133F';
export const testimonial = '#E6E5DF';
