import React, {useEffect} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import styled from 'styled-components';
import {hover, text, white} from '../../styles/globalStyleVars';
import {ImgLazyLoad} from '../nzuac-image/ImgLazyLoad';
import reactHtmlParser from 'react-html-parser';
import gsap, {CSSPlugin} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {SplitText} from 'gsap/SplitText';
import {useLocation} from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger, CSSPlugin, SplitText);

/**
 * Overview Type 6 component section
 * Pass the props with your data to the Overview Type 6 component.
 * Check the page folder to see the components used.
 **/
const OverviewSix = ({data, id, offset}) => {

  /**
     * variables
     * Change / add your custom variable
     **/

  const location = useLocation();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 767) {
        const containerOffsetLeft = document.querySelector('.container').offsetLeft;
        // const lubeTestingImgBox = document.querySelector('.ministry__container');
        const customs_list = document.querySelector('.right-wrapper-col');
        const marginLeft = containerOffsetLeft + 30;
        // lubeTestingImgBox.style.setProperty('right', `${marginLeft}px`);
        customs_list.style.setProperty('padding-left', `${100}px`);
        customs_list.style.setProperty('padding-right', `${marginLeft}px`);
      }
    };

    handleResize(); // Initial positioning

    ScrollTrigger.refresh();
  });


  useEffect(() => {
    if (window.innerWidth > 1700) {

      // Start GSAP timeline
      const timeline = gsap.timeline({
        scrollTrigger: {
          scrub: 1,
          pin: '.pin-wrapper',
          trigger: '.ministry',
          start: 'top -23px',
          pinSpacing: false,
          ease: 'elastic',
          id: 'whois',
          end: '+=455',
          pinType: 'transform',
          toggleActions: 'play none none reverse',
        }
      });

      // Return cleanup function to clear ScrollTrigger
      return () => {
        timeline.kill();
      };
    }
    if (window.innerWidth > 1024) {

      // Start GSAP timeline
      const timeline = gsap.timeline({
        scrollTrigger: {
          scrub: 1,
          pin: '.pin-wrapper',
          trigger: '.ministry',
          start: 'top -23px',
          pinSpacing: false,
          ease: 'elastic',
          id: 'whois',
          end: '+=555',
          pinType: 'transform',
          toggleActions: 'play none none reverse',
        }
      });

      // Return cleanup function to clear ScrollTrigger
      return () => {
        timeline.kill();
      };
    }
  }, [location?.pathname]); // Run effect only once when component mounts

  return (
    <StyledOverview6 id={id} offset={offset} className={'ministry '}>
      <Container fluid className={'ministry__container'} >
        <Row>
          <Col md={{span: 5}} className={'pin-wrapper p-0'}>
            <div className="image-wrapper">
              <ImgLazyLoad alt={reactHtmlParser(data.section_data?.subtitle)} src={data
                ?.images?.list?.[0]?.full_path}/>

            </div>
          </Col>
          <Col md={{span:7}} className={'right-wrapper-col'}>
            <h2 className={'split-up'}>{reactHtmlParser(data.section_data?.subtitle)}</h2>
            <p className={'split-up'}>{reactHtmlParser(data.section_data?.short_desc)}</p>

            <div className="main-ul-list">

              {
                data.section_data?.description &&
                      reactHtmlParser(data.section_data?.description)
              }
            </div>
          </Col>
        </Row>
      </Container>
    </StyledOverview6>
  );
};

/**
 * Styled component for Overview Type 6 component section
 * Add your custom styles for this component
 **/
const StyledOverview6 = styled.section`
  position: relative;


  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 50%;
    bottom: 0;
    background-color: ${white};
  }

  .right-wrapper-col {
    padding: 120px 0;
    background-color: ${text};

    h2 {
      color: ${white};
      margin-bottom: 20px;
    }

    p {
      color: ${white};

    }

    ul {
      margin-top: 60px;
      padding: 0;
      counter-reset: listItem;

      li {
        position: relative;

        h4 {
          font-size: 16px;
          line-height: 20px;
          color: ${white};
          font-weight: 500;
          margin-bottom: 20px;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          position: relative;
          color: rgba(255, 255, 255, 0.5);
          margin: 0 !important;
        }

        padding-left: 90px;

        &:not(:last-child) {
          padding-bottom: 30px;
        }

        &:not(:first-child) {
          margin-top: 30px;
        }

        &:before {
          content: counter(listItem);
          counter-increment: listItem;
          position: absolute;
          top: 12px;
          left: 0;
          margin-top: -15px;
          display: flex;
          align-items: flex-start;
          box-sizing: content-box;
          font-size: 40px;
          line-height: 44px;
          font-weight: 500;
          height: 100%;
          color: white;
        }
      }
    }
  }

  .ministry__container {
    position: relative;
    z-index: 2;

    .image-wrapper {
      position: relative;
      padding-top: 768px;
      background: #F1F0EE;
    }

    .customs-list {
      padding: 0;
      counter-reset: listItem;

      li {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        position: relative;
        color: ${white};

        h4 {
          font-size: 24px;
          font-weight: bold;
          line-height: 28px;
          color: ${white};
          margin-bottom: 20px;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          position: relative;
          color: ${white};
          margin: 0 !important;
        }

        padding-left: 90px;

        &:not(:last-child) {
          border-bottom: 1px solid rgba(217, 217, 217, 0.5);
          padding-bottom: 40px;
        }

        &:not(:first-child) {
          margin-top: 40px;
        }

        &:before {
          content: counter(listItem);
          counter-increment: listItem;
          position: absolute;
          top: 30px;
          left: 0;
          margin-top: -15px;
          display: flex;
          align-items: flex-start;
          box-sizing: content-box;
          font-size: 60px;
          line-height: 60px;
          font-weight: bold;
          height: 100%;
        }
      }
    }

    .row {
      padding-bottom: 0 !important;
    }
  }


  .col-sm-6 {
    &:not(:nth-last-of-type(1)) {
      margin-bottom: 100px;
    }
  }

  h3 {
    font-size: 44px;
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 56px;
    color: ${white};

    span {
      color: ${hover};
    }
  }

  //responsive
  @media (max-width: 992px) {
    top: 0;
    margin-bottom: 0;
    .ministry__container {
      right: 0 !important;

      .row {
        padding: 0;
      }
    }

    .col-md-5 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      margin: 0;
    }

    .col-md-7 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      margin: 0;
      padding-left: ${(props) => props.offset + 30}px;
      padding-right: ${(props) => props.offset + 30}px;
    }
  }
  @media (max-width: 767px) {
    h3 {
      font-size: 32px;
      line-height: 40px;
    }
    .row {
      padding: 0 !important;
    }
    .ministry__container {
      padding-left: 0 !important;
      padding-right: 0 !important;

      .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      .col-sm-2,
      .col-sm-7 {
        min-width: 100%;
        margin: 0;
      }

      .customs-list li {
        padding-left: 70px;
      }

      .col-sm-2 {
        padding: 0 30px !important;
        margin-bottom: 60px;

        img {
          height: 70px;
        }
      }

      .right-wrapper-col {
        padding-left: 15px !important;
        padding-right: 15px !important;
        ul li{
          padding-left: 0;
          
          &:before{
            position: relative;
            font-size: 28px;
            font-weight: 500;
            line-height: 32px;
            margin-bottom: 20px;
          }
        }
      }

      .col-sm-6 {
        min-width: 100%;

        &:not(:nth-last-of-type(1)) {
          margin-bottom: 60px;
        }

        h4 {
          font-size: 60px;
          line-height: 60px;
          padding-bottom: 10px;
          margin-bottom: 20px;
          letter-spacing: -4px;

          &:after {
            width: 100%;
          }
        }
      }
    }
  }
`;

export default React.memo(OverviewSix);
