import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Social from '../nzuac-social-icons/Social';
import {hover, white} from '../../styles/globalStyleVars';
import reactHtmlParser from 'react-html-parser';
import {ImgLazyLoad} from '../nzuac-image/ImgLazyLoad';
import {Img} from '../nzuac-image/Img';


const ImageSocialBox = ({title, subtitle, description, img, modal}) => {


  return (

    <StyeldSection className={'slider_component_latest'}>

      <div className={'single_image_image'}>
        <div className="content_wrap" style={{height: '100%'}}>
          <div className="single_image_image_wrp ">
            {
              modal
                ?
                <Img offset={0} src={img}/>
                :
                <ImgLazyLoad offset={0} src={img}/>

            }
            <div className="single_content_wrp">
              <div className="content-info">
                <h3 className={'split-up'}>{title ? reactHtmlParser(title) : 'Title Here'}</h3>
                <p className={'split-up'}>{subtitle ? reactHtmlParser(subtitle) : 'SubTitle Here'}</p>
              </div>
              <div className="content-address">
                {
                  description ?
                    reactHtmlParser(description) : 'Description / Address Here'
                }
                <Social linkedin={'https://www.linkedin.com/company/nzuac/?originalSubdomain=nz'} bg={white} iconColor={hover}/>
              </div>
            </div>
          </div>

        </div>
      </div>

    </StyeldSection>

  );

};

const StyeldSection = styled.section`
    overflow: hidden;
    height: 100%;
    position: relative;

    &:after {
        position: absolute;
        inset: 0;

    }


    .single_image_image {
        height: 100%;
    }

    .content_wrap, .single_image_image_wrp {
        height: 100%;
    }

    .single_image_image {
        .content_wrap {
            .single_image_image_wrp {
                position: relative;
                padding-top: calc(620 / 570 * 100%);

                .single_content_wrp {
                    position: absolute;
                    inset: 0;
                    background: transparent;
                    padding: 70px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    h3 {
                        margin-bottom: 20px;
                    }

                    h3, p {
                        color: ${white}
                    }

                    a {
                        color: ${white}

                    }

                    ul {
                        margin-bottom: 10px;

                        li {
                            margin-bottom: 10px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }


`;


export default React.memo(ImageSocialBox);