import React, {useEffect} from 'react';
import styled from 'styled-components';
import {ImgLazyLoad} from '../nzuac-image/ImgLazyLoad';
import {Col, Container, Row} from 'react-bootstrap';
import reactHtmlParser from 'react-html-parser';
import {ImageParallaxNoLoad} from '../nzuac-image/ImageParallaxNoLoad';
import {gsap} from 'gsap';
import {white} from '../../styles/globalStyleVars';

const InnerBanner = ({img, logo, subtext, title, uptitle, project, notFull, noPara, data}) => {
  useEffect(() => {
    // Use GSAP to animate opacity from 0 to 1
    gsap.to('.InnerBanner', {alpha: 1, visibility: 'visible', duration: 0.5, delay: 0.5});
    gsap.to('.InnerBanner h1', {alpha: 1, visibility: 'visible', duration: 1, delay: 0.5});
    gsap.to('.InnerBanner p', {alpha: 1, visibility: 'visible', duration: 0.5, delay: 0.5});

    // You can customize the animation duration and other properties as needed
  }, [img]); // Run this effect only once when the component mounts


  return (
    <StyledInnerBanner notFull={notFull} project={project} className="InnerBanner">
      {
        noPara ?
          <ImgLazyLoad banner={true} alt={data?.section_data?.subtitle} src={data?.images?.list?.[0]?.full_path ? data?.images?.list?.[0]?.full_path : '/images/static/blurbg.svg'}/>
          :
          <ImageParallaxNoLoad alt={data?.section_data?.subtitle} banner={true} src={data?.images?.list?.[0]?.full_path ? data?.images?.list?.[0]?.full_path : '/images/static/blurbg.svg'}/>

      }
      <Container>
        <Row>
          <Col md={7}>
            <h1 className={'split-up'}>{data?.section_data?.subtitle ? reactHtmlParser(data?.section_data?.subtitle) : ''}</h1>
          </Col>
          <Col md={1}>

          </Col>
          <Col md={4}>
            <p className={'split-up'}>{data?.section_data?.short_desc ? reactHtmlParser(data?.section_data?.short_desc) : ''}</p>
          </Col>
        </Row>
      </Container>
    </StyledInnerBanner>
  );
};

const StyledInnerBanner = styled.section`
    padding-top: ${props => props.notFull ? '85svh' : '85svh'};
    position: relative;
    background-color: #DDD;
    height: ${props => props.notFull ? '85svh' : '85svh'};
    overflow: hidden;
    opacity: 0;
    visibility: hidden;

    &:after {
        //content: '';
        position: absolute;
        background: linear-gradient(142deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5018601190476191) 0%);
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
    }

    @media (max-width: 1024px) and (min-width: 768px) {
        padding-top: 65svh;
        overflow: hidden;
        height: 65svh;

    }


    .container {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 80px;
        z-index: 2;

        h1 {
            transform: translateY(0);
            opacity: 0;
            margin-bottom: 20px;
        }

        h1 {
            color: ${white};
        }

        p {
            color: ${white};

        }
    }


    @media (max-width: 992px) {
        .container{
          .col-md-7, .col-md-4, .col-md-1{
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
    }

    @media (max-width: 767px) {
        .container {
            bottom: 30px;
        }
    }


`;

export default InnerBanner;
