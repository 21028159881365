import React from 'react';
import styled from 'styled-components';
import {Container, Row} from 'react-bootstrap';
import {Img} from '../nzuac-image/Img';
import ButtonIcon from '../nzuac-buttons/ButtonIcon';
import {hover, text, white} from '../../styles/globalStyleVars';
import reactHtmlParser from 'react-html-parser';

const ErrorPage = () => {


  return (
    <StyledErrorPage className="StyledErrorPage ">
      <Img src={'./images/static/error.jpg'}/>

      <Container>
        <Row>
          <div className="StyledErrorPage__wrap">
            <div className="middle-content">
              <h1>Page not found!</h1>
              <p>{reactHtmlParser('The page you are looking for doesn\'t exist.')}</p>
              <div className="btn-group">
                <ButtonIcon margin={'60px 0 0'} height={48} src={'/'} width={200} text={'Go Back to Home'} hoverTextcolor={text} borderColor={hover} background={hover} hoverBackground={white}/>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </StyledErrorPage>
  );
};

const StyledErrorPage = styled.div`
  background: #e9e9e9;
  position: relative;
  padding: 200px 15px;
  min-height: 70svh;

  .container{
    position: relative;
    z-index: 3;
 
  }
  .middle-content {
  
  }

  .StyledErrorPage__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding: 120px 15px 50px 15px;

        h1 {
            margin-bottom: 30px;
            color: ${text};

        }

        p {
            font-size: 14px;
            line-height: 21px;
            color: ${text};
        }

    }


    @media (max-width: 500px) {
        .StyledErrorPage__wrap {
            text-align: center;
            margin-top: 80px;
        }

    }
`;
export default ErrorPage;
