import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Link} from 'react-router-dom';
import {hover, text, white} from '../../styles/globalStyleVars';
import {Img} from '../nzuac-image/Img';


const NewsList = ({title, cat, date, img, link}) => {


  return (

    <StyeldSection className={'slider_component_latest'}>

      <div className={'single_image_image'}>
        <div className="content_wrap">
          <Link to={link ? link : '/'}>
            <div className="single_image_image_wrp">
              <Img offset={10} src={img ? img : './images/dynamic/news/news-1.jpg'}/>
            </div>
            <div className="single_content_wrp">
              <div className="date">
                <p className="date_text split-up">{date ? date : '15 July 2020'}</p>
                <p className="cat split-up">{cat ? cat : 'Media'}</p>
              </div>
              <div className="title">
                <h3 className={'split-up'}>{title ? title : 'New Zealand Universities Introduce Exciting Scholarships!'}</h3>
              </div>
            </div>
          </Link>
        </div>
      </div>

    </StyeldSection>

  );

};

const StyeldSection = styled.section`
    overflow: hidden;
    @media (max-width: 767px) {
        .container-fluid {
            padding: 0 15px !important;
        }
    }


    .single_image_image {
        margin: 0;
        box-shadow: 0 3px 20px rgba(0, 0, 0, 0);
        background: ${white};

        .content_wrap {
            a {
                display: flex;
                flex-direction: column;

                .single_image_image_wrp {
                    overflow: hidden;

                    .global-image {
                        transition: 0.7s all ease;
                        transform: scale(1.01);
                    }
                }

                &:hover {
                    .single_image_image_wrp {
                        .global-image {
                            transform: scale(1.04);
                        }
                    }
                }
            }
        }

        .single_image_image_wrp {
            position: relative;
            height: 100%;
            width: 100%;
            padding-top: calc(260 / 370 * 100%)
        }

        .single_content_wrp {
            display: flex;
            flex-direction: column;
            padding: 30px 30px 70px;
            background: ${white};


            .date {

                margin-bottom: 30px;

                .cat {
                    margin: 0;
                    color: ${hover};
                    font-size: 12px;
                    line-height: 10px;
                    font-weight: 400;
                }

                .date_text {
                    color: ${text};
                    line-height: 20px;
                    font-size: 12px;
                    font-weight: 400;
                }
            }

            .title {

                h3 {
                    line-height: 24px;
                    font-size: 20px;
                    margin: 0;
                    font-weight: 500;
                    color: ${text};
                    min-height: 75px;
                }
            }
        }
    }
`;


export default React.memo(NewsList);