import React, {useEffect} from 'react';
// import LogoBig from '../nzuac-svg/LogoBig';
import gsap, {CSSPlugin} from 'gsap';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';
import LogoBig from '../nzuac-svg/LogoBig';

gsap.registerPlugin(DrawSVGPlugin, CSSPlugin);

const PageLoader = ({onEnterComplete, onExitComplete}) => {


  useEffect(() => {
    var hide = '.big-preloader';
    var logo_white = '.preloader-logo';

    var shapes = 'svg .first-load', tl = gsap.timeline({ repeat: 0, yoyo: true });
    tl.fromTo(shapes, { drawSVG: '0' }, { duration: 1.5, drawSVG: '100%', stagger: 0.2 })
      .to(shapes, { duration: 0.8, fill: 'white' }, '-=0.2');

    var shapesTw = 'svg .second-load', tl2 = gsap.timeline({ repeat: 0, yoyo: true });
    tl2.fromTo(shapesTw, { drawSVG: '0' }, { duration: 1.5, drawSVG: '100%', stagger: 0.2 })
      .to(shapesTw, { duration: 1, fill: 'white' }, '-=0.5')
      .to(logo_white, { duration: 1, alpha: 0, display:'none' }, '+=0.2')
      .to(hide, { duration: 1, height: '0' }, '-=0.2')
      .to(hide, { duration: 0.2, opacity: '0', display: 'none'}, '-=0.4');

  }, [null]);
  return (<div className="big-preloader">
    <LogoBig/>
  </div>);
};

export default PageLoader;
