import React from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import styled from 'styled-components';
import {hover} from '../../styles/globalStyleVars';

// Initialize ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

const GoBack = ({targetSection}) => {



  return (
    <StyledGoBack>
      <div className="button_wrapper">
        <div className="icon">
          <svg xmlns="http://www.w3.org/2000/svg" className={'svg_normal'} width="20" height="20" viewBox="0 0 20 20">
            <g id="Group_23883" data-name="Group 23883" transform="translate(3758 -14567) rotate(-90)">
              <circle id="Ellipse_3" data-name="Ellipse 3" cx="10" cy="10" r="10" transform="translate(-14587 -3738) rotate(-90)" fill="#a08750"/>
              <g id="Group_15708" data-name="Group 15708" transform="translate(-14580 -3746.5) rotate(-90)">
                <line id="Line_7" data-name="Line 7" y1="3" x2="3" transform="translate(0 3)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                <line id="Line_8" data-name="Line 8" x2="3" y2="3" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
              </g>
            </g>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" className={'svg_hover'} width="20" height="20" viewBox="0 0 20 20">
            <g id="Group_23883" data-name="Group 23883" transform="translate(3758 -14567) rotate(-90)">
              <circle id="Ellipse_3" data-name="Ellipse 3" cx="10" cy="10" r="10" transform="translate(-14587 -3738) rotate(-90)" fill="#fff"/>
              <g id="Group_15708" data-name="Group 15708" transform="translate(-14580 -3746.5) rotate(-90)">
                <line id="Line_7" data-name="Line 7" y1="3" x2="3" transform="translate(0 3)" fill="none" stroke="#a08750" strokeLinecap="round" strokeWidth="1"/>
                <line id="Line_8" data-name="Line 8" x2="3" y2="3" fill="none" stroke="#a08750" strokeLinecap="round" strokeWidth="1"/>
              </g>
            </g>
          </svg>

        </div>
        <div className="content">
          <p>Go back</p>
        </div>
      </div>
    </StyledGoBack>
  );
};
const StyledGoBack = styled.div`
        .button_wrapper{
          position: relative;
          cursor: pointer;
          display: flex;
          .icon{
            height: 20px;
            width: 20px;
            position: relative;
            overflow: hidden;
            background: #a08750;
            border-radius: 50%;
            margin-right: 20px;
          }
          p{
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            color: ${hover}
          }
          svg{
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            transition: 0.7s all ease;
            
          }
          .svg_hover{
            transform: translateY(50px);
          }
          .svg_normal{
            transform: translateY(0);
          }
          
          &:hover{
            .svg_hover{
              transform: translateY(0);
              
            }
            .svg_normal{
              transform: translateY(-50px);
              
            }
          }
        }
`;

export default GoBack;
