import React from 'react';
import styled from 'styled-components';

/**
 * Icon Right component
 * Pass the props with your data to the Icon Left component.
 **/
const Mail = () => {
  return (
    <StyledMail>
      <svg xmlns="http://www.w3.org/2000/svg" width="18.693" height="14" viewBox="0 0 18.693 14">
        <g id="Group_24274" data-name="Group 24274" transform="translate(0 -64.266)">
          <path id="Path_9565" data-name="Path 9565" d="M10.909,176.6a2.812,2.812,0,0,1-3.124,0L.124,171.49c-.042-.028-.084-.058-.124-.088v8.368a1.72,1.72,0,0,0,1.721,1.721H16.972a1.72,1.72,0,0,0,1.721-1.721V171.4c-.041.03-.082.06-.125.088Z" transform="translate(0 -103.225)" fill="#fff"/>
          <path id="Path_9566" data-name="Path 9566" d="M.732,67.354l7.66,5.107a1.716,1.716,0,0,0,1.909,0l7.66-5.107a1.64,1.64,0,0,0,.732-1.368,1.722,1.722,0,0,0-1.72-1.72H1.72A1.722,1.722,0,0,0,0,65.987a1.64,1.64,0,0,0,.732,1.367Z" fill="#fff"/>
        </g>
      </svg>
    </StyledMail>
  );
};

/**
 * Styled component for Icon Right Single
 * Add your styles for  Icon Right component here.
 **/

const StyledMail = styled.div`
    
  display: inline-block;
`;

export default React.memo(Mail);
