import React, {useEffect, useState} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import styled from 'styled-components';
import {hover} from '../../styles/globalStyleVars';

// Initialize ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

const CallButton = ({targetSection}) => {
  const [offset, setOffset] = useState(90);

  useEffect(() => {
    const updateOffset = () => {
      if (window.innerWidth > 767) {
        const containerElement = document.querySelector('.container');
        if (containerElement) {
          setOffset(containerElement.offsetLeft + 15);
        }
      }
    };

    updateOffset();

    // Update the offset when the window is resized
    window.addEventListener('resize', updateOffset);

    return () => {
      window.removeEventListener('resize', updateOffset);
    };
  }, []);


  return (
    <StyledCallButton>
      <div className="call-button"
        style={{
          cursor: 'pointer',
          position: 'fixed',
          bottom: '40px',
          zIndex: 9999,
          right: `${offset - 50}px`
        }}

      >
        <a href={'http://m.me/PAGE-NAME'} target={'_blank'} rel={'noreferrer'}>


          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" className={'svg-normal'} viewBox="0 0 50 50">
            <g id="Hover" transform="translate(-1292 -670)">
              <circle id="Base" cx="25" cy="25" r="25" transform="translate(1292 670)" fill={hover}/>
              <g id="Messenger_Icon" data-name="Messenger Icon" transform="translate(1304 682.5)">
                <path id="Path_8948" data-name="Path 8948" d="M5.194,25a2.02,2.02,0,0,1-1.059-.3A1.982,1.982,0,0,1,3.179,23v-2.93A12.26,12.26,0,0,1,.613,10.718,12.529,12.529,0,0,1,21.761,3.554a12.32,12.32,0,0,1,3.66,10.265A12.507,12.507,0,0,1,8.1,23.8l-2,.991a2.032,2.032,0,0,1-.9.213ZM11.568,1.863A10.745,10.745,0,0,0,2.383,10.95a10.519,10.519,0,0,0,2.376,8.238.882.882,0,0,1,.206.566V23a.22.22,0,0,0,.109.194A.225.225,0,0,0,5.3,23.2l2.378-1.179a.9.9,0,0,1,.779-.01,10.719,10.719,0,0,0,15.191-8.39,10.567,10.567,0,0,0-3.137-8.806,10.76,10.76,0,0,0-8.941-2.955ZM11.454.984h0Zm0,0" transform="translate(0 0)" fill="#fff"/>
                <path id="Path_8949" data-name="Path 8949" d="M126.887,188.375l4.1-5.787a.574.574,0,0,1,.84-.172l2.746,2.043a.576.576,0,0,0,.637.033l3.343-2.345a.57.57,0,0,1,.806.74l-3.31,5.681a.574.574,0,0,1-.806.233l-3.665-2.182a.576.576,0,0,0-.55-.021l-3.387,2.566a.569.569,0,0,1-.752-.789Zm0,0" transform="translate(-120.092 -173.173)" fill="#fff"/>
              </g>
            </g>
          </svg>

          <svg className={'svg-hover'} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
            <g id="Hover" transform="translate(-1292 -670)">
              <circle id="Base" cx="25" cy="25" r="25" transform="translate(1292 670)" fill="#fff"/>
              <g id="Messenger_Icon" data-name="Messenger Icon" transform="translate(1304 682.5)">
                <path id="Path_8948" data-name="Path 8948" d="M5.194,25a2.02,2.02,0,0,1-1.059-.3A1.982,1.982,0,0,1,3.179,23v-2.93A12.26,12.26,0,0,1,.613,10.718,12.529,12.529,0,0,1,21.761,3.554a12.32,12.32,0,0,1,3.66,10.265A12.507,12.507,0,0,1,8.1,23.8l-2,.991a2.032,2.032,0,0,1-.9.213ZM11.568,1.863A10.745,10.745,0,0,0,2.383,10.95a10.519,10.519,0,0,0,2.376,8.238.882.882,0,0,1,.206.566V23a.22.22,0,0,0,.109.194A.225.225,0,0,0,5.3,23.2l2.378-1.179a.9.9,0,0,1,.779-.01,10.719,10.719,0,0,0,15.191-8.39,10.567,10.567,0,0,0-3.137-8.806,10.76,10.76,0,0,0-8.941-2.955ZM11.454.984h0Zm0,0" transform="translate(0 0)" fill="#a08750"/>
                <path id="Path_8949" data-name="Path 8949" d="M126.887,188.375l4.1-5.787a.574.574,0,0,1,.84-.172l2.746,2.043a.576.576,0,0,0,.637.033l3.343-2.345a.57.57,0,0,1,.806.74l-3.31,5.681a.574.574,0,0,1-.806.233l-3.665-2.182a.576.576,0,0,0-.55-.021l-3.387,2.566a.569.569,0,0,1-.752-.789Zm0,0" transform="translate(-120.092 -173.173)" fill="#a08750"/>
              </g>
            </g>
          </svg>


        </a>
      </div>
    </StyledCallButton>
  );
};
const StyledCallButton = styled.div`

    .call-button {
        height: 50px;
        width: 50px;
        overflow: hidden;
        border-radius: 50%;
        position: relative;
        background: ${hover};
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
        @media (min-width: 1550px) {
            right: 60px !important;
        }

        @media (max-width: 992px) {
            right: 15px !important;
        }

        a {
            height: 50px;
            width: 50px;
            overflow: hidden;
            border-radius: 50%;
            position: relative;
            display: block;

            svg {
                position: absolute;
                inset: 0;
                height: 50px;
                width: 50px;

                &.svg-normal {
                    transform: translateY(0);
                    transition: 0.5s all ease;
                }

                &.svg-hover {
                    transform: translateY(60px);
                    transition: 0.5s all ease;
                }
            }

            &:hover {
                .svg-normal {
                    transform: translateY(-60px);

                }

                .svg-hover {
                    transform: translateY(0);

                }
            }
        }
    }
`;

export default CallButton;
