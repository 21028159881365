import {gsap, TimelineLite} from 'gsap';
import React, {useEffect, useRef} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {coffe, hover, text, white} from '../../styles/globalStyleVars';
import Social from '../../components/nzuac-social-icons/Social';
import BsLeft from '../../components/nzuac-svg/BsLeft';
import BsDown from '../../components/nzuac-svg/BsDown';
import ButtonIcon from '../../components/nzuac-buttons/ButtonIcon';
import SearchButton from '../../components/nzuac-buttons/SearchButton';
import ApplyNow from '../../components/nzuac-svg/ApplyNow';
import CloseIconMobile from '../../components/nzuac-svg/CloseIconMobile';
import HamburgerMobile from '../../components/nzuac-svg/HamburgerMobile';
import Accordion from 'react-bootstrap/Accordion';
import LazyLoad from 'react-lazyload';
import CloseIconSearch from '../../components/nzuac-svg/CloseIconSearch';
import LandingFilterForm from '../../components/nzuac-form/LandingFilterForm';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {fetchPostsChildFilter} from '../../api/redux/home';
import {useDispatch, useSelector} from 'react-redux';


/**
 *  Mobile Menu component
 *  Pass the props with your data for Mobile Menu
 **/
const MobileMenu = ({data}) => {
  /**
   * variables
   * Change / add your custom variable
   **/
  const mobileMenuRaf = useRef(null);
  const menuRef = useRef(null);
  // const [scrollDirection, setScrollDirection] = useState('down');
  // const [showSubmenu, setShowSubmenu] = useState(null);
  const targetElementRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();



  useEffect(() => {
    let api_filter = apiEndPoints.FILTER;
    dispatch(fetchPostsChildFilter([api_filter]));
  }, [dispatch, location]);

  let getPost = useSelector(state => state.home);
  let filter2 = getPost?.filter?.filter;










  useEffect(() => {
    targetElementRef.current = document.querySelector('.show');
    /**
     * on scroll menu fixed action
     * Change / add your custom variable
     **/
    if (document.body.classList.contains('scroll-down')) {
      document.body.classList.remove('scroll-down');
      document.body.classList.remove('mobile-menu-open');
    }

    if (document.body.classList.contains('menu-is-open')) {
      document.body.classList.remove('scroll-down');
      document.body.classList.remove('scroll-up');
    }
  }, [mobileMenuRaf]);


  /**
   * sticky menu
   * Change / add your custom variable
   **/
  useEffect(() => {
    const body = document.body;
    const scrollUp = 'scroll-up';
    const scrollDown = 'scroll-down';
    let lastScroll = 0;
    let howMuchScroll = 150;
    if (window.screen.width < 991) {
      howMuchScroll = 0;
    } else {
      howMuchScroll = 150;
    }

    window.addEventListener('scroll', () => {
      let currentScroll = window.pageYOffset;

      if (currentScroll <= howMuchScroll) {
        body.classList.remove(scrollUp);
        return;
      }
      if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
      } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
      }
      lastScroll = currentScroll;
    });
  }, [mobileMenuRaf,menuRef]);


  // new menu action
  useEffect(() => {
    var tl = new TimelineLite();
    var tl2 = new TimelineLite();
    document.querySelector('.hamburger').addEventListener('click', function (e) {
      e.stopPropagation();
      if (!document.body.classList.contains('menu-is-open')) {
        tl.to('.mobile-menu__items', 0, {
          display: 'block',
        }).to('.mobile-menu__items', 0.5, {
          x: 0,
          autoAlpha: 1,
          ease: 'Expo.easeInOut'
        });
        document.body.classList.add('menu-is-open');
        document.body.classList.add('mobile-menu-open');
        document.body.classList.add('stop-scroll');
        document.querySelector('.hamburger').classList.add('menu-open');
        document.querySelector('html').classList.add('menu-is-open');
        document.querySelector('html').classList.add('mobile-menu-open');
        document.querySelector('.applyNow')?.classList.add('mobile-menu-open');
      } else {
        tl2.to('.mobile-menu__items', 1, {
          x: '100%',
          autoAlpha: 0,
          ease: 'Expo.easeInOut'
        }).to('.mobile-menu__items', 0, {
          display: 'none',
        }, '-=.3');
        document.body.classList.remove('menu-is-open');
        document.body.classList.remove('mobile-menu-open');
        document.body.classList.remove('stop-scroll');
        document.querySelector('.hamburger').classList.remove('menu-open');
        document.querySelector('html').classList.remove('menu-is-open');
        document.querySelector('.contact-open').classList.remove('sub-menu-open');
        document.querySelector('.applyNow').classList.remove('mobile-menu-open');
      }

    });

    let getLinkTag = document.querySelectorAll('.mobile-menu__items__ul li a');

    for (let i of getLinkTag) {
      i.addEventListener('click', () => {
        menuRef.current.style.cssText = 'opacity:0';
        document.body.classList.remove('mobile-menu-open');
        document.querySelector('html').classList.remove('mobile-menu-open');
        document.querySelector('.hamburger').classList.remove('menu-open');
      });
    }

  }, []);

  const handleContact = (e) => {
    document.querySelector('.contact-open')?.classList.add('sub-menu-open');
  };


  const handleContactClose = (e) => {
    document.querySelector('.contact-open')?.classList.remove('sub-menu-open');
  };
  const handleContactCloseMenu = (e) => {
    menuRef.current.style.cssText = 'opacity:0';
    mobileMenuRaf.current.classList.remove('menu-open');
    document.querySelector('.hamburger').classList.remove('menu-open');
  };



  const searchClickRef = useRef();
  const searchItemRef = useRef();
  useEffect(() => {
    // Ensure searchClickRef is valid
    if (!searchClickRef || !searchClickRef.current) return;

    // Function to handle click event for showing/hiding search
    const handleClick = (e) => {
      const searchItem = searchItemRef.current;
      // Toggle overflow of smooth content
      // Toggle search open/close classes and animations
      if (!searchItem.classList.contains('search-open')) {
        searchItem.classList.add('search-open');
        document.querySelector('body').classList.add('search-open');
        gsap.to(searchItem, {
          display: 'flex',
          duration: 0.5,
          height: '100vh',
          opacity: 1,
          overflow: 'visible'
        });
        gsap.to(searchItem.querySelector('.container'), {
          opacity: 1,
          delay: 0.2,
        });
      } else {
        searchItem.classList.remove('search-open');
        gsap.to(searchItem, {
          duration: 0.4,
          height: 0,
          opacity: 0,
          overflow: 'hidden'
        });
        gsap.to(searchItem.querySelector('.container'), {
          opacity: 0,
        }, '-=0.4');
        gsap.to(searchItem, {
          display: 'none'
        });
      }
    };

    // Add click event listener to show/hide search
    searchClickRef.current.addEventListener('click', handleClick);

    // Clean up function to remove event listener
    return () => {
      if (searchClickRef.current) {
        searchClickRef.current.removeEventListener('click', handleClick);
      }
    };
  }, [searchClickRef]); // Dependency array with searchClickRef

  // Add event listeners to close search when clicking on close button or link
  useEffect(() => {
    const handleCloseClick = () => {
      const searchItem = searchItemRef.current;
      searchItem.classList.remove('search-open');
      document.querySelector('body').classList.remove('search-open');

      gsap.to(searchItem, {
        duration: 0.4,
        height: 0,
        overflow: 'hidden'
      });
      gsap.to(searchItem.querySelector('.container'), {
        opacity: 0,
      }, '-=0.4');
      gsap.to(searchItem, {
        display: 'none'
      });
    };
    // Add event listeners to close search
    const closeButton = document.querySelector('.search-desktop svg');
    if (closeButton) {
      closeButton.addEventListener('click', handleCloseClick);
    }
    const closeLink = document.querySelector('.search-desktop a');
    if (closeLink) {
      closeLink.addEventListener('click', handleCloseClick);
    }

    // Clean up function to remove event listeners
    return () => {
      if (closeButton) {
        closeButton.removeEventListener('click', handleCloseClick);
      }
      if (closeLink) {
        closeLink.removeEventListener('click', handleCloseClick);
      }
    };
  }, [searchItemRef]); // Dependency array with searchItemRef



  return (
    <>
      <StyledMobileMenu className={'Mobile-menu-wrap menu-bar '}>
        <img src="/images/static/leaf.svg" className={'fixed-bg-img mobile-none'} alt=""/>
        {/*<img src="/images/static/leaf-mobile.svg" className={'fixed-bg-img mobile'} alt=""/>*/}

        <Container>
          <Row className="mobile-menu" ref={mobileMenuRaf}>
            {/*logo*/}
            <Col className="logo forNormal">
              <Link to={'/'}>
                <img src={'/images/static/logo.svg'} alt={'logo'}/>
              </Link>
            </Col>

            {/*close icon*/}
            <Col className="logo forClose">
              <Link to={'/'}>
                <img src={'/images/static/logo.svg'} alt={'logo'}/>
              </Link>
            </Col>


            <Col className={'button-wrapper-ok'}>
              <div className={'applyNow'}>
                <Link to={'/'}>
                  <ApplyNow/>
                </Link>

              </div>
              <div className="hamburger">
                <span className={'mobile-sticky'}>
                  <HamburgerMobile/>
                </span>
                <span className={'close-icon'}>
                  <CloseIconMobile/>
                </span>
              </div>
            </Col>
            <div className="mobile-menu__items" ref={menuRef}>

              <div className="button-wrapper">
                <div className="search-button" ref={searchClickRef}>
                  <SearchButton borderColor={white} background={'transparent'} icon={true}
                    text={'Search any course'}/>
                </div>
                <div className="apply-button">
                  <ButtonIcon src={'/apply-now'} iconSVG text={'Apply Now'} hoverTextcolor={text}
                    borderColor={coffe}
                    background={coffe}/>
                </div>
                <div className="contact-button">

                  <ButtonIcon color={white} text={'Contacts'} hoverTextcolor={white}
                    borderColor={white}
                    background={'transparent'} hoverBackground={hover} onClick={() => handleContact()}/>
                  <ul className={'submenu contact-button contact-open'}>
                    <div className="title" onClick={() => handleContactClose()}>
                      <BsLeft/>
                      Contact
                    </div>
                    <li className={'main'}>
                      <Link to={'/contacts'} onClick={() => handleContactCloseMenu()}>
                        Contact Page
                        <BsDown/>
                      </Link>
                    </li>

                    <div className="email">
                      <p>Email</p>
                      <a href="mailto:placeholder@email.com">placeholder@email.com</a>
                    </div>
                    <div className="phone email">
                      <p>Call</p>
                      <a href="tel:+880 1400 999 666">placeholder@email.com</a>
                    </div>
                    <div className="follow email">
                      <p>Follow</p>
                      <Social bg={hover} iconColor={white}/>
                    </div>
                  </ul>
                </div>

              </div>

              <ul className="mobile-menu__items__ul">
                <li>
                  <Link to={'/'}>Home</Link>
                </li>

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Study in New Zealand <BsDown/></Accordion.Header>
                    <Accordion.Body>

                      <ul className={'submenu'}>
                        <li>
                          <Link to={'/university'}>
                            Universities
                          </Link>
                        </li>

                        <li>
                          <Link to={'/student-life'}>
                            Student Life
                          </Link>
                        </li>
                        <li>
                          <Link to={'/scholarship'}>
                            Scholarships
                          </Link>
                        </li>
                        <li>
                          <Link to={'/english-proficiency-test'}>
                            English Proficiency Test
                          </Link>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>


                  <li>
                    <Link to={'/about'}>
                      About NZUAC
                    </Link>
                  </li>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Our Partners <BsDown/></Accordion.Header>
                    <Accordion.Body>

                      <ul className={'submenu'}>
                        <li>
                          <Link to={'/our-partners'}>
                            Our Partners
                          </Link>
                        </li>

                        <li>
                          <Link to={'/partner'}>
                            Become a Partner Agent
                          </Link>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>

                  <li>
                    <Link to={'/news'}>
                      News & Events
                    </Link>
                  </li>
                </Accordion>
              </ul>

            </div>

          </Row>
        </Container>


        <div className="search-desktop" ref={searchItemRef}>
          <LazyLoad>
            <img src="./images/static/leaf.svg" className={'fixed-bg-img mobile-none'} alt=""/>
          </LazyLoad>
          <LazyLoad>
            <img src="./images/static/leaf-mobile.svg" className={'fixed-bg-img mobile'} alt=""/>
          </LazyLoad>
          <a className={'search-close-icon'}>
            <CloseIconSearch/>
          </a>
          <Container>

            <Row>
              <Col md={{span: 10, offset: 1}}>
                <p>Select any course from hundreds of education institutions</p>
                <LandingFilterForm data={filter2}/>

              </Col>
            </Row>
          </Container>
        </div>

      </StyledMobileMenu>

      <StyleSlide className={'menu-list'}>

      </StyleSlide>
    </>
  );
};

/**
 *  Styled component for Mobile Menu
 *  Add your styles for Mobile Menu component here
 **/
const StyledMobileMenu = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 90px;
  z-index: 99999;
  display: flex;
  align-items: center;
  transition: all 0.4s cubic-bezier(0.4, 0, 0, 1);
  overflow: visible !important;
  background: rgba(4, 5, 4, 85%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.20);

  .fixed-bg-img {
    position: absolute;
    z-index: 1;
    right: 0px;
    bottom: 0px;
    width: auto;
    height: auto;
    display: inline-block;

    //&.mobile-none {
    //  display: block;
    //  @media (max-width: 767px) {
    //    display: none;
    //  }
    //}
  }


  //container
  //search

  .search-desktop {
    //height: 195px;
    background-color: ${text};
    //display: flex;
    align-content: center;
    flex-wrap: wrap;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    height: 0;
    display: none;
    overflow: hidden;
    z-index: 999999;
    box-shadow: 0 1px 6px rgb(0 0 0 / 10%);

    p{
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color:white;
      text-align: center;
      margin-bottom: 30px;
    }
    .container {
      position: relative;
      opacity: 0;
      z-index: 999;
      overflow: visible !important;
    }

    .fixed-bg-img {
      position: absolute;
      z-index: 1;
      right: 0px;
      bottom: 0px;
      width: auto;
      height: auto;
      display: inline-block;

      &.mobile {
        display: none;
        @media (max-width: 767px) {
          display: block;
        }
      }

      &.mobile-none {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
    }

    .search-close-icon {
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: 10;
    }

    form {
      min-width: 100%;

      .search-input {
        position: relative;

        button, p, a {
          box-shadow: none;
          border: none;
          background-color: transparent;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: fit-content;
          font-size: 12px;
          line-height: 18px;
          color: #221f1f;
          transition: color .3s ease;
          cursor: pointer;

          &:hover {
            color: ${hover};
          }
        }

        span {
          position: absolute !important;
          bottom: 18px;
        }

        .form-control {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #221F1F;
          padding-left: 48px;
          padding-bottom: 9px;
          padding-top: 0;
          height: 50px;
          font-size: 32px;
          line-height: 36px;
          font-weight: 600;
          color: rgba(34, 31, 31, 0.20);
          background-color: #F9F9F9;

          ::placeholder {
            font-size: 32px;
            line-height: 36px;
            font-weight: 600;
            color: rgba(34, 31, 31, 0.20);
          }
        }

      }
    }

    &__menu {
      margin-top: 23px;
      min-width: 100%;

      p {
        font-size: 12px;
        color: ${hover};
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 13px;

      }

      ul {
        display: inline-flex;

        li {
          a {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: #221f1f;
            display: flex;
            margin-right: 30px;
          }

          &:nth-last-child(1) {
            a {
              margin-right: 0;
            }
          }
        }
      }

    }

  }

  .container {
    z-index: 4;
    background-color: transparent;
    overflow: hidden;
    transition: all 1.2s cubic-bezier(0.4, 0, 0, 1) 0s;
  }

  //menu open

  &.menu-open {
    transform: unset !important;
    transition: unset;

    .container {
      background-color: ${text};
      overflow: hidden;
      transition: all 1.2s cubic-bezier(0.4, 0, 0, 1) 0s;
      @media (min-width: 768px) and (max-width: 991px) {
        max-width: 100% !important;
        padding-left: 7%;
        padding-right: 7%;
      }
    }

    .forNormal {
      display: none !important;
    }

    .forClose {
      display: flex !important;
    }
  }

  //menu bar

  .menu-bar {
    background-color: transparent !important;
    box-shadow: unset !important;
  }

  //close icon

  .forClose {
    display: none !important;

    a {
      img {
        height: 50px !important;
      }
    }
  }

}

//mobile menu

.mobile-menu {
  .main_child a svg {
    z-index: -1;
  }

  .mobile-menu__items {
    overflow-x: hidden;
    overflow-y: scroll;

    .button-wrapper {
      margin-bottom: 50px;

      .dc-btn {
        width: 100%;
        text-align: center;
      }

      .search-button {

        .button-wrapper {
          margin-bottom: 20px;
          justify-content: center !important;
        }
      }

      .apply-button {
        margin-bottom: 20px;
      }
    }
  }

  height: 90px;

  .logo {
    display: flex;
    align-items: center;

    span {
      width: 220px !important;
    }

    a {
      img {
        width: 130px;
        height: 50px;
      }
    }
  }

  .button-wrapper-ok {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .applyNow{
      display: block;
      &.mobile-menu-open{
        display: none;
      }
    }
  }

  .hamburger {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 20px;

    span {

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: ${white};
        margin: 0 10px 0 0;
      }
    }
    .mobile-sticky {
      display: block !important;
    }
    .close-icon {
      display: none !important;
    }

    &.menu-open {
      .mobile-sticky {
        display: none !important;
      }

      .close-icon {
        display: block !important;
      }
    }

    // &__items {
    //   display: none;
    //   transform: translateX(100%);
    //   position: absolute;
    //   opacity: 0;
      //   background-color: ${text};
    //   height: calc(100vh - 90px);
    //   top: 90px;
    //   left: 0;
    //   width: 100%;
    //   z-index: 99;
    //   padding: 55px 15px 60px 15px;
    //   overflow: hidden;
    //   //transition: opacity 0.1s cubic-bezier(0.4, 0, 0, 1);
    //
    //   &:after {
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     top: 0;
      //     background: ${white};
    //     height: 1px;
    //     content: '';
    //     margin: 0 auto;
    //     width: 100%;
    //   }
    //
    //   &__top {
    //     ul {
    //       li {
    //         a {
    //           height: 45px;
    //           border-radius: 25px;
      //           background-color: ${hover};
      //           color: ${white};
    //           font-size: 18px;
    //           font-weight: 400;
    //           width: 100%;
    //           display: flex;
    //           align-items: center;
    //           justify-content: flex-start;
    //           padding: 0 30px;
    //           text-transform: capitalize;
    //
    //           margin-bottom: 30px;
    //
    //           img {
    //             margin-right: 10px;
    //           }
    //
    //           span {
    //             margin-right: 10px !important;
    //           }
    //         }
    //
    //         &:nth-last-child(1) a {
    //           margin-bottom: 60px;
    //           background-color: #4f616b;
    //
    //           &:hover {
      //             color: ${white} !important;
    //           }
    //         }
    //       }
    //     }
    //   }
    //
    //   &__ul {
    //     li {
    //       padding-bottom: 25px;
    //       border-bottom: 1px solid white;
    //       margin-bottom: 25px;
    //
    //       &:nth-last-child(1) {
    //         border-bottom: 0;
    //       }
    //
    //       a {
      //         color: ${white};
    //         font-weight: 500;
    //         font-size: 20px;
    //         line-height: 20px;
    //         text-transform: capitalize;
    //       }
    //     }
    //   }
    // }

    &.menu-open {
      .mobile-menu__items {
        display: block;
      }
    }

    .main-child-sub {
      a {
        display: flex;
        justify-content: space-between;
      }
    }

    //accordion

    .accordion-item {
      .accordion-header {
        .accordion-button {
          background-color: transparent;
          color: ${white};
          font-weight: 500;
          font-size: 20px;
          line-height: 20px;
          text-transform: capitalize;
          width: 100%;
          text-align: left;
          display: flex;
          justify-content: space-between;
          padding: 0;
          box-shadow: none;
          border: none;
          margin-bottom: 20px;
          padding-bottom: 25px;
          border-bottom: 1px solid ${white};

          p {
            margin: 0;
            font-size: 22px;
            line-height: 28px;
            text-transform: capitalize;
            font-weight: bold;
            padding-right: 30px;
            width: 100%;
          }

          span {
            &:nth-last-child(2) {
              display: none !important;
            }

            &:nth-last-child(1) {
              display: block !important;
            }
          }

          &.collapsed {
            span {
              &:nth-last-child(2) {
                display: block !important;
              }

              &:nth-last-child(1) {
                display: none !important;
              }
            }
          }

          &.collapsed {
            border-bottom: 1px solid ${white};
          }
        }
      }

      .accordion-body {
        padding-bottom: 25px;
        padding-top: 20px;

        ul {
          li {
            border: none;
            padding-bottom: 0;
            margin-bottom: 0;

            a {
              font-size: 16px;
              line-height: 22px;
              font-weight: 600;
              margin-bottom: 15px;
              display: block;
            }

            &:after {
              display: none;
            }
          }
        }
      }

      &:nth-last-child(1) {
        .accordion-button {
          //border: none !important;
        }
      }
    }

    .social {
      &__icon {
        position: absolute;
        bottom: unset;
        display: flex;
        z-index: -1;

        &__icons {
          margin-right: 20px;
        }
      }
    }

    .main_child {
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;
      overflow: hidden;

      .title {
        background-color: transparent;
        color: ${white};
        font-weight: 500 !important;
        font-size: 20px !important;
        line-height: 20px !important;
        text-transform: capitalize;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding: 0;
        box-shadow: none;
        border: none;
        margin-bottom: 20px;
        padding-bottom: 25px;
        border-bottom: 1px solid ${white};

        @media (max-width: 767px) {
          img {
            z-index: -1;
            position: relative;
          }
        }


        svg {
          transform: rotate(90deg);
        }

        &:hover {
          color: ${white} !important;
          opacity: 70%;
        }

        p {
          margin: 0;
          font-size: 22px;
          line-height: 28px;
          text-transform: capitalize;
          font-weight: bold;
          padding-right: 30px;
          width: 100%;
        }

        span {
          &:nth-last-child(2) {
            display: none !important;
          }

          &:nth-last-child(1) {
            display: block !important;
          }
        }

        &.collapsed {
          span {
            &:nth-last-child(2) {
              display: block !important;
            }

            &:nth-last-child(1) {
              display: none !important;
            }
          }
        }

        &.collapsed {
          border-bottom: 1px solid ${white};
        }
      }

      &:last-child {
        a {
          margin-bottom: 0;
        }
      }

      a {
        background-color: transparent;
        color: ${white};
        font-weight: 500 !important;
        font-size: 20px !important;
        line-height: 20px !important;
        text-transform: capitalize;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding: 0;
        box-shadow: none;
        border: none;
        margin-bottom: 20px;
        padding-bottom: 25px;
        border-bottom: 1px solid ${white};

        @media (max-width: 767px) {
          img {
            z-index: -1;
            position: relative;
          }
        }

        &:hover {
          color: ${white} !important;
          opacity: 70%;
        }

        p {
          margin: 0;
          font-size: 22px;
          line-height: 28px;
          text-transform: capitalize;
          font-weight: bold;
          padding-right: 30px;
          width: 100%;
        }

        span {
          &:nth-last-child(2) {
            display: none !important;
          }

          &:nth-last-child(1) {
            display: block !important;
          }
        }

        &.collapsed {
          span {
            &:nth-last-child(2) {
              display: block !important;
            }

            &:nth-last-child(1) {
              display: none !important;
            }
          }
        }

        &.collapsed {
          border-bottom: 1px solid ${white};
        }
      }

      &.submenu-open-mb {
        .title {
          color: ${hover} !important;
          border-color: ${hover} !important;

          svg {
            transform: rotate(270deg);

            circle {
              fill: ${hover};
              stroke: ${hover};
            }

          }
        }

        .submenu {
          opacity: 1;
          visibility: visible;
          height: auto;
          margin: 25px 0;
          transform: translateY(0);
          width: 100% !important;

          a {
            font-size: 16px !important;
            font-weight: 400 !important;
            line-height: 20px !important;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          }

          &.sub-menu-open {
            opacity: 1;
            visibility: visible;
            height: auto;


          }
        }

        .submenu-open-next {
          .mega_sub {
            opacity: 1;
            visibility: visible;
            height: auto;
            margin: 25px auto 50px;
            transform: translateY(0);


          }
        }

        .submenu-open-next-next {
          .mega_sub_sub {
            opacity: 1;
            visibility: visible;
            height: auto;
          }
        }
      }

      .submenu {
        margin: 0 auto 0;
        position: relative;
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        background: ${text};
        width: 100%;
        opacity: 0;
        height: 0;
        visibility: hidden;
        transition: 0.4s all cubic-bezier(0.4, 0, 0, 1);
        transform: translateY(-100px);
        overflow-x: scroll;

        &.sub-menu-open {
          opacity: 1;
          visibility: visible;
          height: auto;
          transform: translateY(0);
          margin: 0 auto 50px;


        }

        &::-webkit-scrollbar {
          display: none;
        }

        .main_title {
          font-size: 20px;
          line-height: 24px;
          text-align: left;
          display: flex;
          color: ${white};
          margin-bottom: 60px;
          font-weight: 400;

          svg {
            margin-right: 20px;
            position: relative;
            z-index: -1;
          }
        }

        li {
          border: none;
          padding-bottom: 0;
          margin-bottom: 0;

          &:last-child {
            a {
              margin-bottom: 0 !important;
              padding-bottom: 0 !important;
              border-bottom: none !important;
            }
          }

          a {
            color: ${white};
            font-size: 16px !important;
            line-height: 20px !important;
            font-weight: 500 !important;
            margin-bottom: 25px !important;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;

            //display: block;
          }

          &:after {
            display: none;
          }
        }

        .mega_sub {
          padding: 20px 15px 30px;
          position: fixed;
          right: 0;
          top: 0px;
          left: 0;
          bottom: 0;
          background: ${white};
          width: 100%;
          opacity: 0;
          visibility: hidden;
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
          transform: translateX(100%);
          height: 185vh;

          &:hover {
            //color: #4F616B;
          }
        }

        .mega_sub_sub {
          padding: 20px 15px 30px;
          position: fixed;
          right: 0;
          top: 0px;
          left: 0;
          bottom: 0;
          background: ${text};
          z-index: 999;
          width: 100%;
          opacity: 0;
          visibility: hidden;
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
          transform: translateX(100%);
        }
      }

      .fotter_menu {
        margin-top: 150px;

        h3 {
          font-size: 32px;
          color: ${white};
          line-height: 32px;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: ${white};
        }

        .footer {
          margin-top: 40px;

          a {
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
            color: ${white};
            padding: 0;
            display: block;
            margin: 0 0 10px;
            border: none;

            &:last-child {
              margin: 0;
            }
          }
        }
      }

      &:nth-last-child(1) {
        .accordion-button {
          //border: none !important;
        }
      }
    }
  }

  //menu bottom

  .mobile-bottom-bar__buttons {
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  //menu bottom bar

  .mobile-bottom-bar__account {
    position: fixed;
    width: 100%;
    padding: 110px 20px 60px 20px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${white};
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
    visibility: hidden;
    transform: translateY(100%);
    transition: all 0.6s cubic-bezier(0.87, 0, 0.13, 1);

    svg {
      position: absolute;
      right: 13px;
      top: 50px;
      font-size: 40px;
      color: black;
    }

    p {
      font-size: 16px;
      color: #d0ca10;
      line-height: 22px;
      margin-bottom: 40px;
      font-weight: 600;
    }

    ul {
      width: 100%;

      li {
        a {
          display: flex;
          font-size: 12px;
          line-height: 18px;
          font-weight: 600;
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          margin-bottom: 20px;
        }

        &:nth-last-of-type(1) {
          a {
            margin-bottom: 0;
            border-bottom: 0;
          }
        }
      }
    }

    &.open {
      transform: none;
      visibility: visible;
    }
  }

  //sub menu styel

  .has-child-sub.open_submenu > a svg {
    opacity: 1;
    right: 0;
  }

  //sub menu hover

  .has-child-sub-sub a:hover {
    svg {
      opacity: 1 !important;
    }
  }

  @media (min-width: 992px) {
    display: none;
  }
  
  .mobile-menu__items {
    display: none;
    transform: translateX(100%);
    position: absolute;
    opacity: 0;
    background: #040504;
    height: calc(-90px + 100vh);
    top: 90px;
    left: 0;
    width: 100%;
    z-index: 9999;
    padding: 55px 15px 60px 15px;
    overflow: hidden scroll;
    transition: opacity 0.1s cubic-bezier(0.4, 0, 0, 1);

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      background: ${white};
      height: 1px;
      content: '';
      margin: 0 auto;
      width: 100%;
    }

    &__top {
      ul {
        li {
          a {
            height: 45px;
            border-radius: 25px;
            background-color: ${hover};
            color: ${white};
            font-size: 18px;
            font-weight: 400;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0 30px;
            text-transform: capitalize;

            margin-bottom: 30px;

            img {
              margin-right: 10px;
            }

            span {
              margin-right: 10px !important;
            }
          }

          &:nth-last-child(1) a {
            margin-bottom: 60px;
            background-color: #4f616b;

            &:hover {
              color: ${white} !important;
            }
          }
        }
      }
    }

    &__ul {
      margin-top: 50px;
      li {
        padding-bottom: 25px;
        border-bottom: 1px solid white;
        margin-bottom: 25px;

        &:nth-last-child(1) {
          border-bottom: 0;
        }

        a {
          color: ${white};
          font-weight: 500;
          font-size: 20px;
          line-height: 20px;
          text-transform: capitalize;
        }
      }
      .accordion-header {
        .accordion-button {
          background-color: transparent;
          font-size: 20px;
          font-weight: 500;
          line-height: 20px;
          color: #A08750;
          width: 100%;
          text-align: left;
          display: flex;
          justify-content: space-between;
          padding: 0;
          box-shadow: none;
          border: none;
          margin-bottom: 32px;
          padding-bottom: 16px;
          border-bottom: 1px solid #CFCDCC;

          img {
            height: 20px;
          }
          svg{
            transform: rotate(-90deg);
            #Ellipse_3{
              fill: #A08750;
            }
          }

          &.collapsed {
            border-bottom: 1px solid #FFF;
            color: #FFFFFF;
            svg{
              transform: rotate(90deg);
              #Ellipse_3{
                fill: transparent;
              }
            }
          }
          
          
        }
      }
      .accordion-body{
        .submenu{
          li{
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            a{
              font-size: 16px;
            }
          }
        }
      }
    }
    
    .contact-button {
      .dc-btn{
        a{
          border: 1px solid #FFFFFF;
        }
      }
    .submenu.contact-button {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      background: ${text};
      z-index: 999;
      padding: 60px 15px;
      transition: 0.7s all ease;
      transform: translateX(100%);
      &.sub-menu-open{
        transform: translateX(0);
      }

      &.sub-menu-open {
        transform: translateX(0);
        overflow: hidden;
      }

      .title {
        display: flex;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: ${hover};
        align-items: center;
        margin-bottom: 60px;


        svg {
          margin-right: 15px;

          circle {
            fill: ${hover};
          }

          line {
            fill: ${white};
            stroke: ${white};
          }
        }
      }

      li.main {
        margin-bottom: 25px;

        a {
          display: flex;
          font-size: 20px;
          color: ${white};
          line-height: 24px;
          justify-content: space-between;
          border-bottom: 1px solid white;
          padding-bottom: 25px;
        }
      }

      .email {
        margin-bottom: 25px;

        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: rgba(255, 255, 255, 0.5);
          margin-bottom: 5px;
        }

        a {
          font-size: 20px;
          color: ${white};
          line-height: 24px;

          &:hover {
            color: ${hover};
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.follow {
          li a {
            border: none !important;
          }
        }
      }
    }
  }
    .dc-btn{
      width: 100%;
      margin-bottom: 25px;
    }
  }
`;

const StyleSlide = styled.section`
  
`;

export default React.memo(MobileMenu);
