import React, {useEffect} from 'react';
import styled from 'styled-components';

import {useLocation, useParams} from 'react-router-dom';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {fetchPosts, fetchPostsChild} from '../../api/redux/university';
import {useDispatch, useSelector} from 'react-redux';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import gsap, {CSSPlugin} from 'gsap';
import {ApiParam} from '../../api/network/apiParams';
import Details from '../../components/nzuac-section/Details';
import * as PropTypes from 'prop-types';
import {motion} from 'framer-motion';
import {PageAnimation} from '../../components/nzuic-animations/PageAnimation';


gsap.registerPlugin(CSSPlugin);

function Cta(props) {
  return null;
}

Cta.propTypes = {data: PropTypes.any};
const UNIVERSITYDETAILS = () => {

  const dispatch = useDispatch();
  let {slug} = useParams();

  // api call
  useEffect(() => {
    let api_url_news = apiEndPoints.SECTIONS;
    let param_news = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'university-list',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };
    dispatch(fetchPosts([api_url_news, param_news]));
    let api_url_child = apiEndPoints.UNIVERSITYLIST;
    let param_2 = {
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.FILE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.SPECIFICATION]: 'yes',
    };
    dispatch(fetchPostsChild([api_url_child, param_2]));
  }, [slug, dispatch]);

  const getData = useSelector(store => store.university);



  const detail = getData?.child?.data;

  const detailData = detail?.find((f) => f.product_data?.slug === slug );

  const location = useLocation();

  useEffect(() => {
    document.querySelector('body').classList.add('news-details');
  }, location?.pathname);




  useEffect(() => {
    // Use GSAP to animate opacity from 0 to 1
    gsap.to('.hide', {alpha: 1, visibility: 'visible', display: 'block', duration: 0.5, delay: 1});
    gsap.to('.pagetransition-loader', {alpha: 0, display: 'none', height: '0', duration: 0.5, delay: 1});
    gsap.to('.desktop-menu__bottom__parent-ul > li ul', {alpha: 0, duration: 0.5, delay: 1});

    // if()


    const body = document.querySelector('body');
    const html = document.querySelector('html');
    const applyNow = document.querySelector('.applyNow');
    if (html.classList.contains('mobile-menu-open')) {
      html.classList.remove('mobile-menu-open');
    }
    if (body.classList.contains('scroll-down')) {
      body.classList.remove('scroll-down');
    }

    if (body.classList.contains('scroll-up')) {
      body.classList.remove('scroll-up');
    }

    if (body.classList.contains('menu-is-open')) {
      body.classList.remove('menu-is-open');
    }

    if (html.classList.contains('menu-is-open')) {
      html.classList.remove('menu-is-open');
    }

    if (body.classList.contains('mobile-menu-open')) {
      body.classList.remove('mobile-menu-open');
    }

    if ((body.classList.contains('stop-scroll')) && window.innerWidth < 992) {
      body.classList.remove('stop-scroll');
    }

    if ((applyNow) && window.innerWidth < 992) {
      applyNow.classList.remove('mobile-menu-open');
    }

    var menuOpen = document.querySelectorAll('.Mobile-menu-wrap');
    var hamburger = document.querySelectorAll('.hamburger');
    var menu = document.querySelectorAll('.main_child');
    var menuSub = document.querySelectorAll('.main-child-sub ');
    var menuSubSub = document.querySelectorAll('.main-child-sub-sub');
    hamburger.forEach((i) => {
      i.classList.remove('menu-open');
      gsap.to(document.querySelector('.mobile-menu__items'), {
        opacity: 0,
        duration: '.2'
      });
      gsap.to(document.querySelector('.mobile-menu__items'), {
        display: 'none'
      });
    });
    menuOpen.forEach((i) => {
      i.classList.remove('menu-open');
    });

    menu.forEach((i) => {
      i.classList.remove('submenu-open-mb');
    });
    menuSub.forEach((i) => {
      i.classList.remove('submenu-open-next');
    });
    menuSubSub.forEach((i) => {
      i.classList.remove('submenu-open-next-next');
      i.classList.remove('submenu-open-next');
      i.classList.remove('submenu-open-mb');
    });

    const contact = document.querySelector('.submenu ');



    if((contact)  && window.innerWidth < 992 ){
      if(contact.classList.contains('sub-menu-open')){
        contact.classList.remove('sub-menu-open');

      }
    }
    // You can customize the animation duration and other properties as needed
  }, [location.pathname]); // Run this effect only once when the component mounts




  return (

    <HelmetProvider>
      {/*dynamic title */}
      <Helmet>
        <meta charSet="utf-8"/>

        <title>{`${detail?.data?.title ? detail?.data?.title + ' - New Zealand University Application Centre' : 'New Zealand University Application Centre'}`}</title>
        {
          detail?.data?.meta_title &&
              <meta name="title" content={detail?.data?.meta_title ? detail?.data?.meta_title : ''}/>

        }
        {
          detail?.data?.meta_description &&
              <meta name="description" content={detail?.data?.meta_description}/>

        }
        {
          detail?.data?.og_title &&
              <meta property="og:title" content={detail?.data?.og_title}/>

        }
        {
          detail?.data?.og_description &&
              <meta property="og:description" content={detail?.data?.og_description}/>

        }
      </Helmet>
      <motion.div key={'183'} className="page-loader pagetransition-loader" exit="exit" animate="anim"
        variants={PageAnimation}
        initial="init">
      </motion.div>
      <StyledComponent>
        <Details data={detailData}/>

      </StyledComponent>
    </HelmetProvider>

  );
};

const StyledComponent = styled.section`

  padding-top: 100px;
  @media (max-width: 767px) {
    padding: 90px 0 0;
  }
`;

export default UNIVERSITYDETAILS;
