import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {hover, text} from '../../styles/globalStyleVars';
import gsap from 'gsap';
import GoBack from '../nzuac-buttons/GoBack';
import CTASmall from '../nzuac-single-item/CTASmall';
import {ImgLazyLoad} from '../nzuac-image/ImgLazyLoad';
import CourseSingle from '../nzuac-single-item/CourseSingle';
import VideoDetails from './VideoDetails';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, EffectFade, Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import reactHtmlParser from 'react-html-parser';
import {useLocation, useNavigate} from 'react-router-dom';
import Table from '../../pages/university/components/Table';

const Details = ({data}) => {

  const sectionSingle = useRef();
  const detailsRef = useRef();

  const location = useLocation();


  useEffect(() => {
    // let details = document.querySelector('.details-wrapper ')

    if (window?.innerWidth > 1700 && detailsRef?.current) {
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: sectionSingle?.current,
          start: 'top top+=180px',
          pinnedContainer: sectionSingle?.current,
          ease: 'elastic',
          id: 'whois',
          end: '+=' + (document.querySelector('#scroller-ship-detail').clientHeight - 700),
          pin: true,
          pinSpacing: false,
          toggleActions: 'play none none reverse',
        }
      });

      return () => {
        timeline.kill();
      };
    }
    if (window?.innerWidth > 992 && detailsRef?.current) {
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: sectionSingle?.current,
          start: 'top top+=180px',
          pinnedContainer: sectionSingle?.current,
          ease: 'elastic',
          id: 'whois',
          end: '+=' + (document.querySelector('#scroller-ship-detail').clientHeight - 600),
          pin: true,
          pinSpacing: false,
          toggleActions: 'play none none reverse',
        }
      });

      return () => {
        timeline.kill();
      };
    }


  }, [data, location?.pathname]);

  const logo = data?.images?.list?.find(f => f?.thumb === 'on');
  const banner = data?.images?.list?.find(f => f?.banner === 'on');

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // This will navigate back in the browser's history
  };


  return (
    <StyledDetails id={'scroller-ship-detail'} className="details-wrapper">
      {
        window?.innerWidth < 992 ?
          <ul className="top-go-back-button">
            <li onClick={handleGoBack}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g id="Group_23883" data-name="Group 23883" transform="translate(3758 -14567) rotate(-90)">
                  <circle id="Ellipse_3" data-name="Ellipse 3" cx="10" cy="10" r="10" transform="translate(-14587 -3738) rotate(-90)" fill="#a08750"/>
                  <g id="Group_15708" data-name="Group 15708" transform="translate(-14580 -3746.5) rotate(-90)">
                    <line id="Line_7" data-name="Line 7" y1="3" x2="3" transform="translate(0 3)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                    <line id="Line_8" data-name="Line 8" x2="3" y2="3" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                  </g>
                </g>
              </svg>

              <p>Go back</p>
            </li>
            <li>
              <p>Apply Now</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g id="Group_23883" data-name="Group 23883" transform="translate(0 20) rotate(-90)">
                  <circle id="Ellipse_3" data-name="Ellipse 3" cx="10" cy="10" r="10" transform="translate(20) rotate(90)" fill="#fff"/>
                  <g id="Group_15708" data-name="Group 15708" transform="translate(13 8.5) rotate(90)">
                    <line id="Line_7" data-name="Line 7" x2="3" y2="3" fill="none" stroke="#a08750" strokeLinecap="round" strokeWidth="1"/>
                    <line id="Line_8" data-name="Line 8" y1="3" x2="3" transform="translate(0 3)" fill="none" stroke="#a08750" strokeLinecap="round" strokeWidth="1"/>
                  </g>
                </g>
              </svg>

            </li>
          </ul>
          : ''
      }
      <Container>
        <Row ref={detailsRef}>
          <Col md={3} className={'social-pin-wrapper'}>
            <div ref={sectionSingle} className="social-pin-wrapper">
              {
                window?.innerWidth > 992 ?
                  <div className="social-wrapper-main">
                    <div onClick={handleGoBack}>
                      <GoBack/>
                    </div>

                    <CTASmall/>
                    {/*<SocialShare direction/>*/}
                  </div>
                  :
                  ''
              }
            </div>

          </Col>
          <Col md={{span: 9}} ref={detailsRef}>
            <div className="intro">
              <div className="intro-left">
                {
                  logo &&
                    <img src={logo?.full_path} height={60} width={200} alt={data?.product_data?.title}/>
                }
                <h1>{data?.product_data?.title}</h1>
              </div>
              <div className="intro-right">
                <div className="box-wrapper">

                  <ImgLazyLoad src="/images/dynamic/logos/details-bg.svg" alt={data?.product_data?.title}/>
                  {
                    data?.product_data?.subtitle &&
                      <div className="content">
                        <p>Qs ranking</p>
                        <p className={'ranking'}>{reactHtmlParser(data?.product_data?.subtitle)}</p>

                      </div>

                  }

                </div>
              </div>
            </div>


            <div className="overview">
              {
                data?.product_data?.description &&
                  reactHtmlParser(data?.product_data?.description)
              }
            </div>

            <div className="banner">
              <div className="campus">
                {
                  data?.product_data?.campus &&
                    <div className="campus-single">
                      {reactHtmlParser(data?.product_data?.campus)}
                    </div>
                }
                {
                  data?.product_data?.campus_others &&
                    <div className="campus-single">
                      {reactHtmlParser(data?.product_data?.campus_others)}
                    </div>
                }

                {
                  data?.product_data?.campus_addional &&
                    <div className="campus-single">
                      {/*<h5 className={*/}
                      {/*  'split-up'*/}
                      {/*}>Additional campus</h5>*/}
                      {/*<p className={'split-up'}>{reactHtmlParser(data?.product_data?.campus_addional)}</p>*/}
                      {reactHtmlParser(data?.product_data?.campus_addional)}
                    </div>
                }

              </div>

              {
                banner &&
                  <div className="banner-wrapper">
                    <ImgLazyLoad alt={data?.product_data?.title} src={banner?.full_path}/>
                  </div>
              }

            </div>

            <div className="course-list-wrapper">
              <h2 className={'split-up'}>Available Courses</h2>
              <div className="clear"></div>
              <div className="course-list">

                {
                  data?.course_list && data?.course_list?.length > 0 &&
                    data?.course_list?.map((item, index) => {
                      return (
                        <div className="course-single" key={index}>
                          <CourseSingle type={item?.course_type} link={item?.slug} title={item?.title}/>
                        </div>
                      );
                    })
                }


              </div>
            </div>


            {data?.posts?.list?.map((item, index) => {


              if (item?.data?.template === 'Table') {
                return (
                  <React.Fragment key={index}>
                    <Table data={item}/>
                  </React.Fragment>
                );
              } else if (item?.data?.template === 'details_video') {

                return (
                  <React.Fragment key={index}>

                    {
                      item?.data?.description &&
                        <div className="detail-description mb-bottom">
                          {
                            item?.data?.description &&
                              reactHtmlParser(item?.data?.description)
                          }

                        </div>

                    }
                    <VideoDetails image={item?.images?.[0]?.full_path} link={item?.images?.[0]?.short_title}/>
                    {
                      item?.images?.[0]?.short_desc && <p className={'caption'}>{reactHtmlParser(item?.images?.[0]?.short_desc)}</p>
                    }
                  </React.Fragment>
                );
              } else if (item?.data?.template === 'details_list_counter') {

                return (
                  <React.Fragment key={index}>
                    <div className="detail-description">
                      {
                        item?.data?.description &&
                            reactHtmlParser(item?.data?.description)

                      }
                    </div>
                  </React.Fragment>
                );
              } else if (item?.data?.template === 'details_slider') {

                return (
                  <React.Fragment key={index}>
                    {
                      item?.images?.length > 0 &&
                          <div className="image-gallery-slider">
                            <Swiper
                              Swiper
                              modules={[Autoplay, Pagination, Navigation, EffectFade]}
                              slidesPerView={1}
                              speed={1500}
                              spaceBetween={50}
                              draggable={true}
                              navigation={{
                                prevEl: '#details-prev',
                                nextEl: '#details-next'
                              }}
                              loop={false}
                            >
                              {
                                item?.images?.length > 0 &&
                                  item?.images?.map((item, index) => {
                                    return (
                                      <SwiperSlide key={index}>
                                        <div className="img-group-caption">
                                          <div className="img-wrapper-big">
                                            <ImgLazyLoad alt={item?.short_desc} src={item?.full_path}/>
                                          </div>
                                          <p>{reactHtmlParser(item?.short_desc)}</p>
                                        </div>
                                      </SwiperSlide>
                                    );
                                  })
                              }


                            </Swiper>

                            {

                              item?.images?.length > 0 &&
                                <div className="navigation">
                                  <div className="blog-button">
                                    <div className="slider-nav">

                                      <ul>
                                        <li id={'details-prev'}>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414" viewBox="0 0 11.207 11.414">
                                            <g id="Arrow" transform="translate(0.707 0.707)">
                                              <g id="Group_23756" data-name="Group 23756">
                                                <line id="Line_3910" data-name="Line 3910" x1="5" y2="5" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                                <line id="Line_3911" data-name="Line 3911" x2="5" y2="5" transform="translate(0 5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                              </g>
                                              <line id="Line_3912" data-name="Line 3912" x1="10" transform="translate(0 5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                            </g>
                                          </svg>


                                        </li>
                                        <li id={'details-next'}>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414" viewBox="0 0 11.207 11.414">
                                            <g id="Arrow" transform="translate(-648 -1595.793)">
                                              <g id="Group_4823" data-name="Group 4823" transform="translate(653.5 1596.5)">
                                                <line id="Line_9" data-name="Line 9" x2="5" y2="5" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                                <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(0 5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                              </g>
                                              <line id="Line_11" data-name="Line 11" x2="10" transform="translate(648.5 1601.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                            </g>
                                          </svg>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                            }


                          </div>
                    }


                  </React.Fragment>
                );
              } else {
                return null; // If the template doesn't match any case, render nothing
              }

            })}


          </Col>
        </Row>
      </Container>
    </StyledDetails>
  );
};

const StyledDetails = styled.section`
  padding: 100px 0;
  background: #F1F0EE;
  position: relative;

  .caption {
    font-weight: 500;
    margin-top: 20px;
  }

  .video-modal{
    margin-bottom: 30px;
  }
  .caption{
    margin-bottom: 60px;
  }
  a{
    
  }
  .top-go-back-button {
    top: 0;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;

    @media (max-width: 992px) and (min-width: 768px) {
      top: -10px;
    }

    li {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 20px 15px;
      display: flex;
      cursor: pointer;

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }

      &:first-child {
        background: white;

        svg {
          margin-right: 20px;
        }
      }

      &:last-child {
        justify-content: flex-end;

        svg {
          margin-left: 20px;
        }

        p {
          color: white
        }

        background: ${hover};
      }
    }
  }


  .social-wrapper-main {
    padding-right: 40px;
  }

  .small-box-cta {
    margin-top: 20px;
  }

  //introduction

  .intro {
    margin-bottom: 30px;
    display: flex;

    .intro-left {
      flex: 0 0 80%;
      max-width: 80%;

      img {
        width: auto;
      }

      h1 {
        font-size: 40px;
        font-weight: 500;
        line-height: 44px;
        color: ${text};
        margin-top: 30px;
      }
    }

    .intro-right {
      flex: 0 0 20%;
      max-width: 20%;

      .box-wrapper {
        position: relative;
        padding-top: calc(130 / 170 * 100%);
        background: ${hover};

        .content {
          position: absolute;
          inset: 0;
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 15px;

          p {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: rgba(255, 255, 255, 0.5);

            &.ranking {
              color: rgba(255, 255, 255, 1);
              font-size: 40px;
              font-weight: 600;
              line-height: 44px;
            }
          }
        }
      }
    }
  }


  //overview

  .overview {
    padding-bottom: 30px;
    border-bottom: 1px solid #040504;
    margin-bottom: 30px;

    h5 {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 20px;
      color: ${text};
    }

    p {
      color: ${text};
    }
  }


  //banner

  .banner {
    .campus {
      margin-bottom: 30px;
      display: flex;

      .campus-single {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }

        h5 {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: rgba(4, 5, 4, 0.5);
          margin-bottom: 10px;
        }

        p {
          color: ${text};
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          margin: 0;
        }
      }
    }

    .banner-wrapper {
      position: relative;
      padding-top: calc(480 / 870 * 100%);
    }
  }


  //course list

  .course-list-wrapper {
    margin-top: 30px;
    padding-bottom: 60px;
    border-bottom: 1px solid #040504;
    margin-bottom: 60px;

    h2 {
      font-size: 28px;
      font-weight: 500;
      line-height: 32px;
      color: ${hover};
      margin-bottom: 30px;
    }

    .course-list {

      display: flex;
      gap: 30px;
      flex-flow: wrap;
    }

    .course-single {
      flex: 0 0 calc(33.3333% - 30px);
      max-width: calc(33.3333% - 30px);
    }
  }

  //description

  .mb-bottom {
    margin-bottom: 60px;
  }

  .detail-description {
    .nzuac-list-count {
      margin-bottom: 20px;

      span {
        display: block;
        font-weight: 500;
      }

      li {
        &:last-child {
          &:before {
            display: none;
          }
        }
      }
    }

    h2 {
      font-size: 28px;
      font-weight: 500;
      line-height: 32px;
      color: ${hover};
      margin-bottom: 20px;
    }

    table {
      gap: 20px;
      margin-bottom: 60px;

      th {
        font-size: 16px; /* 20px / 15px = 1.3333rem */
        font-weight: 400;
        line-height: 20px; /* 28px / 15px = 1.8667rem */
        color: ${text};
        border-top: none !important;
      }

      tr {
        border-bottom: 1px solid rgba(20, 38, 55, 0.2);
        justify-content: flex-start;
        padding: 20px 0;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        font-size: 16px; /* 20px / 15px = 1.3333rem */
        font-weight: 400;
        line-height: 20px; /* 28px / 15px = 1.8667rem */

        &:first-child {
          border-bottom: 1px solid rgba(4, 5, 4, 0.2);
          padding-top: 0;
        }

        &:last-child {
          border-bottom: none;
        }

        th {
          max-width: calc(50% - 30px);
          flex: 0 0 calc(50% - 30px);
        }

        td {
          max-width: calc(50% - 30px);
          flex: 0 0 calc(50% - 30px);
        }
      }
    }

    ul {
      margin-top: 0;
      margin-bottom: 60px;

      li {
        border-bottom: 1px solid rgba(4, 5, 4, 0.2);
        font-size: 16px;
        font-weight: 400;

        strong {
          font-weight: 500;
        }
      }
    }

    a {
      color: ${hover};
      margin-bottom: 20px;
      display: inline-block;
    }

    &.mb-bottom{
      a {
        color: ${hover};
        margin-top: 20px;
        display: inline-block;
      }
    }
  }


  .image-gallery-slider {
    position: relative;
    margin-bottom: 60px;

    .navigation {
      position: absolute;
      bottom: -35px;
      right: 0;
    }

    .img-wrapper-big {
      padding-top: calc(570 / 870 * 100%);
      position: relative;
    }

    .swiper-slide-active {
      p {
        transition-delay: 0.7s;
        opacity: 1;
      }
    }

    p {
      opacity: 0;
      transition: 0.7s all cubic-bezier(0.54, 0.32, 0.05, 0.96);
      max-width: 70%;
      margin-top: 35px;
      font-weight: 500;
    }
  }


  //responsive
  @media (max-width: 992px) {
    .col-md-3 {
      display: none;
    }

    .col-md-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .course-list-wrapper {
      .course-single {
        max-width: calc(50% - 15px);
        flex: 0 0 calc(50% - 15px);
      }
    }
  }
  @media (max-width: 767px) {
    .intro {
      display: flex;
      align-items: flex-start;
      position: relative;

      .intro-left {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .intro-right {
        flex: unset;
        max-width: unset;
        position: absolute;
        right: 0;
        top: 0;


        .box-wrapper {
          position: unset;
          padding-top: unset;
          background: transparent;

          .global-image {
            display: none;
          }

          .content {
            position: unset;
            inset: unset;
            padding: 0;

            p {
              color: #888888;

              &.ranking {
                color: ${hover};
              }
            }
          }
        }
      }
    }

    .banner {
      .campus {
        flex-direction: column;

        .campus-single {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .course-list-wrapper {
      course-box {
        padding-top: calc(120 / 345 * 100%);
      }

      .course-single {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
`;

export default React.memo(Details);




