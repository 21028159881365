import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {get} from '../../network/axiosServices';
import {fetchPostDetail} from '../news';


const initialState = {
  loading: false,
  posts: [],
  error: '',
  detail: [],
  feature: [],
  detailLoading: false,
  detailError: '',
  child: [],

};

export const fetchPosts = createAsyncThunk('fetchDatacourse', (params) => {
  return get(params);
});

export const fetchPostsChild = createAsyncThunk('fetchDatacourseDetails', (params) => {
  return get(params);
});


const postSlice = createSlice({
  name: 'posts',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchPosts.pending, (state) => {
      state.loading = true;
      state.posts = [];
      state.error = '';
    });
    builder.addCase(fetchPosts.fulfilled, (state, action) => {
      state.loading = false;
      state.posts = action.payload;
      state.error = '';
    });
    builder.addCase(fetchPosts.rejected, (state, action) => {
      state.loading = false;
      state.posts = [];
      state.error = action.error;
    });
    builder.addCase(fetchPostsChild.fulfilled, (state, action) => {
      state.loading = false;
      state.child = action.payload;
      state.error = '';
    });
    builder.addCase(fetchPostsChild.rejected, (state, action) => {
      state.loading = false;
      state.child = [];
      state.error = action.error;
    });
    builder.addCase(fetchPostDetail.pending, (state, action) => {
      state.detailLoading = true;
      state.child = [];
      state.detailError = '';
    });

  }
});


export default postSlice.reducer;
