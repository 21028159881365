import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {coffe, text, white} from '../../styles/globalStyleVars';
import BsRightSmall from '../nzuac-svg/BsRightSmall';

const ButtonIcon = ({
  onSubmit,
  text,
  src,
  img,
  hoverImg,
  fontSize,
  fontWeight,
  color,
  letterSpacing,
  lineHeight,
  margin,
  background,
  borderRadius,
  border,
  width,
  height,
  hoverBackground,
  target,
  borderColor, hoverTextcolor, iconSVG
}) => {

  return (
    <StyleButtonIcon className={'dc-btn'}
      hoverTextcolor={hoverTextcolor}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      background={background}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      margin={margin}
      border={border}
      img={img}
      borderRadius={borderRadius}
      width={width}
      hoverImg={hoverImg}
      hoverBackground={hoverBackground}
      height={height}
      borderColor={borderColor}
      target={target}
    >
      {!target ? (
        <Link target={target || '_self'} to={src || '/'}>
          <div className="show-text">
            <div className="text">
              {text}
            </div>
            {

              iconSVG ?
                <div className="icon">
                  <BsRightSmall/>
                </div>
                : ''
            }


          </div>
          <div className="hover-text">

            <div className="text">
              {text}
            </div>
            {

              iconSVG ?
                <div className="icon">
                  <BsRightSmall color={text}/>
                </div>
                : ''
            }


          </div>
        </Link>

      ) : (
        <a target={target || '_self'} href={src || '/'}>
          <div className="text">
            {text}
          </div>
          <div className="show-text">
            {

              iconSVG ?
                <div className="icon">
                  <BsRightSmall/>
                </div>
                : ''
            }


          </div>
          <div className="hover-text">

            <div className="text">
              {text}
            </div>
            {

              iconSVG ?
                <div className="icon">
                  <BsRightSmall/>
                </div>
                : ''
            }

          </div>
        </a>
      )}
    </StyleButtonIcon>
  );
};

const StyleButtonIcon = styled.div`
    &.dc-btn {
        margin: ${props => props.margin || '0'};
        width: fit-content;
        cursor: pointer;
        min-width: ${p => p.width || '155'}px;
        text-align: center;
        overflow: hidden;

        a {
            display: flex;
            align-items: center;
            color: ${p => p.color || white};
            font-size: ${props => props.fontSize || '16'}px;
            font-weight: ${props => props.fontWeight || 500};
            margin: 0;
            line-height: ${props => props.lineHeight || '21'}px;
            text-transform: capitalize;
            height: ${props => props.height || '35'}px;
            border: 1px solid ${props => props.borderColor || white};
            letter-spacing: ${props => props.letterSpacing || '0'};
            position: relative;
            border-radius: ${props => props.borderRadius || '25px'};
            z-index: 0;
            transition: all .5s cubic-bezier(.54, .32, .05, .96);
            overflow: hidden;
            justify-content: flex-start;
            background-color: ${props => props.background || coffe};
            padding: 0 20px;

            .show-text {
                position: absolute;
                inset: 0;
                height: 100%;
                width: 100%;
                background-color: ${props => props.background || coffe};
                transition: all .5s cubic-bezier(.54, .32, .05, .96);
                transform: translateY(0);
                z-index: 1;
                display: flex;
                padding: 0 20px;
                justify-content: center;
                align-items: center;
                border-radius: ${props => props.borderRadius || '25px'};
                overflow: hidden;

                .icon {
                    display: flex;
                    justify-content: center;
                    height: 35px;
                    align-items: center;
                    margin-left: 21px;
                }

                .text {
                    display: flex;
                    justify-content: center;
                    height: 35px;
                    align-items: center;
                }
            }

            .hover-text {
                position: absolute;
                inset: 0;
                height: 100%;
                width: 100%;
                background-color: ${props => props.hoverBackground || white};
                transition: all .5s cubic-bezier(.54, .32, .05, .96);
                transform: translateY(100px);
                z-index: 2;
                display: flex;
                padding: 0 20px;
                justify-content: center;
                align-items: center;
                border-radius: ${props => props.borderRadius || '25px'};
                overflow: hidden;


              .icon {
                display: flex;
                justify-content: center;
                height: 35px;
                align-items: center;
                margin-left: 21px;
              }


              .text {
                    display: flex;
                    justify-content: center;
                    height: 35px;
                    align-items: center;
                    color: ${props => props.hoverTextcolor || text};
                }
            }

            a {
                &:after {
                    display: none;
                }

                &:before {
                    display: none;
                }
            }


            &:hover {
                border: 1px solid ${props => props.hoverBackground ? props.hoverBackground : white};

                .show-text {
                    transform: translateY(-100px);

                }

                .hover-text {
                    transform: translateY(0);

                }
            }


        }
    }





`;


export default React.memo(ButtonIcon);
