import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import gsap, {CSSPlugin} from 'gsap';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {fetchPosts} from '../../api/redux/about';
import {useDispatch, useSelector} from 'react-redux';
import {SplitText} from 'gsap/SplitText';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {ApiParam} from '../../api/network/apiParams';
import {text} from '../../styles/globalStyleVars';
import {fetchPostsChild} from '../../api/redux/news';
import {motion} from 'framer-motion';
import {PageAnimation} from '../../components/nzuic-animations/PageAnimation';
import InnerBanner from '../../components/nzuac-section/InnerBanner';
import NZUACForm from '../../components/nzuac-section/NZUACForm';
import Overview from '../../components/nzuac-section/Overview';
import TestimonialOne from '../../components/nzuac-section/TestimonialOne';
import TouchSection from '../../components/nzuac-section/Touch';
import BlogListTwo from '../../components/nzuac-section/BlogListTwo';
import OverviewSix from '../../components/nzuac-section/OverviewSix';
import PersonMessage from '../../components/nzuac-section/PersonMessage';
import AwardSlider from '../../components/nzuac-section/AwardSlider';
import CTA from '../../components/nzuac-section/CTA';
import {useLocation} from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger, CSSPlugin, SplitText);


const ABOUT = () => {


  const dispath = useDispatch();


  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;
    let api_url_child = apiEndPoints.BLOGLIST;

    let param = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'about-us',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };

    dispath(fetchPosts([api_url, param]));
    dispath(fetchPostsChild([api_url_child]));

  }, [dispath]);


  let getPost = useSelector(store => store.about);

  let page_data = getPost?.posts?.data?.page_data;
  let sections_list = getPost?.posts?.data?.sections;
  let child_data = getPost?.child?.data;


  const location = useLocation();
  useEffect(() => {
    // Use GSAP to animate opacity from 0 to 1
    gsap.to('.hide', {alpha: 1, visibility: 'visible', display: 'block', duration: 0.5, delay: 1});
    gsap.to('.pagetransition-loader', {alpha: 0, display: 'none', height: '0', duration: 0.5, delay: 1});
    gsap.to('.desktop-menu__bottom__parent-ul > li ul', {alpha: 0, duration: 0.5, delay: 1});

    // if()

    const body = document.querySelector('body');
    const html = document.querySelector('html');
    const applyNow = document.querySelector('.applyNow');

    if (html.classList.contains('mobile-menu-open')) {
      html.classList.remove('mobile-menu-open');
    }

    if (body.classList.contains('scroll-down')) {
      body.classList.remove('scroll-down');
    }

    if (body.classList.contains('scroll-up')) {
      body.classList.remove('scroll-up');
    }

    if (body.classList.contains('menu-is-open')) {
      body.classList.remove('menu-is-open');
    }

    if (html.classList.contains('menu-is-open')) {
      html.classList.remove('menu-is-open');
    }

    if (body.classList.contains('mobile-menu-open')) {
      body.classList.remove('mobile-menu-open');
    }

    if ((body.classList.contains('stop-scroll')) && window.innerWidth < 992) {
      body.classList.remove('stop-scroll');
    }

    if ((applyNow) && window.innerWidth < 992) {
      applyNow.classList.remove('mobile-menu-open');
    }
    const contact = document.querySelector('.submenu ');



    if((contact)  && window.innerWidth < 992 ){
      if(contact.classList.contains('sub-menu-open')){
        contact.classList.remove('sub-menu-open');

      }
    }
    var menuOpen = document.querySelectorAll('.Mobile-menu-wrap');
    var hamburger = document.querySelectorAll('.hamburger');
    var menu = document.querySelectorAll('.main_child');
    var menuSub = document.querySelectorAll('.main-child-sub ');
    var menuSubSub = document.querySelectorAll('.main-child-sub-sub');
    hamburger.forEach((i) => {
      i.classList.remove('menu-open');
      gsap.to(document.querySelector('.mobile-menu__items'), {
        opacity: 0,
        duration: '.2'
      });
      gsap.to(document.querySelector('.mobile-menu__items'), {
        display: 'none'
      });
    });
    menuOpen.forEach((i) => {
      i.classList.remove('menu-open');
    });

    menu.forEach((i) => {
      i.classList.remove('submenu-open-mb');
    });
    menuSub.forEach((i) => {
      i.classList.remove('submenu-open-next');
    });
    menuSubSub.forEach((i) => {
      i.classList.remove('submenu-open-next-next');
      i.classList.remove('submenu-open-next');
      i.classList.remove('submenu-open-mb');
    });
    // You can customize the animation duration and other properties as needed
  }, [location.pathname]); // Run this effect only once when the component mounts

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`${page_data?.title ? page_data?.title + ' - New Zealand University Application Centre' : 'New Zealand University Application Centre - Provides institutional brokerage services'}`}</title>
        {
          page_data?.meta_title &&
              <meta name="title" content={page_data?.meta_title ? page_data?.meta_title : 'New Zealand University Application Centre'}/>

        }

        {
          page_data?.meta_description &&
              <meta name="description" content={page_data?.meta_description}/>

        }
        {
          page_data?.og_title &&
              <meta property="og:title" content={page_data?.og_title}/>

        }
        {
          page_data?.og_description &&
              <meta property="og:description" content={page_data?.og_description}/>

        }
      </Helmet>

      <StyledComponent>
        <motion.div exit="exit" animate="anim"  initial="init"  key={'181'} className="page-loader pagetransition-loader"
          variants={PageAnimation}
        >
        </motion.div>
        {
          sections_list?.length > 0 && sections_list.map((item, index) => {
            switch (item?.section_data?.template) {
            case 'innerbanner':
              return (
                <React.Fragment key={index}>
                  <InnerBanner noPara data={item} key={index}/>
                </React.Fragment>
              );
            case 'nzuac_form':
              return <NZUACForm data={item} key={index}/>;
            case 'overview_box':
              return <Overview data={item} key={index} hoverBox winWidth/>;
            case 'testimonial_section':
              return <TestimonialOne background={text} data={item} key={index}/>;
            case 'touch_template':
              return <TouchSection data={item} key={index}/>;
            case 'blog_slider':
              return <BlogListTwo data={child_data} key={index}/>;
            case 'overview_type_two':
              return <OverviewSix data={item} key={index}/>;
            case 'person_message':
              return <PersonMessage data={item} key={index}/>;
            case 'award_slider':
              return <AwardSlider data={item} key={index}/>;
            case 'cta_section':
              return <CTA data={item} key={index}/>;
            default:
              return null; // If the template doesn't match any case, render nothing
            }
          })
        }

      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`

`;

export default ABOUT;
