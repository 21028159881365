export const apiEndPoints = {
  POSTS: 'get-req-data/posts',
  PHOTOS: 'get-req-data/photos',
  PAGE_DATA:'get-req-data/page-data',
  SECTIONS: 'get-req-data/sections',
  SCHOLERSHIP: 'get-req-data/testimonial-list',
  SERVICERESEARCH: 'get-req-data/research-documents-all',
  JOB: 'get-req-data/job-list',
  TEAM: 'get-req-data/team-list',
  CHILD_PAGE: 'get-req-data/child-pages',
  PAGE_POST_DATA: 'get-req-data/page-post-data',
  SEARCH : 'get-req-data/search',
  SEARCHNEWS : 'get-req-data/search-news',
  ALL_CATEGORY: 'get-req-data/all-category',
  CHILD_CATEGORY: 'get-req-data/child-category',
  PRODUCT_DATA: 'get-req-data/product-data',
  ALL_PRODUCTS: 'get-req-data/all-products',
  POST_FORM: 'post-req-data/form-submit',
  SERVICE_FORM: 'post-req-data/form-submit',
  GETINTOUCH_FORM: 'post-req-data/form-submit',
  FIND_DEALER: 'get-req-data/dealer-location',
  SETTINGS: 'get-req-data/settings-data',
  COURSEDATA: 'get-req-data/course-data',
  BlogData: 'get-req-data/blog-data',
  BLOGLIST: 'get-req-data/news-list',
  UNIVERSITYLIST: 'get-req-data/all-univercities',
  COURRSELIST: 'get-req-data/course-list',
  SCHOLERSHIPLIST: 'get-req-data/scholership-list',
  FILTER: 'get-req-data/product-by-cats',
  FILTERUNIVERSITY: 'get-req-data/university-by-cats',
  FORM_SUBMIT: 'post-req-data/form-submit',
  RESEARCHLIST: 'get-req-data/research-list',
  ADDSUBSCRIP: 'post-req-data/add-subscriber',
  SUBMITFORM: 'post-req-data/form-submit',
  INVESTOR: 'get-req-data/investor-data',
  REARCHLISTCAT: 'get-req-data/research-documents',
  ABOUT: 'get-req-data/about-us'
};
