import React from 'react';
import styled from 'styled-components';
import Mail from '../../components/nzuac-svg/Mai';
import Phone from '../../components/nzuac-svg/Phone';
import Text from '../../components/nziuac-title/Text';
import {hover, white} from '../../styles/globalStyleVars';

/**
 * Icon Left component
 * Pass the props with your data to the Icon Left component.
 **/
const FooterAddress = () => {
  return (
    <StyledFooterAddress>
      <Text text={'Plan your success,<br/> connect with NZUAC!'}/>
      <ul>
        <li className={'hide'}>
          <span>
            <Mail/>
          </span><a href="mailto:placeholder@email.com">placeholder@email.com</a>
        </li>
        <li className={'hide'}>
          <span>
            <Phone/>
          </span><a href="tel:+880 1897712005">+880 1897712005 (BD)</a>
        </li>
        <li className={'hide'}>
          <span>
            <Phone/>
          </span><a href="tel:+64 212378773">+64 212378773 (NZ)</a>
        </li>
      </ul>
    </StyledFooterAddress>
  );
};

/**
 * Styled component for Icon Left Single
 * Add your styles for  Icon Left component here.
 **/
const StyledFooterAddress = styled.div`

    .h1 {
        margin-bottom: 40px;
        color: ${white};
    }

    ul {
        li {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            color: ${white};

            span {
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                color: ${white};
                margin-right: 15px;
            }

            a {
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                color: ${white};

                &:hover {
                    color: ${hover}
                }
            }
        }
    }

`;

export default React.memo(FooterAddress);
