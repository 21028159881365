import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import Subtitle from '../nziuac-title/Subtitle';
import {hover, text, white} from '../../styles/globalStyleVars';
import ButtonIcon from '../nzuac-buttons/ButtonIcon';
import FourBox from './FourBox';
import reactHtmlParser from 'react-html-parser';
import LazyLoad from 'react-lazyload';


const AboutSection = ({data, hoverBox, bg}) => {


  return (
    <StyledAboutSection className={'about-section  '}>
      <div className={`first_section_wrapper pt-160 ${hoverBox && data?.posts?.list?.length <= 0 ? 'pb-160' : ''}`}>
        <LazyLoad once>
          <img className={'leaf-bg-fixed desktop'} alt={'background-fixed-desktop'} src={'./images/static/leaf-overview.svg'}/>
          <img className={'leaf-bg-fixed mobile'} alt={'background-fixed-footer'} src={'./images/static/overview-leaf-mobile.svg'}/>
        </LazyLoad>

        <Container>
          <Row className='about-section__title'>
            <Col md={12}>
              <Subtitle text={data?.section_data?.subtitle ? reactHtmlParser(data?.section_data?.subtitle) : 'Subtitle Here'} color={'rgba(4,5,4,0.3)'} fontSize={16} fontWeight={400} lineHeight={20} margin={'0 0 60px'}/>
              <h2 className={'split-up'}>{data?.section_data?.short_desc ? reactHtmlParser(data?.section_data?.short_desc) : 'Short Description'}</h2>
            </Col>
          </Row>

          <Row className='about-section__content'>
            <Col sm={4}>
              <LazyLoad>
                <img className={''} src={data?.images?.list?.[0]?.full_path ? data?.images?.list?.[0]?.full_path : '/images/dynamic/home/logo.png'} alt="About Logo" height={100} width={185}/>
              </LazyLoad>
            </Col>

            <Col sm={{span: 7}}>
              {/*<TextSlideChildrenAnimation duration="1.17s">*/}

              {
                data?.section_data?.description ?
                  reactHtmlParser(data?.section_data?.description)
                  : 'Description Here'
              }


              {
                data?.section_data?.button_url &&
                  <ButtonIcon src={data?.section_data?.button_url ? data?.section_data?.button_url : ''} height={50} margin={'60px 0 0'} width={150} text={'Learn More'} hoverTextcolor={white} borderColor={white} background={hover} hoverBackground={text}/>

              }


            </Col>
          </Row>

        </Container>
      </div>


      {hoverBox && data?.posts?.list?.length > 0 &&
          <FourBox data={data?.posts?.list}/>
      }


    </StyledAboutSection>
  );
};

const StyledAboutSection = styled.section`
    position: relative;
    //z-index: 4;
    background: #F1F0EE;
    //overflow: hidden;
  
  .first_section_wrapper{
    position: relative;
    .leaf-bg-fixed{
      position: absolute;
      right: -150px;
      bottom: -50px;
      top: 0;
      width: auto;
      height: auto;
      z-index: 0;
      &.mobile{
        display: none;
        @media(max-width: 767px){
          display: block;
          top: unset;
          bottom: -235px;
          right: -70px;
        }
      }
      &.desktop{
        display: block;
        @media(max-width: 767px){
          display: none;
        }
      }
    }
  }
    .about-section__title {
        margin-bottom: 60px;

        h2 {

        }
    }

    .about-section__content {

        p {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 16px;

            &:nth-last-of-type(1) {
                margin-bottom: 0;
            }
        }

        &__count-down {
            margin-top: 80px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &__single {
                width: calc(50% - 50px);
                border-top: 1px solid #221F1F;
                margin-bottom: 60px;

                h2 {
                    font-size: 60px;
                    line-height: 60px;
                    margin-bottom: 25px;
                    margin-top: 32px;
                    font-weight: 300;

                    span {
                        font-weight: 300;
                    }
                }

                p {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }
    }


  .parallax-outer {
        height: 100%;
        width: 100%;
        position: relative;
    }


    //

    .texts-section {
        background-color: #221F1F;
        position: relative;
        padding-bottom: 100px;

        h2 {
            font-size: 32px;
            line-height: 36px;
            color: #ffffff;
            margin-bottom: 90px;

            span {
                color: ${hover};
            }
        }

        &__repeat-text {
            .col-sm-3 {
                margin-bottom: 60px;
            }

            h4 {
                font-size: 26px;
                font-weight: bold;
                color: #ffffff;
                line-height: 32px;
                margin-bottom: 26px;
            }

            p {
                font-size: 16px;
                color: #ffffff;
            }
        }

    }


    @media (min-width: 951px) and (max-width: 1200px) {
        .about-section__bottom__single__inner {
            h4 {
                font-size: 20px;
                line-height: 25px;
            }
        }
    }

    @media (max-width: 950px) {
        .about-section__bottom__single {
            flex: 0 0 50%;
            max-width: 50%;
        }

        .texts-section__repeat-text {
            .col-sm-3 {
                min-width: 33.3333%;
            }
        }
    }

    @media (max-width: 900px) {
        .global-image img {
            height: 100% !important;
            bottom: 0 !important;
        }

        .mission-vision {
            padding-top: 160px;
            padding-bottom: 160px;
            height: auto !important;

            .row {
                position: relative !important;
                margin-top: -300px !important;
                margin-right: -15px;
                margin-left: -15px;
            }

            .col-sm-4 {
                min-width: 100%;
                margin-bottom: 30px;

                &:nth-last-child(1) {
                    margin-bottom: 0;
                }
            }
        }
    }

    @media (max-width: 992px) {
      overflow: hidden;

      .mission-vision {
            margin-top: 250px !important;
            padding-top: 60px;
            padding-bottom: 70px;

            .container {
                overflow: visible;

                .row {
                    margin-top: -200px !important;
                }
            }
        }

    }

    @media (max-width: 767px) {
      overflow: hidden;
        .about-section {
            &__title {
                margin-bottom: 60px;
            }

            &__content {
                .col-sm-3, .col-sm-8 {
                    min-width: 100%;
                    margin: 0;
                }

                img {
                    margin-bottom: 60px;
                    width: auto;
                }

                &__count-down {
                    margin-top: 60px;
                }
            }
        }

        .texts-section__repeat-text {
            .col-sm-3 {
                min-width: 50%;
                margin-bottom: 50px;

                &:nth-last-child(1) {
                    margin-bottom: 0;
                }
            }

            h4 {
                margin-bottom: 16px;
            }
        }

        .texts-section {
            h2 {
                margin-bottom: 60px;
            }
        }

    }

    @media (max-width: 650px) {
        .about-section__bottom {
            margin-top: 40px;

            &__single {
                flex: 0 0 100%;
                max-width: 100%;

                &__inner {
                    border: none;

                    h4 {
                        font-size: 20px;
                        line-height: 26px;
                    }
                }
            }
        }
    }


    @media (max-width: 550px) {
        .about-section__content__count-down__single {
            min-width: calc(100% - 50px);
        }
    }


`;
export default React.memo(AboutSection);