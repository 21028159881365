import {useLocation} from 'react-router-dom';
import {CSSPlugin, gsap} from 'gsap';
import {SplitText} from 'gsap/SplitText';
import {useEffect} from 'react';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger, CSSPlugin, SplitText);

export const SplitUp = (data) => {
  const location = useLocation();

  useEffect(() => {

    // gsap.utils.toArray('.split-up').forEach((item, i) => {
    //   const parentSplit = new SplitText(item, {
    //     linesClass: 'split-parent',
    //     span: true
    //   });
    //   // const childSplit = new SplitText(item, {
    //   //   type: 'lines, chars',
    //   //   linesClass: 'split-child'
    //   // });
    //   gsap.from(parentSplit.lines, {
    //     duration: 1,
    //     yPercent: 100,
    //     // stagger: 0.1,
    //     scrollTrigger: {
    //       trigger: item,
    //       // toggleActions: 'restart none none reset',
    //       // start: "top 60%",
    //       // markers: true
    //     }
    //   });
    // });
    //

    const box = document.querySelectorAll('.box');
    const boxplus = document.querySelectorAll('.boxr');
    const cta = document.querySelectorAll('.cta');
    const box_up = document.querySelectorAll('.box-up');
    const box_down = document.querySelectorAll('.box-down');


    const box1 = document.querySelectorAll('.box1');
    const box2 = document.querySelectorAll('.box2');
    const box3 = document.querySelectorAll('.box3');
    const box4 = document.querySelectorAll('.box4');



    if (window.innerWidth > 992) {

      box1.forEach((el, index) => {
        gsap.fromTo(el, {
          y: 130,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true
          }
        });
      });

      box2.forEach((el, index) => {
        gsap.fromTo(el, {
          y: 100,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true
          }
        });
      });

      box3.forEach((el, index) => {
        gsap.fromTo(el, {
          y: 130,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true
          }
        });
      });
      box4.forEach((el, index) => {
        gsap.fromTo(el, {
          y: 100,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true
          }
        });
      });



      box.forEach((el, index) => {
        gsap.fromTo(el, {
          y: -150,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true
          }
        });
      });



      box_up.forEach((el, index) => {
        gsap.fromTo(el, {
          y: -250,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true
          }
        });
      });
      box_down.forEach((el, index) => {
        gsap.fromTo(el, {
          y: 250,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true
          }
        });
      });

      boxplus.forEach((el, index) => {
        gsap.fromTo(el, {
          y: 150,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true
          }
        });
      });
      cta.forEach((el, index) => {
        gsap.fromTo(el, {
          y: -150,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true
          }
        });
      });


    }

  }, [location.pathname,data]);
};

// how to use
// 1. Add 'split-up' class on the tag which hold text(p,Title,h1-h6,etc...)