import React, {useState} from 'react';
import styled from 'styled-components';
import {coffe, text, white} from '../../styles/globalStyleVars';
import KeyPopUp from '../nzuac-section/KeyPopUp';
import SignUpIcon from '../nzuac-svg/SignUpIcon';

const ButtonModal = ({
  onClick,
  onSubmit,
  text,
  src,
  img,
  hoverImg,
  fontSize,
  fontWeight,
  color,
  letterSpacing,
  lineHeight,
  margin,
  background,
  borderRadius,
  border,
  width,
  height,
  hoverBackground,
  target,
  borderColor, hoverTextcolor, iconSVG
}) => {


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);


  };


  return (
    <>
      <KeyPopUp show={show} handleClose={handleClose}/>
      <StyleButtonModal onClick={handleShow} className={'dc-btn'}
        hoverTextcolor={hoverTextcolor}
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={color}
        background={background}
        lineHeight={lineHeight}
        letterSpacing={letterSpacing}
        margin={margin}
        border={border}
        img={img}
        borderRadius={borderRadius}
        width={width}
        hoverImg={hoverImg}
        hoverBackground={hoverBackground}
        height={height}
        borderColor={borderColor}
        target={target}
      >
        {!target ? (
          <div className={'a'}>
            <div className="show-text">
              {

                iconSVG ?
                  <div className="icon">
                    <SignUpIcon/>
                  </div>
                  : ''
              }

              <div className="text">
                {text}
              </div>
            </div>
            <div className="hover-text">
              {

                iconSVG ?
                  <div className="icon">
                    <SignUpIcon color={text}/>
                  </div>
                  : ''
              }

              <div className="text">
                {text}
              </div>
            </div>
          </div>

        ) : (
          <div className={'a'}>
            <div className="show-text">
              {

                iconSVG ?
                  <div className="icon">
                    <SignUpIcon/>
                  </div>
                  : ''
              }

              <div className="text">
                {text}
              </div>
            </div>
            <div className="hover-text">
              {

                iconSVG ?
                  <div className="icon">
                    <SignUpIcon color={text}/>
                  </div>
                  : ''
              }

              <div className="text">
                {text}
              </div>
            </div>
          </div>
        )}
      </StyleButtonModal>
    </>

  );
};

const StyleButtonModal = styled.div`
    &.dc-btn {
        margin: ${props => props.margin || '0'};
        width: fit-content;
        cursor: pointer;
        min-width: ${p => p.width || '155'}px;
        text-align: center;
        overflow: hidden;

        .a {
            display: flex;
            align-items: center;
            color: ${p => p.color || white};
            font-size: ${props => props.fontSize || '16'}px;
            font-weight: ${props => props.fontWeight || 500};
            margin: 0;
            line-height: ${props => props.lineHeight || '21'}px;
            text-transform: capitalize;
            height: ${props => props.height || '35'}px;
            //border: 1px solid ${props => props.borderColor || white};
            letter-spacing: ${props => props.letterSpacing || '0'};
            position: relative;
            border-radius: ${props => props.borderRadius || '25px'};
            z-index: 0;
            transition: all .5s cubic-bezier(.54, .32, .05, .96);
            overflow: hidden;
            justify-content: flex-start;
            background-color: ${props => props.background || coffe};
            padding: 0 20px;

            .show-text {
                position: absolute;
                inset: 0;
                height: 100%;
                width: 100%;
                background-color: ${props => props.background || coffe};
                transition: all .5s cubic-bezier(.54, .32, .05, .96);
                transform: translateY(0);
                z-index: 1;
                display: flex;
                padding: 0 20px;
                justify-content: center;
                align-items: center;
                border-radius: ${props => props.borderRadius || '25px'};
                overflow: hidden;

                .icon {
                    display: flex;
                    justify-content: center;
                    height: 35px;
                    align-items: center;
                    margin-right: 8px;
                }

                .text {
                    display: flex;
                    justify-content: center;
                    height: 35px;
                    align-items: center;
                }
            }

            .hover-text {
                position: absolute;
                inset: 0;
                height: 100%;
                width: 100%;
                background-color: ${props => props.hoverBackground || white};
                transition: all .5s cubic-bezier(.54, .32, .05, .96);
                transform: translateY(100px);
                z-index: 2;
                display: flex;
                padding: 0 20px;
                justify-content: center;
                align-items: center;
                border-radius: ${props => props.borderRadius || '25px'};
                overflow: hidden;

                .icon {
                    display: flex;
                    justify-content: center;
                    height: 35px;
                    align-items: center;
                    margin-right: 8px;
                }

                .text {
                    display: flex;
                    justify-content: center;
                    height: 35px;
                    align-items: center;
                    color: ${props => props.hoverTextcolor || text};
                }
            }

            .a {
                &:after {
                    display: none;
                }

                &:before {
                    display: none;
                }
            }


            &:hover {
                //border: 1px solid ${props => props.hoverBackground ? props.hoverBackground : white};

                .show-text {
                    transform: translateY(-100px);

                }

                .hover-text {
                    transform: translateY(0);

                }
            }


        }
    }





`;


export default React.memo(ButtonModal);
