import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import reactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import {Autoplay, EffectCreative, Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide} from 'swiper/react';
import {gray, title, white,hover} from '../../styles/globalStyleVars';
import {ImgLazyLoad} from '../nzuac-image/ImgLazyLoad';

/**
 * Testimonial Type one component section
 * Pass the props with your data to the Testimonial Type one component.
 * Check the page folder to see the components used.
 **/
const TestimonialOne = ({background, data, padding}) => {
  /**
     * variables
     * Change / add your custom variable
     **/



  let [offset, setOffset] = useState(90);

  useEffect(() => {
    if (window.innerWidth > 767) {
      setOffset(document.querySelector('.container').offsetLeft + 0);
    }
  }, [data]);



  return (
    <StyledTestimonialOne
      offsetStyle={offset}
      background={background}
      className={`amenities ${padding ? padding : 'pb-160 pt-160'}  as-image-text-slider`}
    >
      <Container>
        <Row>
          <Col className={'amenities__big'} sm={{span: 4}}>
            <div className="fixed-item-wrapper clearfix">
              <p className="first-one h1 boxr">
                {data?.section_data?.subtitle}
              </p>
              <p className="first-two cta h1">
                {data?.section_data?.short_desc}
              </p>
            </div>
            <div className="mobile-title-wrapper">
              <p>{data?.section_data?.subtitle} {data?.section_data?.short_desc}</p>

            </div>
          </Col>

          <Col className={'amenities__right'} sm={{span: 8}}>
            <div className="slider-nav">
              <ul>
                <li id={'testimonial-prev'}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414" viewBox="0 0 11.207 11.414">
                    <g id="Arrow" transform="translate(0.707 0.707)">
                      <g id="Group_23756" data-name="Group 23756">
                        <line id="Line_3910" data-name="Line 3910" x1="5" y2="5" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="1"/>
                        <line id="Line_3911" data-name="Line 3911" x2="5" y2="5" transform="translate(0 5)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="1"/>
                      </g>
                      <line id="Line_3912" data-name="Line 3912" x1="10" transform="translate(0 5)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="1"/>
                    </g>
                  </svg>


                </li>
                <li id={'testimonial-next'}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414" viewBox="0 0 11.207 11.414">
                    <g id="Arrow" transform="translate(-648 -1595.793)">
                      <g id="Group_4823" data-name="Group 4823" transform="translate(653.5 1596.5)">
                        <line id="Line_9" data-name="Line 9" x2="5" y2="5" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="1"/>
                        <line id="Line_10" data-name="Line 10" x1="5" y2="5" transform="translate(0 5)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="1"/>
                      </g>
                      <line id="Line_11" data-name="Line 11" x2="10" transform="translate(648.5 1601.5)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="1"/>
                    </g>
                  </svg>
                </li>
              </ul>
            </div>

            <div className="first-slider">
              {
                data?.posts?.list && data?.posts?.list?.length > 0 &&
                  <Swiper
                    Swiper
                    modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                    pagination={{
                      el: '.swiper-pagination',
                      type: 'fraction'
                    }}
                    navigation={{
                      prevEl: '#testimonial-prev',
                      nextEl: '#testimonial-next'
                    }}
                    onBeforeInit={(swiper) => {
                      swiper.params.navigation.prevEl = '#testimonial-prev';
                      swiper.params.navigation.nextEl = '#testimonial-next';
                    }}
                    speed={900}
                  >
                    {data?.posts?.list?.length > 0 &&
                        data?.posts?.list?.map((e, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <div className="heading">
                                <div className="logo">
                                  <ImgLazyLoad offset={10} src={e?.images?.[0]?.full_path} alt={reactHtmlParser(e?.data?.title)}/>
                                </div>
                                <div className="title-wrapper">
                                  <p className="name split-up">{e?.data?.title}</p>
                                  <p className="versity split-up">{e?.data?.subtitle}</p>
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                  </Swiper>
              }

            </div>
            {
              data?.posts?.list && data?.posts?.list?.length > 0 &&
                <Swiper
                  Swiper
                  modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                  pagination={{
                    el: '.swiper-pagination',
                    type: 'fraction'
                  }}
                  navigation={{
                    prevEl: '#testimonial-prev',
                    nextEl: '#testimonial-next'
                  }}
                  onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = '#testimonial-prev';
                    swiper.params.navigation.nextEl = '#testimonial-next';
                  }}
                  speed={900}
                >
                  {data?.posts?.list?.length > 0 &&
                      data?.posts?.list?.map((e, index) => {
                        return (
                          <SwiperSlide key={index}>


                            <div className="content-wrapper">
                              <svg xmlns="http://www.w3.org/2000/svg" width="46.751" height="40" viewBox="0 0 46.751 40">
                                <g id="Group_8088" data-name="Group 8088" transform="translate(-411.174 -370.741)" opacity="0.1">
                                  <path id="Path_720" data-name="Path 720" d="M411.174,390.694v20.047h19.953V390.694H421.1q.374-9.6,10.023-9.93V370.741Q411.174,373.037,411.174,390.694Z" transform="translate(0 0)" fill="#fff"/>
                                  <path id="Path_721" data-name="Path 721" d="M515.432,380.765V370.741q-19.953,2.3-19.953,19.953v20.047h19.953V390.694H505.409Q505.783,381.093,515.432,380.765Z" transform="translate(-57.507 0)" fill="#fff"/>
                                </g>
                              </svg>
                              <h4 className={'split-up'}>{e?.data?.short_desc ? reactHtmlParser(e?.data?.short_desc) : ''}</h4>
                              <p className={'split-up'}>{e?.data?.description ? reactHtmlParser(e?.data?.description) : ''}</p>
                            </div>

                          </SwiperSlide>
                        );
                      })}
                </Swiper>
            }

          </Col>
        </Row>
      </Container>
    </StyledTestimonialOne>
  );
};

/**
 * Styled component for Testimonial Type one component section
 * Add your styles for Testimonial Type one component here.
 **/
const StyledTestimonialOne = styled.section`
  overflow: hidden;
  background-color: ${(p) => (p.background ? p.background : white)};
  min-height: 100svh;
  padding-top: 250px;
  padding-bottom: 270px;
  //margin-top: -3px;
  position: relative;
  z-index: 1;
  .mobile-title-wrapper {
    display: none;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    margin-bottom: 40px;    
    p {
      color: rgba(255, 255, 255, 0.4);
    }

    @media (max-width: 992px) {
            display: block;
    }
  }


  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .container {
    position: relative;

    .slider-nav {
      position: absolute;
      top: 0;
      width: 50%;
      right: 0;
      z-index: 3;
      display: flex;
      justify-content: flex-end;
      ul{
        display: flex;
        gap: 15px;
      }
      li{
        height: 48px;
        width: 48px;
        background: #FFFFFF;
        border-radius: 50%;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        svg{
          position: relative;
          z-index: 3;
          line{
            transition: 0.7s all ease;
            transition-delay: 0.2s;
          }
        }
        &:after{
          content: '';
          position: absolute;
          inset: 0;
          background: ${hover};
          border-radius: 50%;
          transition: 0.7s all ease;
          z-index: 2;
          transform: translateY(100px);
        }
        &:hover{
          line{
            stroke: white;
          }
          &:after{
            transform: translateY(0);
          }
        }
      }
      @media (max-width: 767px) {
        justify-content: flex-start;

      }

      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: ${title};
        color: ${white};

        span {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: ${white};
        }
      }
    }
  }

  .amenities__big {
    position: relative;

    .fixed-item-wrapper {
      position: relative;
      @media (max-width: 992px) {
        display: none;
      }

      .h1 {
        color: ${white};
        position: absolute;
      }

      .first-one {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        @media(min-width: 1550px){
          top: -70px
        }
      }

      .first-two {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        left: 100px;
      
      }
    }
  }

  .amenities__left {
  }
  
  .heading{
    display: flex;
    border-bottom: 1px solid rgba(242, 242, 242, 0.3);
    padding-bottom: 60px;
    align-items: center;
    .logo{
      width: 100px;
      height: 100px;
      position: relative;
      margin-right: 20px;
      border-radius: 50%;
      overflow: hidden;
      
    }
  }

  .amenities__right {
    position: relative;

    .title-wrapper {

      .name {
        color: ${white};
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      overflow: hidden;
      color: white;
    }

    .designation {
      color: ${gray};
    }

    h4 {
      color: ${white};
      margin-bottom: 30px;
    }

    .content-wrapper {
      margin-top: 80px;
      position: relative;

      svg {
        position: absolute;
        top: -15px;
        left: -10px;
        height: 40px;
        width: 46px;
      }
    }
  }

  .swiper-slide {
    padding-left: 15px;
  }

  @media (max-width: 992px) {
    padding-top: 80px;
    padding-bottom: 80px;
    .container {
      padding: 0;
    }

    .slider-nav {
      display: block;
    }

    .title {
      margin-bottom: 0;
    }

    .col-sm-5,
    .col-sm-4 {
      min-width: 100%;
      margin-left: 0;
    }

    .amenities__big {
      padding-right: 30px;
      padding-left: 30px;

      &__thumb {
        height: 150px;
        width: 55%;
        bottom: -75px;

        div {
          height: 100%;
          width: 100%;
        }

        .single {
          padding: 0 !important;
        }
      }
    }

    .amenities__big__slider .shadow {
      left: 45%;
    }

    .amenities__right {
      margin-top: 0;
      padding-top: 0;
      padding-right: 30px;
      padding-left: 30px;
      margin-left: 0;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .amenities__big .icon {
      position: absolute;
      right: unset;
      z-index: 1;
      height: 80px;
      bottom: unset;
      top: -21px;
      left: 2px;

      svg {
        height: 40px;
      }
    }

    .container .slider-nav {
      bottom: unset;
      width: 100% !important;
      position: relative;
      margin: 40px 0;
      display: flex;
      align-items: center;

      p {
        margin-top: 0px;
      }

      ul {
        li {
          justify-content: flex-start;
        }
      }
    }
  }
  
  @media(max-width: 767px){
    .first-slider{
      .swiper-slide{
        padding-left: 0 !important;
      }
    }
  }
`;

export default React.memo(TestimonialOne);
