import React from 'react';
import styled from 'styled-components';
import reactHtmlParser from 'react-html-parser';

/**
 * Hamburger Icon component
 * Pass the props with your data to the Hamburger Icon.
 **/
const Table = ({data}) => {
  return (
    <StyledTable>
      <div className="detail-description">
        {
          data?.data?.description &&
            reactHtmlParser(data?.data?.description)
        }
      </div>

    </StyledTable>
  );
};
/**
* Styled component for Hamburger Icon
 * Add your styles for Hamburger Icon component here.
 **/
const StyledTable = styled.div`
`;

export default React.memo(Table);
